import * as actionTypes from '../actions/actionTypes';
import axios from '../../axios/axios';
import * as actions from './index';
export const authStart = () => {
  return { type: actionTypes.AUTH_START };
};

export const authFail = (mess) => {
  return { type: actionTypes.AUTH_FAIL, message: mess };
};

export const authSuccess = (token) => {
  return { type: actionTypes.AUTH_SUCCESS, token: token };
};

export const authClear = () => {
  return { type: actionTypes.AUTH_CLEAR };
};

export const passwordExpire = () => {
  return { type: actionTypes.AUTH_PASSWORD_EXPIRE };
};

export const auth = (username, passowrd, history) => {
  const authData = {
    username: username,
    password: passowrd,
  };

  return (dispatch) => {
    dispatch(authStart());
    axios
      .post('/authenticate', authData)
      .then((respose) => {
        dispatch(authSuccess(respose.headers.authorization));
        history.push('/home');
      })
      .catch((err) => {
        const messages = err.response.data.map((e) => e.errorCode);
        dispatch(actions.sliderMessageInit(messages, false));
        history.push('/home');
        history.goBack();
      });
  };
};
