import React from 'react';
import './index.scss';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import UgcBreadcrumb from '../../../../../components/UgcBreadcrumb';
import InputField from '../../../../../components/InputField';
import bPaths from '../../../../../config/breadcrumbPaths.json';
import BootstrapTable from 'react-bootstrap-table-next';
import { paginationConfig } from '../../../../../utility/tableConfig';
import { columns, data } from './config';
import { checkFormValidity } from '../../../../../utility/formValidation';
import axios from '../../../../../axios/axios';
import { checkValidity } from '../../../../../utility/validation';
import urls from '../../../../../config/url.json';
import CardButton from '../../../../../components/CardButton';
import { convertResponseToErrorMessage } from '../../../../../utility/errorConvertor';
import labels from '../../../../../config/label.json';

class ViewHigherPreference extends React.Component{
    state = {
        columns: columns(),
        data: [],
        totalData: 0,
        controls: {
            indexNumber: {
                valid: false,
                value: '',
                touched: false,
                rules: {required: true, indexNo:true},
            },
            academicYear: {
                valid: false,
                value: '',
                touched: false,
                rules: {required: true, academicYear: true},
            }
        },
        id: null,
    };

    componentDidMount() {
        this.setState({ ...this.state, columns: columns() });
        this.loadData(0);
    }

    // componentDidUpdate() {
    //     this.setState({ ...this.state, columns: columns() });
    //     this.loadData(0);
    // }

    inputChangeHandler = (property, val) => {
        val = val.trim();
        const propertyConfig = { ...this.state.controls[property] };
        const isValid = checkValidity(val, propertyConfig.rules);
        propertyConfig['valid'] = isValid;
        propertyConfig['value'] = val;
        propertyConfig['touched'] = true;

        const updatedControl = {
            ...this.state.controls,
            [property]: propertyConfig,
        };
        const formValid = checkFormValidity(updatedControl);

        const updatedState = {
            ...this.state,
            formValid: formValid,
            controls: updatedControl,
        };
        this.setState(updatedState);
    };

    searchHigherPreference = (event,page) => {
        event.preventDefault();
        const indexNumber = this.state.controls.indexNumber.value;
        const academicYear = this.state.controls.academicYear.value;
        const pageNo = page;
        console.log('hello')
        console.log(pageNo)

        axios(this.props.history)
            .get(urls.getUpperPreferenceData, { params: {academicYear: academicYear, indexNumber: indexNumber, page: '0'} })
            .then((resepose) => {
                console.log(resepose.data);
                this.setState({ ...this.state, columns: columns() });
                this.loadData(0);
            })

            .catch((err) => {
            });
    };

    loadData = (page) => {
        const indexNumber = this.state.controls.indexNumber.value;
        const academicYear = this.state.controls.academicYear.value;
        axios(this.props.history)
            .get(urls.getUpperPreferenceData, { params: {academicYear: academicYear, indexNumber: indexNumber, page: page} })
            .then((response) => {
                const updatedState = { ...this.state, data: response.data.data, totalData: response.data.totalCount };
                this.setState(updatedState);
            });
    };

    handleTableChange = (type, { page, sizePerPage }) => {
        if (type === 'pagination') {
            this.loadData(page - 1);
        }
    };

    render(){
        return (
            <div className="view-higherpreferences">
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                        <UgcBreadcrumb bPaths={bPaths.viewHigherPreferences} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className="higherpreference-table-search">
                            <Row>
                            <Col xs={12} sm={12} md={4} lg={4}>
                                <InputField
                                    name='Academic Year:'
                                    type='text'
                                    autocomplete='off'
                                    onChange={(val) => this.inputChangeHandler('academicYear', val)}
                                    feedback={labels.ViewHigherPreference.academicYear.feedback}
                                    invalid={!this.state.controls.academicYear.valid && this.state.controls.academicYear.touched}   
                                    id='academicYearInputField'
                                    
                                />
                            </Col>
                            <Col xs={12} sm={12} md={5} lg={5}>
                                <InputField
                                    name='Index Number:'
                                    type='number'
                                    autocomplete='off'
                                    onInput = {(e) =>{
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,7)
                                      }}
                                    onChange={(val) => this.inputChangeHandler('indexNumber', val)}
                                    feedback={labels.ViewHigherPreference.indexNo.feedback}
                                    invalid={!this.state.controls.indexNumber.valid && this.state.controls.indexNumber.touched}  
                                    id='indexNumberInputField'
                                />
                            </Col>
                            <Col xs={12} sm={12} md={3} lg={3}>
                                <CardButton
                                    btnName='SEARCH'
                                    btnCat='icon-btn no-margin float-right'
                                    iconName='search'
                                    onClick={this.searchHigherPreference}
                                    disabled={!this.state.controls.indexNumber.valid || !this.state.controls.academicYear.valid}
                                    id='higherPreferenceSearchButton'
                                />
                            </Col>
                            </Row>   
                        </Col>
                    </Row>
                    <Row className="view-higherpreferences-table">
                        <BootstrapTable
                            onTableChange={this.handleTableChange}
                            remote
                            keyField="indexNumberd"
                            data={this.state.data}
                            columns={this.state.columns}
                            pagination={paginationConfig(this.state.totalData)}
                        />
                    </Row>
                </Container>
            </div>
        );
    }
};

export default ViewHigherPreference;
