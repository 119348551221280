import React, { Component } from 'react';
import { columns } from './config';
import './index.scss'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import UgcBreadcrumb from '../../../../../components/UgcBreadcrumb';
import InputField from '../../../../../components/InputField';
import bPaths from '../../../../../config/breadcrumbPaths.json';
import BootstrapTable from 'react-bootstrap-table-next';
import { paginationConfig } from '../../../../../utility/tableConfig';
import { checkFormValidity } from '../../../../../utility/formValidation';
import axios from '../../../../../axios/axios';
import { checkValidity } from '../../../../../utility/validation';
import urls from '../../../../../config/url.json';
import CardButton from '../../../../../components/CardButton';
import SubmitButton from '../../../../../components/SubmitButton';
import { convertResponseToErrorMessage } from '../../../../../utility/errorConvertor';
import labels from '../../../../../config/label.json';
import RemoveHigherPreferece from '../removeHigherPreferece';

class EditUpperPreference extends Component {
  state = {
    columns: columns(this.removeAllHandler, this.removeHander),
    data: [],
    totalData: 0,
    controls: {
      indexNumber: {
        valid: false,
        value: '',
        touched: false,
        rules: { required: true, indexNo: true },
      },
      academicYear: {
        valid: false,
        value: '',
        touched: false,
        rules: { required: true, academicYear: true },
      },
    },
    id: null,
    removed: [],
    checkboxchangedbyLetter: false,
    uniCodeList: '',
    showModal: {
      removeHigherPreferece: false
    }
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      columns: columns(this.removeAllHandler, this.removeHander),
    });
    //this.loadData(0);
  }

  inputChangeHandler = (property, val) => {
    val = val.trim();
    const propertyConfig = { ...this.state.controls[property] };
    const isValid = checkValidity(val, propertyConfig.rules);
    propertyConfig['valid'] = isValid;
    propertyConfig['value'] = val;
    propertyConfig['touched'] = true;

    const updatedControl = {
      ...this.state.controls,
      [property]: propertyConfig,
    };
    const formValid = checkFormValidity(updatedControl);

    const updatedState = {
      ...this.state,
      formValid: formValid,
      controls: updatedControl,
    };
    this.setState(updatedState);
  };

  searchHigherPreference = (event, page) => {
    event.preventDefault();
    const indexNumber = this.state.controls.indexNumber.value;
    const academicYear = this.state.controls.academicYear.value;
    const pageNo = page;
    this.loadData(0);
  };

  loadData = (page) => {
    const indexNumber = this.state.controls.indexNumber.value;
    const academicYear = this.state.controls.academicYear.value;
    axios(this.props.history)
      .get(urls.getUpperPreferenceData, {
        params: { academicYear: academicYear, indexNumber: indexNumber, page: page },
      })
      .then((response) => {
        const updatedState = { ...this.state, data: response.data.data, totalData: response.data.totalCount };
        updatedState.data.forEach((value) => {
          value['removed'] = false;
        });
        this.setState(updatedState);
      });
  };

  removeAllHandler = (cheched) => {
    this.setState((state, props) => {
      const updatedData = [...state.data];
      updatedData.forEach((value) => {
        value['removed'] = cheched;
      });
      var updatedCol = columns(this.removeAllHandler, this.removeHander);
      const updatedState = { ...state, data: updatedData, columns: updatedCol };
      return updatedState;
    });
  };

  removeHander = (id, checked) => {
    const updatedData = [...this.state.data];
    updatedData.forEach((value) => {
      if (value['id'] === id) {
        value['removed'] = checked;
      }
    });
    var updatedCol = columns(this.removeAllHandler, this.removeHander);
    const updatedState = { ...this.state, data: updatedData, columns: updatedCol };
    this.setState(updatedState);
  };

  handleTableChange = (type, { page, sizePerPage }) => {
    if (type === 'pagination') {
      this.loadData(page - 1);
    }
  };

  editHigherPreferences = (buttonAction) => {
    const upperPreferences = [];
    this.state.data.forEach((value) => {
      const upperPref = { id: value.id, removed: value.removed };
      upperPreferences.push(upperPref);
    });
    axios(this.props.history)
      .patch(urls.updateUpperPrefData, { upperPrefDataList: upperPreferences, changeByLetter: this.state.checkboxchangedbyLetter, removeVacancyUniCode: buttonAction })
      .then((response) => {
        console.log(response);
      });
    window.location.reload(true);
  };

  validateHigherPreferences = () => {
    const upperPreferences = [];
    this.state.data.forEach((value) => {
      const upperPref = { id: value.id, removed: value.removed };
      upperPreferences.push(upperPref);
    });
    axios(this.props.history)
      .post(urls.validateUpperPrefData, { upperPrefDataList: upperPreferences })
      .then((response) => {

        if (response.data.hasVacancies === true) {

          let uniCodeList = "";
          for (let i = 0; i < response.data.vacancies.length - 1; i++) {

            uniCodeList += response.data.vacancies[i].uniCode + ","
          }
          uniCodeList += response.data.vacancies[response.data.vacancies.length - 1].uniCode
          this.setState({
            ...this.state,
            uniCodeList: uniCodeList,
          });
          this.handleCardShow('removeHigherPreferece', true);
        } else {
          this.editHigherPreferences('NO');
        }
      });
  };

  handleCancel = () => {
    this.props.history.push('/home');
  };


  handleCardShow = (modal, show) => {
    const updatedShowModal = { ...this.state.showModal, [modal]: show };
    const updatedState = { ...this.state, showModal: updatedShowModal };
    this.setState(updatedState);
  };

  changedbyLetter = (event) => {
    this.setState({
      ...this.state,
      checkboxchangedbyLetter: event.target.checked,
    });
  };

  render() {
    let modal = null;
    if (this.state.showModal.removeHigherPreferece) {
      modal = (
        <RemoveHigherPreferece
          closed={() => {
            this.handleCardShow('removeHigherPreferece', false);
          }}
          uniCodeList={this.state.uniCodeList}
          editHigherPreferences={(buttonAction) => {
            this.editHigherPreferences(buttonAction);
          }}
        ></RemoveHigherPreferece>
      );
    }
    return (
      <div className="edit-higherpreferences">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <UgcBreadcrumb bPaths={bPaths.editHigherPreferences} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="higherpreference-table-search">
              <Row>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <InputField
                    name="Academic Year:"
                    type="text"
                    autocomplete="off"
                    onChange={(val) => this.inputChangeHandler('academicYear', val)}
                    feedback={labels.ViewHigherPreference.academicYear.feedback}
                    invalid={!this.state.controls.academicYear.valid && this.state.controls.academicYear.touched}
                    id="academicYearInputField"
                  />
                </Col>
                <Col xs={12} sm={12} md={5} lg={5}>
                  <InputField
                    name="Index Number:"
                    type="number"
                    autocomplete="off"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7)
                    }}
                    onChange={(val) => this.inputChangeHandler('indexNumber', val)}
                    feedback={labels.ViewHigherPreference.indexNo.feedback}
                    invalid={!this.state.controls.indexNumber.valid && this.state.controls.indexNumber.touched}
                    id="indexNumberInputField"
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3}>
                  <CardButton
                    btnName="SEARCH"
                    btnCat="icon-btn no-margin float-right"
                    iconName="search"
                    onClick={this.searchHigherPreference}
                    disabled={!this.state.controls.indexNumber.valid || !this.state.controls.academicYear.valid}
                    id="higherPreferenceSearchButton"
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="view-higherpreferences-table">
            <BootstrapTable
              onTableChange={this.handleTableChange}
              remote
              keyField="indexNumberd"
              data={this.state.data}
              columns={this.state.columns}
              pagination={paginationConfig(this.state.totalData)}
            />
            <Form.Group className="changedbyletter-check">
              <Form.Check
                type="checkbox"
                label='Preference Change by Letter'
                checked={this.state.checkboxChecked}
                onChange={this.changedbyLetter}
              />
            </Form.Group>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="bottom-buttons">
              <Row>
                <Col xs={12} sm={12} md={4} lg={4}></Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <SubmitButton
                    variant='outline-primary'
                    text="CANCEL"
                    clicked={this.handleCancel}
                    disabled={false}
                    id="cancelButton"
                  />
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <SubmitButton
                    variant='primary'
                    text="REMOVE SELECTED HIGHER PREFERENCES"
                    clicked={(e) => this.validateHigherPreferences()}
                    disabled={false}
                    id="uploadButton"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {modal}
        </Container>
      </div>
    );
  }
}

export default EditUpperPreference;
