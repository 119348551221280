import React from 'react';
import './index.scss';

import Modal from 'react-bootstrap/Modal';
import SubmitButton from '../../../../../components/SubmitButton';
import FileInput from '../../../../../components/FileInput';
import axios from '../../../../../axios/axios';
import urls from '../../../../../config/url.json';
import InlineFeedback from '../../../../../components/InlineFeedback';
import messages from '../../../../../config/messages.json';
import { formatMessageArgs } from '../../../../../utility/utillity';
import { withRouter } from 'react-router-dom';

class UploadMfile extends React.Component {
  state = {
    show: false,
    fileContent: '',
    canSubmit: false,
    backendMessage: '',
    dowloadLink: '',
    canAttach: true,
    isSuccess: '',
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClose = () => {
    this.props.closed();
    this.setState({ show: false });
  };

  handleFileContentLoaded = (content) => {
    console.log(content);
    this.setState({ ...this.state, fileContent: content, canSubmit: true });
  };

  //{ "errorFileInfo": { "name": "string", "url": "string" }, "fileId": 0, "message": "string", "successCount": 0 }

  buildBackendMessage = (data) => {
    if (data.errorFileInfo) {
      if(data.successCount === 0){
        return messages.MFILE_UPLOAD_FAILD;
      }else{
        const successcount = data.successCount
        const replace = {
          '%SUCCESSCOUNT%': successcount
        }
        const message = formatMessageArgs(messages.FILE_UPLOAD_WITH_ERRORS, replace);
        return message;
      }

    } else {
      const successcount = data.successCount
      const replace = {
        '%SUCCESSCOUNT%': successcount
      }
      const message = formatMessageArgs(messages.FILE_UPLOAD_ZERO_ERRORS, replace);
      return message;
    }
  };

  uploadM = (e) => {
    this.setState({ ...this.state, canSubmit: false, canAttach: false });
    const formData = new FormData();
    formData.append('file', this.state.fileContent);
    axios(this.props.history)
      .post(urls.mFileUpload, formData)
      .then((resepose) => {
        const link = resepose.data.errorFileInfo ? resepose.data.errorFileInfo.url : '';
        this.setState({
          ...this.state,
          canSubmit: false,
          backendMessage: this.buildBackendMessage(resepose.data),
          dowloadLink: link,
          canSubmit: false,
          isSuccess: false,
        });
      })
      .catch((err) => console.log(err));
  };
  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.handleClose()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload M-File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-detail">Please upload the Master File (M-File) in below field.</p>
          <FileInput
            name='Upload M-File'
            hint='upload CSV File Only'
            hiddenFieldId='mFile'
            visibleFieldId = 'mFileName'
            onContentChange={this.handleFileContentLoaded}
            canAttach={this.state.canAttach}
          ></FileInput>
          {this.state.backendMessage !== '' ? (
            <InlineFeedback
              message={this.state.backendMessage}
              isSuccess={this.state.isSuccess}
              isDisplay={true}
              link={this.state.dowloadLink}
            />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            variant={'primary'}
            text={'UPLOAD'}
            clicked={this.uploadM}
            disabled={!this.state.canSubmit}
            id={'uploadButton'}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

UploadMfile.propTypes = {};

export default withRouter(UploadMfile);
