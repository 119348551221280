import React from 'react';
import './index.scss';

class StudentFormFeedback extends React.Component {
    render(){
        return (
            <p className='studentform-feedback'>{this.props.message}</p>
        );
    }
};

export default StudentFormFeedback;
