export const columns = () => {
    return [  
        {
            dataField: 'indexNumber',
            text: 'Index Number',
        },
        {
            dataField: 'referenceNumber',
            text: "Reference Number"
        },
        {
            dataField: 'nic',
            text: 'NIC Number',
        },
        {
            dataField: 'academicYear',
            text: 'Academic Year',
        },
        {
            dataField: 'nameWithInitials',
            text: 'Name',
        },
        {
            dataField: 'courseOfStudy',
            text: 'Course',
        },
        {
            dataField: 'university',
            text: 'University',
        },
        {
            dataField: 'intakeCategory',
            text: 'Intake Category',
        },
        {
            dataField: 'batchNumber',
            text: 'Batch Number',
        },
        {
            dataField: 'regDeadline',
            text: 'Registration Deadline',
        },
        {
            dataField: 'regDate',
            text: 'Registration Date',
        },
        {
            dataField: 'regTime',
            text: 'Registration Time',
        },
        {
            dataField: 'status',
            text: 'Status',
        }
    ];
};
