import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import * as actions from './store/actions/authActions';

import { HashRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import store from './store/store';

const app = (
  <Provider store={store}>
    <HashRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </HashRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

const tokenFromStore = localStorage.getItem('token');
if (tokenFromStore) {
  store.dispatch(actions.authSuccess(localStorage.getItem('token')));
}
const passwordExpired = localStorage.getItem('passwordExpire');
if (passwordExpired) {
  store.dispatch(actions.passwordExpire(passwordExpired));
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
