export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_CLEAR = 'CLEAR_AUTH';
export const AUTH_PASSWORD_EXPIRE = 'AUTH_PASSWORD_EXPIRE';

export const SLIDER_MESSAGE_INIT = 'SLIDER_MESSAGE_INIT';
export const SLIDER_MESSAGE_REMOVE = 'SLIDER_MESSAGE_REMOVE';

export const LOADER_SHOW = 'LOADER_SHOW';
export const LOADER_REMOVE = 'LOADER_REMOVE';
