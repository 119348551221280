import React from 'react';
import './index.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import UgcBreadcrumb from '../../../../../components/UgcBreadcrumb';
import InputField from '../../../../../components/InputField';
import bPaths from '../../../../../config/breadcrumbPaths.json';

import BootstrapTable from 'react-bootstrap-table-next';
import { paginationConfig } from '../../../../../utility/tableConfig';
import { columns, data } from './config';
import { checkFormValidity } from '../../../../../utility/formValidation';
import { checkValidity } from '../../../../../utility/validation';
import axios from '../../../../../axios/axios';
import urls from '../../../../../config/url.json';
import DeleteModel from '../../../../commonDeleteModel';
import EditRecordBatch from '../../Batches/editRecordBatch';
import ViewBatchRecordDetails from '../../Batches/viewBatchRecordDetails';

class ViewBatchRecords extends React.Component {
  state = {
    columns: columns(this.handleModalShow),
    data: [],
    totalData: 0,
    controls: {
      indexNumber: {
        valid: false,
        value: '',
        touched: false,
        rules: {},
      },
    },
    batchNumber: '',
    id: '',
    referenceNumber: '',
    showModal: {
      viewBatchRecordDetails: false,
      editRecordBatch: false,
      deleteModal: false,
    },
  };

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const batchNo = params.get('batchNumber');
    this.setState({ ...this.state, batchNumber: batchNo, columns: columns(this.handleModalShow) });
    this.loadData(0, batchNo);
  }

  inputChangeHandler = (property, val) => {
    val = val.trim();
    const propertyConfig = { ...this.state.controls[property] };
    const isValid = checkValidity(val, propertyConfig.rules);
    propertyConfig['valid'] = isValid;
    propertyConfig['value'] = val;
    propertyConfig['touched'] = true;

    const updatedControl = {
      ...this.state.controls,
      [property]: propertyConfig,
    };
    const formValid = checkFormValidity(updatedControl);

    const updatedState = {
      ...this.state,
      formValid: formValid,
      controls: updatedControl,
    };
    this.setState(updatedState);
  };

  handleIndexNumberKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.loadData(0);
    }
  };

  loadData = (page, batchNo) => {
    const batchNum = batchNo ? batchNo : this.state.batchNumber;
    axios(this.props.history)
      .get(urls.getBatchRecordData, {
        params: { year: '', page: page, batchNumber:  batchNum, indexNumber: this.state.controls.indexNumber.value },
      })
      .then((response) => {
        const updatedState = { ...this.state, data: response.data.data, totalData: response.data.totalCount };
        this.setState(updatedState);
      });
  };

  handleTableChange = (type, { page }) => {
    if (type === 'pagination') {
      this.loadData(page - 1);
    }
  };

  handleModalShow = (modal, show, batchNo, id, referenceNumber) => {
    console.log(modal, show, batchNo);
    const updatedShowModal = { ...this.state.showModal, [modal]: show };
    const updatedState = { ...this.state, showModal: updatedShowModal, batchNumber: batchNo, id: id, referenceNumber: referenceNumber };
    this.setState(updatedState);
  };

  render() {
    let modal = null;
    if (this.state.showModal.deleteModal) {
      modal = (
        <DeleteModel
          closed={() => {
            this.handleModalShow('deleteModal', false, null);
          }}
          url={urls.deleteRecordFromBatchFileData + this.state.id}
        />
      );
    } else if (this.state.showModal.editRecordBatch) {
      modal = (
        <EditRecordBatch
          closed={() => {
            this.handleModalShow('editRecordBatch', false, null);
          }}
          id={this.state.id}
        />
      );
    } else if (this.state.showModal.viewBatchRecordDetails) {
      modal = (
        <ViewBatchRecordDetails
          closed={() => {
            this.handleModalShow('viewBatchRecordDetails', false, null);
          }}
          referenceNo={this.state.referenceNumber}
        />
      );
    } else {
      modal = null;
    }
    return (
      <div className="view-batches-records">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <UgcBreadcrumb bPaths={bPaths.viewBatchesRecords} />
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              sm={{ span: 6, offset: 6 }}
              md={{ span: 6, offset: 6 }}
              lg={{ span: 6, offset: 6 }}
              className="table-search"
            >
              <InputField
                name={'Search by Index Number'}
                type={'text'}
                onChange={(val) => this.inputChangeHandler('indexNumber', val)}
                invalid={!this.state.controls.indexNumber.valid && this.state.controls.indexNumber.touched}
                onKeyDown={this.handleIndexNumberKeyDown}
                id={'indexNumberInputField'}
              />
            </Col>
          </Row>
          <Row className="view-batch-records-table">
            <BootstrapTable
              onTableChange={this.handleTableChange}
              remote
              keyField="id"
              data={this.state.data}
              columns={this.state.columns}
              pagination={paginationConfig(this.state.totalData)}
            ></BootstrapTable>
          </Row>
          {modal}
        </Container>
      </div>
    );
  }
}

export default ViewBatchRecords;
