import React from 'react';
import './index.scss';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';

class SearchableDropdown extends React.Component {
    render(){
        return (
            <div>
                <Form.Group>
                    <Form.Label>{this.props.Label}</Form.Label>
                    <Typeahead
                        {...this.props}
                    />
                </Form.Group>
            </div>
        );   
    }
};

export default SearchableDropdown;
