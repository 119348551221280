import React from 'react';
import './index.scss';

import Form from "react-bootstrap/Form";
import DateTimePicker from "react-datetime-picker";

class DateInput extends React.Component {
    render(){
        return (
            <Form.Group >
                <Form.Label className="date-label">{this.props.children}</Form.Label>
                <DateTimePicker {...this.props} format='yyyy-MM-dd'/>
                <Form.Control.Feedback type="invalid">
                    {this.props.feedback}
                </Form.Control.Feedback>
            </Form.Group>
        );
    }

};

export default DateInput;
