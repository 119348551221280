import React from 'react';
import Form from 'react-bootstrap/Form';

export const columns = (removeAllHandler, removeHander, allRemoved) => [
  {
    dataField: 'uniCode',
    text: '​Higher Preferred UNI Codes',
  },
  {
    dataField: 'prefNumber',
    text: '​Preference Number',
  },
  {
    dataField: 'university',
    text: 'University',
  },
  {
    dataField: 'course',
    text: 'Course',
  },
  {
    dataField: 'removed',
    headerFormatter: (column, colIndex) => {
      return (
        <span>
          <Form.Check type="checkbox" label="Select All" onChange={(e) => removeAllHandler(e.target.checked)} />
        </span>
      );
    },
    formatter: (cellContent, row, rowIndex, formatExtraData) => {
      const cell = (
        <Form.Group className="studentdeclaration-check">
          <Form.Check type="checkbox" checked={row.removed} onChange={(e) => removeHander(row.id, e.target.checked)} />
        </Form.Group>
      );
      return <span>{cell}</span>;
    },
  },
];
