import React from 'react';

import Modal from 'react-bootstrap/Modal';
import SubmitButton from '../../../../../components/SubmitButton';
import InputField from '../../../../../components/InputField';
import InlineFeedback from '../../../../../components/InlineFeedback';
import DropdownInput from '../../../../../components/DropdownInput';
import { checkValidity } from "../../../../../utility/validation";
import { checkFormValidity } from "../../../../../utility/formValidation";

import labels from '../../../../../config/label.json';
import dropdownValues from "./dropdownValues.json"
import axios from "../../../../../axios/axios";
import urls from "../../../../../config/url.json";
import { withRouter } from 'react-router-dom';


class InsertRecordBatch extends React.Component {

    state = {
        controls: {
            indexNo: {
                valid: false,
                value: "",
                touched: false,
                rules: { required: true, indexNo:true }
            },
            referenceNo: {
                valid: false,
                value: "",
                touched: false,
                rules: { required: true, referenceNo: true, minLength:16, maxLength:18 }
            },
            uniCode: {
                valid: false,
                value: "",
                touched: false,
                rules: { required: true, maxLength:18 }
            },
            perviousRegistered: {
                valid: false,
                value: "",
                touched: false,
                rules: { requiredForDropdown: true }
            },
            intakeCategory: {
                valid: false,
                value: "",
                touched: false,
                rules: { requiredForDropdown: true }
            },
            vacNumber: {
                valid: false,
                value: "",
                touched: false,
                rules: { required: true, maxLength:2 }
            },
        },
        
        backendMessage: '',
        show: false,
        formValid: false,
        isSuccess: '',
        vacNumberVisible: false
      };

      inputChangeHandler = async (property, val) => {
        val = val.trim();
        console.log(val);
        const propertyConfig = { ...this.state.controls[property] };
        const isValid = checkValidity(val, propertyConfig.rules);
        propertyConfig["valid"] = isValid;
        propertyConfig["value"] = val;
        propertyConfig["touched"] = true;

        if (property === "uniCode") {

            var status = await this.validateUniCode(val);
            propertyConfig["valid"] = isValid && status;
        }    
        const updatedControl = {
          ...this.state.controls,
          [property]: propertyConfig,
        };
        const formValid = checkFormValidity(updatedControl);

        const updatedState = {
          ...this.state,
          formValid: formValid,
          controls: updatedControl
        };
        this.setState(updatedState);
      };

      intakeCategoryHandler = async (property, val) => {
       
        val = val.trim();
        console.log(val);
        const propertyConfig = { ...this.state.controls[property] };
        const isValid = checkValidity(val, propertyConfig.rules);
        propertyConfig["valid"] = isValid;
        propertyConfig["value"] = val;
        propertyConfig["touched"] = true;    


        var vacNumberVisible = false;

        const propertyConfig1 = { ...this.state.controls["vacNumber"] };
        propertyConfig1["valid"] = true;
        propertyConfig1["value"] = "";
        propertyConfig1["touched"] = false;
        propertyConfig1["rules"] = { required: false, maxLength:2 };

        if(property === "intakeCategory" && val === "V"){
           
          vacNumberVisible = true;

          propertyConfig1["valid"] = false;
          propertyConfig1["rules"] = { required: true, maxLength:2 };
        }

        const updatedControl = {
          ...this.state.controls,
          [property]: propertyConfig,
          ["vacNumber"]: propertyConfig1,
        };
        const formValid = checkFormValidity(updatedControl);

        const updatedState = {
          ...this.state,
          formValid: formValid,
          controls: updatedControl,
          vacNumberVisible: vacNumberVisible,
        };
        this.setState(updatedState);
      };

      // inputChangeHandler1 = (property, val) => {
      //   // alert("inputChangeHandler1");
      //   var vacNumberVisible = false;

      //   const propertyConfig = { ...this.state.controls["vacNumber"] };
      //   propertyConfig["valid"] = true;
      //   propertyConfig["value"] = "";
      //   propertyConfig["touched"] = false;
      //   propertyConfig["rules"] = { required: false, maxLength:2 };
        
      //   if(property === "intakeCategory" && val === "V"){
           
      //       vacNumberVisible = true;

      //       propertyConfig["valid"] = false;
      //       propertyConfig["rules"] = { required: true, maxLength:2 };
      //   }

      //   const updatedControl = {
      //     ...this.state.controls,
      //     ["vacNumber"]: propertyConfig,
      //   };

      //   const updatedState = {
      //       // ...this.state,
      //       vacNumberVisible: vacNumberVisible,
      //       controls: updatedControl
      //     };
      //     this.setState(updatedState);
      // }

      validateUniCode = async (val) => {
        
        var status;
        await axios(this.props.history)
        .get(urls.validateUniCode+val)
        .then((response) =>{

            status = response.data.status;
        })
        .catch((err) => {
            console.log(err);
          });
          return await  status;
      }

      handleShow = () => {
        this.setState({ show: true });
      };
    
      handleClose = () => {
        this.props.closed();
        this.setState({ show: false });
      };

      insert = () => {
        
        const indexNo = this.state.controls.indexNo.value;
        const referenceNo = this.state.controls.referenceNo.value;
        const uniCode = this.state.controls.uniCode.value;
        const perviousRegistered = this.state.controls.perviousRegistered.value;
        const intakeCategory = this.state.controls.intakeCategory.value;
        const vacNumber = this.state.controls.vacNumber.value;
        const batchData = {
            indexNumber: indexNo,
            intakeCategory: intakeCategory,
            previousRegistered: perviousRegistered,
            referenceNumber: referenceNo,
            uniCode: uniCode,
            vac: vacNumber
        };
        axios(this.props.history)
        .post(urls.insertBatchRecord+this.props.id, batchData)
        .then((response) =>{
            
            var message = '';
                if(response.data.success === true){
                    message = response.data.message;
                }else if(response.data.success === false){  
                    message = response.data.message;
                }
                this.setState({
                    ...this.state,
                    backendMessage: message
                });
        })
        .catch((err) => {
            console.log(err);
          });
      }
    render() {
        return (
          <Modal
            show={true}
            onHide={() => this.handleClose()}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Insert a Record to Batch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputField
                    name={'Index Number'}
                    type={'number'}
                    onInput = {(e) =>{
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,7)
                      }}
                    onChange={(val) => this.inputChangeHandler('indexNo', val)}
                    feedback={labels.InsertBatchRecord.indexNo.feedback}
                    invalid={!this.state.controls.indexNo.valid && this.state.controls.indexNo.touched}
                    id={'indexNoInputField'}
                />
                <InputField
                    name={'Reference Number'}
                    type={'text'}
                    onChange={(val) => this.inputChangeHandler('referenceNo', val)}
                    feedback={labels.InsertBatchRecord.referenceNo.feedback}
                    invalid={!this.state.controls.referenceNo.valid && this.state.controls.referenceNo.touched}
                    id={'referenceNoInputField'}
                />

                <InputField
                    name={'UNI-Code'}
                    type={'text'}
                    onChange={(val) => this.inputChangeHandler('uniCode', val)}
                    feedback={labels.InsertBatchRecord.uniCode.feedback}
                    invalid={!this.state.controls.uniCode.valid && this.state.controls.uniCode.touched}
                    id={'uniCodeInputField'}
                />

                <DropdownInput 
                    controlId="perviousRegistered"
                    name="Previous Registered"
                    onChange={(val) => this.inputChangeHandler("perviousRegistered", val)}
                    feedback={labels.InsertBatchRecord.perviousRegistered.feedback}
                    options={dropdownValues.InsertBatchRecord.previousRegistered.options}
                />

                <DropdownInput 
                    controlId="intakeCategory"
                    name="Intake Category"
                    // onChange={(val) => { this.inputChangeHandler("intakeCategory", val); this.inputChangeHandler1("intakeCategory", val); } }
                    onChange={(val) => this.intakeCategoryHandler("intakeCategory", val)}
                    feedback={labels.InsertBatchRecord.intakeCategory.feedback}
                    options={dropdownValues.InsertBatchRecord.intakeCategory.options}
                />

                {this.state.vacNumberVisible === true ?

                <InputField
                    name={'VAC Number'}
                    type={'number'}
                    
                    onChange={(val) => this.inputChangeHandler('vacNumber', val)}
                    feedback={labels.InsertBatchRecord.vacNumber.feedback}
                    invalid={!this.state.controls.vacNumber.valid && this.state.controls.vacNumber.touched}
                    id={'vacNumberInputField'}
                />
            
                : null}
    
              {this.state.backendMessage !== '' ? (
                <InlineFeedback message={this.state.backendMessage} isSuccess={true} isDisplay={true} />
              ) : null}
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton
                variant={'primary'}
                text={'INSERT'}
                clicked={() => this.insert()}
                disabled={!this.state.formValid}
                id={'insertButton'}
              />
            </Modal.Footer>
          </Modal>
        );
      }

}

InsertRecordBatch.propTypes = {};

export default withRouter(InsertRecordBatch);