import React from 'react';

import Modal from 'react-bootstrap/Modal';

import { updateControlValues } from '../../../../../utility/formValidation';
import axios from "../../../../../axios/axios";
import urls from "../../../../../config/url.json";
import { withRouter } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import './index.scss';


class ViewBatchRecordDetails extends React.Component {

    state = {
        controls: {
            indexNo: {
                value: ""  
            },
            referenceNo: {
                value: ""
            },
            nameWithInitials: {
                value: ""
            },
            fullName: {
                value: ""
            },
            nicNumber: {
                value: ""
            },
            uniCode: {
                value: ""
            },
            courseOfStudy: {
                value: ""
            },
            university: {
                value: ""
            },
            zScore: {
                value: ""
            },
            applicationYear: {
                value: ""
            },
            academicYear: {
                value: ""
            },
            intakeCategory: {
                value: ""
            },
            perviousRegistered: {
                value: ""
            },
            studentRegistered: {
                value: ""
            }
        },
        
        backendMessage: '',
        show: false,
        formValid: false,
        isSuccess: '',
    };

    handleShow = () => {

        this.setState({ show: true });
    };
    
    handleClose = () => {

        this.props.closed();
        this.setState({ show: false });
    };

    componentDidMount() {

        let referenceNo = this.props.referenceNo;
        axios(this.props.history)
        .get(urls.retrieveRecordFromBatchFileDataByReference + referenceNo).then((response) => {

          
            const indexNo = response.data.indexNumber;
            const referenceNo = response.data.referenceNumber;
            const nameWithInitials = response.data.nameWithInitials;
            const fullName = response.data.fullName;
            const nicNumber = response.data.nic;
            const uniCode = response.data.uniCode;
            const courseOfStudy = response.data.courseOfStudy;
            const university = response.data.university;
            const zScore = response.data.zscore;
            const applicationYear = response.data.applicationYear;
            const academicYear = response.data.academicYear;
            const intakeCategory = response.data.intakeCategory;
            const perviousRegistered = response.data.previousRegistered;
            const studentRegistered = response.data.studentRegistered;

            const responseData = { indexNo: indexNo, referenceNo: referenceNo, nameWithInitials: nameWithInitials, fullName: fullName, nicNumber: nicNumber, uniCode: uniCode, courseOfStudy: courseOfStudy, university: university, zScore: zScore, applicationYear: applicationYear, academicYear: academicYear, intakeCategory: intakeCategory, perviousRegistered: perviousRegistered, studentRegistered: studentRegistered };
            const updatedControls = updateControlValues(this.state.controls, responseData);
            const updatedState = { ...this.state, controls: updatedControls };
            this.setState(updatedState);
          
          
        });
      }

    render() {
        return (
            <Modal
                show={true}
                onHide={() => this.handleClose()}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Header closeButton>
                    <Modal.Title>Batch Record Details</Modal.Title>
                </Modal.Header>

                <Modal.Body className="batch-record-detail-model" >

                    <table className="batch-record-detail-table" >
                        <tr>
                            <th><Form.Label>Index Number  :  </Form.Label></th>
                            <td>{this.state.controls.indexNo.value}</td>
                        </tr>
                        <tr>
                            <th><Form.Label>Reference Number  :  </Form.Label></th>
                            <td>{this.state.controls.referenceNo.value}</td>
                        </tr>
                        <tr>
                            <th><Form.Label>Name with initials  :  </Form.Label></th>
                            <td>{this.state.controls.nameWithInitials.value}</td>
                        </tr>
                        <tr>
                            <th><Form.Label>Full Name  :  </Form.Label></th>
                            <td>{this.state.controls.fullName.value}</td>
                        </tr>
                        <tr>
                            <th><Form.Label>NIC Number  :  </Form.Label></th>
                            <td>{this.state.controls.nicNumber.value}</td>
                        </tr>
                        <tr>
                            <th><Form.Label>UNI-Code  :  </Form.Label></th>
                            <td>{this.state.controls.uniCode.value}</td>
                        </tr>
                        <tr>
                            <th><Form.Label>Course of study  :  </Form.Label></th>
                            <td>{this.state.controls.courseOfStudy.value}</td>
                        </tr>
                        <tr>
                            <th><Form.Label>University  :  </Form.Label></th>
                            <td>{this.state.controls.university.value}</td>
                        </tr>
                        <tr>
                            <th><Form.Label>Z-Score  :  </Form.Label></th>
                            <td>{this.state.controls.zScore.value}</td>
                        </tr>
                        <tr>
                            <th><Form.Label>Application Year  :  </Form.Label></th>
                            <td>{this.state.controls.applicationYear.value}</td>
                        </tr>
                        <tr>
                            <th><Form.Label>Academic Year  :  </Form.Label></th>
                            <td>{this.state.controls.academicYear.value}</td>
                        </tr>
                        <tr>
                            <th><Form.Label>Intake Category  :  </Form.Label></th>
                            <td>{this.state.controls.intakeCategory.value}</td>
                        </tr>
                        <tr>
                            <th><Form.Label>Previous Registered  :  </Form.Label></th>
                            <td>{this.state.controls.perviousRegistered.value}</td>
                        </tr>
                        <tr>
                            <th><Form.Label>Student Registered  :  </Form.Label></th>
                            <td>{this.state.controls.studentRegistered.value}</td>
                        </tr>
                    </table>

                </Modal.Body>

            </Modal>
        );
    }

}

ViewBatchRecordDetails.propTypes = {};

export default withRouter(ViewBatchRecordDetails);