import * as actionTypes from "./actionTypes";

export const loaderShow = () => {
  return {
    type: actionTypes.LOADER_SHOW,
  };
};

export const loaderRemove = () => {
  return {
    type: actionTypes.LOADER_REMOVE,
  };
};