//The method will get the string with token '%token %' and replace with the items in replacements class
export const formatMessageArgs = (str, replacements) => {
  str = str.replace(/%\w+%/g, (all) => (all in replacements ? replacements[all] : all));
  return str;
};

export const capitalizeFirstLetter = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  return null;
};
