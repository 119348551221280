import React from 'react';
import './index.scss';
import { Switch, Route } from 'react-router-dom';
import StudentInitialMessage from '../studentInitialMessage';
import StudentRegistration from '../studentRegistration';
import RegistrationSuccess from '../RegistrationSuccess';
import Loader from '../../../components/Loader/index';
import { connect } from 'react-redux';

class StudentLayout extends React.Component {
  render() {
    return (
      <div>
        {this.props.showLoader ? <Loader /> : null}
        <Switch>
          <Route path={this.props.match.url + 'registration-success'} exact component={RegistrationSuccess} />
          <Route path={this.props.match.url + 'registration-form'} exact component={StudentRegistration} />
          <Route path={this.props.match.url + '/'} exact component={StudentInitialMessage} />
        </Switch>
      </div>
    );
  }
}

const mapsStateToPros = (state) => {
  return {
    showLoader: state.loader.showLoader,
  };
};

export default connect(mapsStateToPros)(StudentLayout);
