import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/updateObject';
const instialState = {
  showLoader: false,
};

const reducer = (state = instialState, action) => {
  switch (action.type) {
    case actionTypes.LOADER_SHOW:
      return updateObject(state, {
        showLoader: true
      });
    case actionTypes.LOADER_REMOVE:
      return updateObject(state, {
        showLoader: false
      });
  }
  return state;
};

export default reducer;
