import React from 'react';
import './index.scss';

import Form from 'react-bootstrap/Form';

class DropdownInput extends React.Component {
  render() {
    return (
      <Form.Group controlId={this.props.controlId}>
        <Form.Label>{this.props.name}</Form.Label>
        <Form.Control {...this.props} as="select" onChange={(e) => this.props.onChange(e.target.value)}>
          {this.props.options && this.props.options.map((op) => <option key={op.id}>{op.value}</option>)}
        </Form.Control>
      </Form.Group>
    );
  }
}

export default DropdownInput;
