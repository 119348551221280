import paginationFactory from 'react-bootstrap-table2-paginator';
export const paginationConfig = (totalElementCount) => {
  return paginationFactory({
    totalSize: totalElementCount,
    paginationSize: 4,
    hideSizePerPage: true,
    sizePerPageList: [
      {
        text: '15',
        value: 15,
      },
    ],
    firstPageText: 'First',
    lastPageText: 'Last',
    nextPageText: 'Next',
    prePageText: 'Back',
  });
};
