import React from 'react';
import { style } from './index.scss';
import Header from '../../../components/Header';
import Home from '../home';
import SlidingMessage from '../../../components/SlidingMessage';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import InsertRecord from '../home/MFile/InsertRecord';
import MFileRecordView from '../home/MFile/mFileRecordView';
import MFileView from '../home/MFile/mFileView';
import MFileRecordEditView from '../home/MFile/MFileRecordEditView';
import ViewBatches from '../home/Batches/viewBatches';
import ViewBatchRecords from '../home/Batches/viewBatchRecords';
import ViewHigherPreference from '../home/Batches/viewHigherPreference';
import EditUpperPreference from '../home/Batches/editHigherPreferece';
import FindReports from '../home/Reports/findReports';
import Loader from '../../../components/Loader';

class LandingLayout extends React.Component {
  render() {
    return (
      <div className={style}>
        {this.props.sliderMessages ? (
          <SlidingMessage messages={this.props.sliderMessages} isSuccess={this.props.success} isDisplay={true} />
        ) : null}
        {this.props.showLoader ? <Loader/> : null}
        <Header/>
        <Switch>
          <Route path={this.props.match.url + '/find-reports'} component={FindReports} />
          <Route path={this.props.match.url + '/higherpreference-view'} component={ViewHigherPreference} />
          <Route path={this.props.match.url + '/view-batches-records'} component={ViewBatchRecords} />
          <Route path={this.props.match.url + '/view-batches'} component={ViewBatches} />
          <Route path={this.props.match.url + '/insert-record-to-m-file'} component={InsertRecord} />
          <Route path={this.props.match.url + '/edit-m-file-record/:id'} component={MFileRecordEditView} />
          <Route path={this.props.match.url + '/mfile-view/record-view'} exact component={MFileRecordView} />
          <Route path={this.props.match.url + '/mfile-view'} exact component={MFileView} />
          <Route path={this.props.match.url + '/higherpreference-edit'} component={EditUpperPreference} />
          <Route path={this.props.match.url + '/'} exact component={Home} />
        </Switch>
      </div>
    );
  }
}

const mapsStateToPros = (state) => {
  return {
    sliderMessages: state.slider.messages,
    success: state.slider.isSuccess,
    showLoader: state.loader.showLoader
  };
};

export default connect(mapsStateToPros)(LandingLayout);
