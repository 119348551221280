import React from 'react';
import './index.scss';

import Form from "react-bootstrap/Form";
import DateTimePicker from "react-datetime-picker";

class DatetimeInput extends React.Component {    
    render(){
        console.log(this.props);
        return (
                <Form.Group >
                    <Form.Label className="date-label">{this.props.children}</Form.Label>
                    <DateTimePicker {...this.props} format='yyyy-MM-dd hh:mm:ss a'/>
                    <Form.Control.Feedback type="invalid">
                        {this.props.feedback}
                    </Form.Control.Feedback>
                </Form.Group>
        );
    }  
};

DatetimeInput.propTypes = {

};

export default DatetimeInput;
