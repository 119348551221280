import React from 'react';
import './index.scss';
import Modal from 'react-bootstrap/Modal';
import SubmitButton from '../../../../../components/SubmitButton';
import FileInput from '../../../../../components/FileInput';
import InlineFeedback from '../../../../../components/InlineFeedback';
import messages from '../../../../../config/messages.json';
import axios from '../../../../../axios/axios';
import urls from '../../../../../config/url.json';
import InputField from '../../../../../components/InputField';
import labels from '../../../../../config/label.json';
import { checkValidity } from '../../../../../utility/validation';
import { checkFormValidity } from '../../../../../utility/formValidation';
import { formatMessageArgs } from '../../../../../utility/utillity';

class UploadHigherPreference extends React.Component {
    state = {
        controls: {
            academicYear: {
                valid: false,
                value: '',
                touched: false,
                rules: { required: true, academicYear: true },
            }
        },
        show: false,
        fileContent: '',
        canSubmit: false,
        backendMessage: '',
        dowloadLink: '',
        canAttach: true,
        isSuccess: '',
        formValid: false
    };
    handleShow = () => {
        this.setState({ show: true });
    };
    handleClose = () => {
        this.props.closed();
        this.setState({ show: false });
    };
    handleFileContentLoaded = (content) => {
        console.log(content);
        this.setState({ ...this.state, fileContent: content, canSubmit: true });
    };
    // buildBackendMessage = (data) => {
    //     if(data.successCount === 0){
    //         return messages.HIGHERPREFERENCE_UPLOAD_FAILD;
    //     }
    //     if (data.errorFileInfo) {
    //         return messages.FILE_UPLOAD_WITH_ERRORS;
    //     } else {
    //         return messages.FILE_UPLOAD_ZERO_ERRORS;
    //     }
    // };

    buildBackendMessage = (data) => {

        if (data.errorFileInfo) {
          if (data.successCount === 0) {
            return messages.HIGHERPREFERENCE_UPLOAD_FAILD;
          } else {
            const successcount = data.successCount
            const replace = {
              '%SUCCESSCOUNT%': successcount
            }
            const message = formatMessageArgs(messages.FILE_UPLOAD_WITH_ERRORS, replace);
            return message;
          }
          
        } else {
          const successcount = data.successCount
          const replace = {
            '%SUCCESSCOUNT%': successcount
          }
          const message = formatMessageArgs(messages.FILE_UPLOAD_ZERO_ERRORS, replace);
          return message;
        }
      };



    inputChangeHandler = (property, val) => {
        val = val.trim();
        console.log(val);
        const propertyConfig = { ...this.state.controls[property] };
        const isValid = checkValidity(val, propertyConfig.rules);
        propertyConfig['valid'] = isValid;
        propertyConfig['value'] = val;
        propertyConfig['touched'] = true;
    
        const updatedControl = {
          ...this.state.controls,
          [property]: propertyConfig,
        };
        const formValid = checkFormValidity(updatedControl);
    
        const updatedState = {
          ...this.state,
          formValid: formValid,
          controls: updatedControl,
        };
        this.setState(updatedState);
    };
    uploadHigherPreferenceFile = (e) => {
        this.setState({ ...this.state, canSubmit: false, canAttach: false });
        const academicYear  = this.state.controls.academicYear.value;
        const formData = new FormData();
        formData.append('file', this.state.fileContent);
        formData.append('academicYear', academicYear)
        axios(this.props.history, true)
          .post(urls.upperPrefFileUpload, formData)
          .then((resepose) => {
            const link = resepose.data.errorFileInfo ? resepose.data.errorFileInfo.url : '';
            this.setState({
              ...this.state,
              backendMessage: this.buildBackendMessage(resepose.data),
              dowloadLink: link,
              canSubmit: false,
              isSuccess: false,
            });
          })
          .catch((err) => console.log(err));
    };

    render() {
        return (
            <Modal
                show={true}
                onHide={() => this.handleClose()}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Upload Higher Preferences File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputField
                        name="Academic Year"
                        hint='Academic Year should be in YYYY/YYYY format'
                        onChange={(val) => this.inputChangeHandler('academicYear', val)}
                        feedback={labels.InsertMfileRecord.academicYear.feedback}
                        invalid={!this.state.controls.academicYear.valid && this.state.controls.academicYear.touched}
                        id='academicYearInputField'
                    />
                    <FileInput
                        name='File'
                        hint='upload CSV File Only'
                        hiddenFieldId='higherPreference'
                        visibleFieldId='higherPreferenceName'
                        onContentChange={this.handleFileContentLoaded}
                        canAttach={this.state.canAttach}
                    ></FileInput>
                    {this.state.backendMessage !== '' ? (
                        <InlineFeedback
                            message={this.state.backendMessage}
                            isSuccess={this.state.isSuccess}
                            isDisplay={true}
                            link={this.state.dowloadLink}
                        />
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <SubmitButton
                        variant='primary'
                        text='UPLOAD'
                        clicked={this.uploadHigherPreferenceFile}
                        disabled={!this.state.canSubmit || !this.state.formValid}
                        id='uploadButton'
                    />
                </Modal.Footer>
            </Modal>
        );
    }
};

export default UploadHigherPreference;
