import React from 'react';
import './index.scss';

class Overlay extends React.Component {
    render(){
        return (
            
            <div className={"overlay"}>
                
            </div>
        );
    }    
};

Overlay.propTypes = {

};

export default Overlay;
