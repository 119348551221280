import React from 'react';

import Modal from 'react-bootstrap/Modal';
import SubmitButton from '../../components/SubmitButton';
import InlineFeedback from '../../components/InlineFeedback';
import axios from '../../axios/axios';
import { withRouter } from 'react-router-dom';

class DeleteModel extends React.Component {
  state = {
    show: false,
    backendMessage: '',
    disabledYesButton: false
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.props.closed();
    this.setState({ show: false });
    if(this.state.disabledYesButton){
      window.location.reload(true);
    }
  };

  deleteRecord = (e) => {
    axios(this.props.history)
      .delete(this.props.url)
      .then((resepose) => {
        let message = '';
        let disabledYesButton = false;
        if (resepose.data.success === true) {
          message = 'Record Successfully Deleted.';
          disabledYesButton = true;
        } else if (resepose.data.success === false) {
          message = 'Record Can not Deleted.';
        }
        this.setState({
          ...this.state,
          backendMessage: message,
          disabledYesButton: disabledYesButton
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.handleClose()}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-detail">Are you sure you want to delete this record?</p>

          {this.state.backendMessage !== '' ? (
            <InlineFeedback message={this.state.backendMessage} isSuccess={true} isDisplay={true} />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            variant={'primary'}
            text={'YES'}
            clicked={this.deleteRecord}
            disabled={this.state.disabledYesButton}
            id={'yesButton'}
          />
          <SubmitButton
            variant={'outline-primary'}
            text={'NO'}
            clicked={() => this.handleClose()}
            disabled={false}
            id={'noButton'}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(DeleteModel);
