import React from 'react';
import './index.scss';
import axios from '../../axios/axios';
import { withRouter } from 'react-router-dom';
import urls from '../../config/url.json';

class Footer extends React.Component {

    state = {
        year: null,
    }

    componentDidMount() {

        axios(this.props.history)
          .get(urls.getapplicationYear)
          .then((response) => {
            this.setState({
                ...this.state,
                year: response.data.currentYear
            });
          });
    }

    render (){
        return (
            <footer className="footer">
                    <p>Copyright {this.state.year} University Grants Commission - Sri Lanka. All Rights Reserved.</p>
            </footer>
        );
    }
    
}
export default withRouter(Footer);
