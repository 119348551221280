import React from 'react';
import './index.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import SubmitButton from '../../../components/SubmitButton';

class StudentInitialMessage extends React.Component {
  render() {
    return (
      <div className="student-initial">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={{ span: 5, offset: 7 }} lg={{ span: 5, offset: 7 }} className="logo-section">
              <Image src={`${process.env.PUBLIC_URL}/images/ugcLogo.png`} fluid />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} className="title-section">
              <h5>REGISTRATION OF STUDENTS FOR</h5>
              <h2>COURSES OF STUDY OF UNIVERSITIES</h2>
              <div className="student-branding">
                <p>Powered by</p>
                <img src={`${process.env.PUBLIC_URL}/images/mobitelLogo.png`}></img>
              </div>
            </Col>
            <Col className="initial-message-section" xs={12} sm={12} md={6} lg={6}>
              <h6>වැදගත්:</h6>
              <p>
                කිසියම් ශිෂ්‍යයෙකු කිසියම් විශ්වවිද්‍යාලයක් මඟින් පවත්වනු ලබන යම් පාඨමාලාවක් (Uni-Code) සඳහා තේරී පත්ව ඒ
                සඳහා ලියාපදිංචි නොවුනහොත්, ඔහු/ඇය එම පාඨමාලාව පවත්වන වෙනත් විශ්වවිද්‍යාල සඳහා සිය විශ්වවිද්‍යාල ප්‍රවේශ
                අයදුම්පතෙහි ඉහළ මනාපයන් දක්වා තිබුණද පුරප්පාඩු පිරවීමේ වටයන්හිදී ඒ කිසිඳු විශ්වවිද්‍යාලයක් සඳහා එම
                පාඨමාලාව හැදෑරීම පිණිස ඔහුගේ/ඇයගේ අයදුම්පත යළි සලකා බලනු නොලැබේ. ඒ වෙනුවට ඔහු/ඇය ඊළඟට ඉහළ මනාපය සඳහන් කර
                ඇති අනෙක් පාඨමාලාව සඳහා ඔහුගේ/ඇයගේ අයදුම්පත සලකා බලනු ලැබේ.
              </p>
              <h6>முக்கியம்:</h6>
              <p>
                தெரிவு செய்யப்பட்ட ஒரு கற்கைநெறிக்கு (Uni-code)மாணவரொருவர் பதிவு செய்யாது விடின் எதிர்கால வெற்றிட
                நிர்ப்பலின் கீழ் , அதே கற்கைநெறியினை வேறு பல்கலைக்கழகங்களில் தொடர்வதற்கு மாணவரது விண்ணப்பபடிவத்தில்
                அவற்றிற்கு உயர் தெரிவு வழங்க பட்டிருப்பினும் எதிர்கால வெற்றிட நிர்ப்பலின் கீழ் , அனுமதிக்கு கருத்தில்
                கொள்ளப்பட மாட்டார். பதிலாக அவர்களது விண்ணப்பத்தில் அடுத்த விருப்பொழுங்கிலுள்ள கற்கை நெறியிற்கு அவர்கள்
                கருத்தில் கொள்ளப்படுவர்.
              </p>
              <h6>Important:</h6>
              <p>
                If a student is not getting registered to a course of study of a university (Uni-code) selected, he/she
                will not then be considered for any other university to follow the same course of study under filling of
                vacancies, even if the student has given higher preferences for the same course of study in different
                universities in the application form for university admission. Instead, his/her application will be
                considered for the next course of study preferred.
              </p>
              <Col xs={12} sm={12} md={{ span: 6, offset: 6 }} lg={{ span: 6, offset: 6 }}>
                <SubmitButton
                  variant="primary"
                  text="CONTINUE"
                  clicked={() => this.props.history.push('/registration-form')}
                  id="ContinueButton"
                />
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default StudentInitialMessage;
