import React from 'react';
import './index.scss';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from '../../axios/axios';
import { withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

class Header extends React.Component {
  handlerLogOut = () => {
    axios(this.props.history)
      .get('/logout')
      .then((response) => {
        this.props.onAuthClear();
        localStorage.removeItem('token');
        localStorage.removeItem('passwordExpire');
        this.props.history.push('/login');
      })
      .catch((error) => {
        this.props.onAuthClear();
      });
  };

  render() {
    return (
      <Navbar sticky="top">
        <Container>
          <Navbar.Brand>
            <img src={`${process.env.PUBLIC_URL}/images/ugcLogo.png`}></img>
          </Navbar.Brand>
          <a onClick={this.handlerLogOut}>
            <FontAwesomeIcon icon="sign-out-alt" />
            LOG OUT
          </a>
        </Container>
      </Navbar>
    );
  }
}

const mapsDispatchToProps = (dispatch) => {
  return {
    onAuthClear: () => dispatch(actions.authClear()),
  };
};

export default connect(null, mapsDispatchToProps)(withRouter(Header));
