import React from 'react';
import './index.scss';

import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import axios, {post} from "../../axios";

class FileInput extends React.Component {
  state = {
    fileName: '',
    filePath: '',
    fileData: '',
  };

  changeHandler = (e) => {
    console.log(e.target.value);
    const file = e.target.files[0];
    this.props.onContentChange(file);
    this.setState({ fileName: file.name });
  };

  render() {
    return (
      <div className="file-input">
        <Form.Group>
          <Form.Label>{this.props.name}</Form.Label>
          <Form.Control value={this.state.fileName} id={this.props.visibleFieldId} disabled></Form.Control>
          <div className={'hint'} style={{ display: this.props.hint ? 'block' : 'none' }}>
            <FontAwesomeIcon icon="info-circle" />
            <p>{this.props.hint}</p>
          </div>
          <label className="attach" htmlFor={this.props.hiddenFieldId}>
            <FontAwesomeIcon icon="paperclip" />
          </label>

          <input
            type="file"
            id={this.props.hiddenFieldId}
            hidden
            disabled={!this.props.canAttach}
            onChange={(e) => this.changeHandler(e)}
          />
        </Form.Group>
      </div>
    );
  }
}

FileInput.propTypes = {};

export default FileInput;
