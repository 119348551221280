import * as actionTypes from "./actionTypes";

export const sliderMessageInit = (messages, isSuccess) => {
  return {
    type: actionTypes.SLIDER_MESSAGE_INIT,
    messages: messages,
    isSuccess: isSuccess,
  };
};

export const sliderMessageRemove = (messages) => {
  return {
    type: actionTypes.SLIDER_MESSAGE_REMOVE,
    messages: messages,
  };
};
