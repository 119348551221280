import React from 'react';
import { LoopCircleLoading } from 'react-loadingg';
import './index.scss';

class Loader extends React.Component {
    componentDidMount() {
        let element = document.createElement("p");
        element.appendChild(document.createTextNode('We appreciate your patience. Please wait for the system to load your results.'));
        // document.getElementsByClassName('sc-kgoBCf').appendChild(element);

    }
    render(){
        return (
            <div className="loader-wrapper">
                <LoopCircleLoading/>
                <p className="loading-message">We appreciate your patience. Please wait for the system to load your results.</p>
            </div>
        );
    }  
};

export default Loader;
