import moment from 'moment';
export const columns = [
  {
    dataField: 'batchNo',
    text: 'Batch No',
  },
  {
    dataField: 'registrationStartDate',
    text: 'Registration Start Date',
    formatter: (cellContent) => {
      return moment(cellContent).format('Do MMMM  YYYY');
    },
  },
  {
    dataField: 'indexNumber',
    text: 'Index No',
  },
  {
    dataField: 'referenceNumber',
    text: 'Reference No',
  },
  {
    dataField: 'academicYear',
    text: 'Academic Year',
  },
  {
    dataField: 'previousRegistered',
    text: 'Previous Registered',
  },
  {
    dataField: 'registrationEndDate',
    text: 'Registration End Date',
    formatter: (cellContent) => {
      return moment(cellContent).format('Do MMMM  YYYY');
    },
  },
  {
    dataField: 'studentRegistered',
    text: 'Student Registered',
  },
  {
    dataField: 'uniCode',
    text: 'UNI Code',
  },
  {
    dataField: 'university',
    text: 'University',
  },
  {
    dataField: 'courseOfStudy',
    text: 'Course of Study',
  },
];

export const data = [
  { id: 1, name: 'Kamal', price: '$10' },
  { id: 2, name: 'Kamal', price: '$10' },
  { id: 3, name: 'Kamal', price: '$10' },
  { id: 4, name: 'Kamal', price: '$10' },
  { id: 5, name: 'Kamal', price: '$10' },
  { id: 6, name: 'Kamal', price: '$10' },
  { id: 7, name: 'Kamal', price: '$10' },
  { id: 8, name: 'Kamal', price: '$10' },
  { id: 9, name: 'Kamal', price: '$10' },
  { id: 10, name: 'Kamal', price: '$10' },
  { id: 11, name: 'Kamal', price: '$10' },
  { id: 12, name: 'Kamal', price: '$10' },
  { id: 13, name: 'Kamal', price: '$10' },
  { id: 14, name: 'Kamal', price: '$10' },
  { id: 15, name: 'Kamal', price: '$10' },
  { id: 16, name: 'Kamal', price: '$10' },
  { id: 17, name: 'Kamal', price: '$10' },
  { id: 18, name: 'Kamal', price: '$10' },
  { id: 19, name: 'Kamal', price: '$10' },
  { id: 20, name: 'Kamal', price: '$10' },
  { id: 21, name: 'Kamal', price: '$10' },
  { id: 22, name: 'Kamal', price: '$10' },
  { id: 23, name: 'Kamal', price: '$10' },
  { id: 24, name: 'Kamal', price: '$10' },
  { id: 25, name: 'Kamal', price: '$10' },
  { id: 26, name: 'Kamal', price: '$10' },
  { id: 27, name: 'Kamal', price: '$10' },
  { id: 28, name: 'Kamal', price: '$10' },
  { id: 29, name: 'Kamal', price: '$10' },
  { id: 30, name: 'Kamal', price: '$10' },
  { id: 31, name: 'Kamal', price: '$10' },
  { id: 32, name: 'Kamal', price: '$10' },
  { id: 33, name: 'Kamal', price: '$10' },
  { id: 34, name: 'Kamal', price: '$10' },
  { id: 35, name: 'Kamal', price: '$10' },
  { id: 36, name: 'Kamal', price: '$10' },
  { id: 37, name: 'Kamal', price: '$10' },
  { id: 38, name: 'Kamal', price: '$10' },
  { id: 39, name: 'Kamal', price: '$10' },
  { id: 40, name: 'Kamal', price: '$10' },
  { id: 41, name: 'Kamal', price: '$10' },
  { id: 42, name: 'Kamal', price: '$10' },
  { id: 43, name: 'Kamal', price: '$10' },
  { id: 44, name: 'Kamal', price: '$10' },
  { id: 45, name: 'Kamal', price: '$10' },
  { id: 46, name: 'Kamal', price: '$10' },
  { id: 47, name: 'Kamal', price: '$10' },
  { id: 48, name: 'Kamal', price: '$10' },
  { id: 49, name: 'Kamal', price: '$10' },
  { id: 50, name: 'Kamal', price: '$10' },
  { id: 51, name: 'Kamal', price: '$10' },
  { id: 52, name: 'Kamal', price: '$10' },
  { id: 53, name: 'Kamal', price: '$10' },
  { id: 54, name: 'Kamal', price: '$10' },
  { id: 55, name: 'Kamal', price: '$10' },
  { id: 56, name: 'Kamal', price: '$10' },
  { id: 57, name: 'Kamal', price: '$10' },
  { id: 58, name: 'Kamal', price: '$10' },
  { id: 59, name: 'Kamal', price: '$10' },
  { id: 60, name: 'Kamal', price: '$10' },
  { id: 61, name: 'Kamal', price: '$10' },
  { id: 62, name: 'Kamal', price: '$10' },
  { id: 63, name: 'Kamal', price: '$10' },
  { id: 64, name: 'Kamal', price: '$10' },
  { id: 65, name: 'Kamal', price: '$10' },
  { id: 66, name: 'Kamal', price: '$10' },
  { id: 67, name: 'Kamal', price: '$10' },
  { id: 68, name: 'Kamal', price: '$10' },
  { id: 69, name: 'Kamal', price: '$10' },
  { id: 70, name: 'Kamal', price: '$10' },
  { id: 71, name: 'Kamal', price: '$10' },
  { id: 72, name: 'Kamal', price: '$10' },
  { id: 73, name: 'Kamal', price: '$10' },
  { id: 74, name: 'Kamal', price: '$10' },
  { id: 75, name: 'Kamal', price: '$10' },
  { id: 76, name: 'Kamal', price: '$10' },
  { id: 77, name: 'Kamal', price: '$10' },
  { id: 78, name: 'Kamal', price: '$10' },
  { id: 79, name: 'Kamal', price: '$10' },
  { id: 80, name: 'Kamal', price: '$10' },
  { id: 81, name: 'Kamal', price: '$10' },
  { id: 82, name: 'Kamal', price: '$10' },
  { id: 83, name: 'Kamal', price: '$10' },
  { id: 84, name: 'Kamal', price: '$10' },
  { id: 85, name: 'Kamal', price: '$10' },
  { id: 86, name: 'Kamal', price: '$10' },
  { id: 87, name: 'Kamal', price: '$10' },
  { id: 88, name: 'Kamal', price: '$10' },
  { id: 89, name: 'Kamal', price: '$10' },
  { id: 90, name: 'Kamal', price: '$10' },
];
