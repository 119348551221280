import * as actionTypes from '../actions/actionTypes';
import axios from '../../axios/axios';

const intialState = {
  token: null,
  passwordExpired: false,
};

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      break;

    case actionTypes.AUTH_SUCCESS:
      return { ...state, token: action.token, passwordExpired: false };

    case actionTypes.AUTH_FAIL:
      return { ...state, token: '' };

    case actionTypes.AUTH_CLEAR:
      return { ...state, token: '', passwordExpired: false };

    case actionTypes.AUTH_PASSWORD_EXPIRE:
      return { ...state, passwordExpired: true };
  }
  return state;
};

export default reducer;
