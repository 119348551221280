import React from 'react';
import './index.scss';

import Modal from 'react-bootstrap/Modal';
import SubmitButton from '../../../../../components/SubmitButton';
import DatetimeInput from '../../../../../components/DatetimeInput';
import InlineFeedback from '../../../../../components/InlineFeedback';
import Form from 'react-bootstrap/Form';

import { checkValidity } from '../../../../../utility/validation';
import { checkFormValidity } from '../../../../../utility/formValidation';
import { formatMessageArgs } from '../../../../../utility/utillity';
import axios from '../../../../../axios/axios';
import messages from '../../../../../config/messages.json';
import urls from '../../../../../config/url.json';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

class CreateBatch extends React.Component {
  state = {
    controls: {
      startDate: {
        valid: false,
        value: new Date(),
        touched: false,
        rules: { dateRequired: true },
        minDate: new Date(),
        disabled: false
      },
      deadline: {
        valid: false,
        value: new Date(),
        touched: false,
        rules: { dateRequired: true },
        minDate: "",
        disabled: false
      }
    },
    backendMessage: "",
    show: false,
    formValid: false,
    isSuccess: "",
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.props.closed();
    this.setState({ show: false });
  };

  inputChangeHandler = (property, val) => {
    const propertyConfig = { ...this.state.controls[property] };
    const isValid = checkValidity(val, propertyConfig.rules);
    propertyConfig['valid'] = isValid;
    propertyConfig['value'] = val;
    propertyConfig['touched'] = true;

    const updatedControl = {
      ...this.state.controls,
      [property]: propertyConfig,
    };
    const formValid = checkFormValidity(updatedControl);

    const updatedState = {
      ...this.state,
      formValid: formValid,
      controls: updatedControl,
    };
    this.setState(updatedState);
    console.log("hello");
    console.log(updatedState);
  };

  buildBackendMessage = (batchNumber) => {
    if (batchNumber !== 0) {
      const replacements = {
        '%BATCHNO%': batchNumber,
      };
      const message = formatMessageArgs(messages.BATCH_CREATE_SUCCESS, replacements);
      return message;
    } else {
      return messages.BATCH_CREATE_FAILD;
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const formatedstartDate = moment(this.state.controls.startDate.value).format('YYYY-MM-DD HH:mm:ss');
    const formateddeadline = moment(this.state.controls.deadline.value).format('YYYY-MM-DD HH:mm:ss');
    const batchData = {
      regStartDate: formatedstartDate,
      regDeadlineDate: formateddeadline,
    };
    axios(this.props.history)
      .post(urls.createBatch, batchData)
      .then((resepose) => {
        this.setState({
          ...this.state,
          formValid: false,
          isSuccess: true,
          disabled: true,
          backendMessage: this.buildBackendMessage(resepose.data.batchNumber)
        })
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.handleClose()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a batch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="batch-info">
            <label className="batch-no">Batch No:</label>
            <label className="batch-no-msg">Your Batch Number will be automatically generated</label>
          </div>
          <DatetimeInput
            controlId="rStartDate"
            value={this.state.controls.startDate.value}
            minDate={this.state.controls.startDate.minDate}
            disabled={this.state.disabled}
            onChange={(val) => this.inputChangeHandler("startDate", val)}
            invalid={!this.state.controls.startDate.valid &&
              this.state.controls.startDate.touched}
          >Registration Start Date & Time</DatetimeInput>

          <DatetimeInput
            controlId="rDeadline"
            value={this.state.controls.deadline.value}
            minDate={this.state.controls.startDate.value}
            disabled={this.state.disabled}
            onChange={(val) => this.inputChangeHandler("deadline", val)}
            invalid={!this.state.controls.deadline.valid &&
              this.state.controls.deadline.touched}
          >Registration Deadline</DatetimeInput>

          {this.state.backendMessage !== '' ? (
            <InlineFeedback message={this.state.backendMessage} isSuccess={this.state.isSuccess} isDisplay={true} />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            variant={'primary'}
            text={'CREATE'}
            clicked={this.handleSubmit}
            disabled={!this.state.formValid}
            id={'createButton'}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(CreateBatch);
