import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from "react-tooltip";

export const columns = (handleModalShow) => [
  {
    dataField: 'indexNumber',
    text: '​Index Number',
  },
  {
    dataField: 'nameWithInitials',
    text: '​Name with initials',
  },
  {
    dataField: 'fullName',
    text: '​Full Name',
  },
  {
    dataField: 'nic',
    text: '​NIC Number',
  },
  {
    dataField: 'zscore',
    text: '​Z-Score',
  },
  {
    dataField: 'applicationYear',
    text: '​Application Year',
  },
  {
    dataField: 'academicYear',
    text: '​Academic Year',
  },
  {
    dataField: 'status',
    text: '​Status',
  },
  {
    dataField: 'id',
    hidden: true,
  },
  {
    dataField: 'f',
    text: 'Actions',
    formatter: (cellContent, row) => {
      const viewClasses = row.canView ? 'action-button' : 'action-button link-disabled';
      const editClasses = row.canEdit ? 'action-button' : 'action-button link-disabled';
      //let deleteClasses = ['action-button'];
      let deleteClasses = row.canDelete ? 'action-button' : 'action-button link-disabled delete';
      let disableMessageClass = row.canDelete ? 'delete-msg-none' : 'delete-msg-visible'

      let disableMessage = '';
      if (row.hasBatchRecord && row.hasUpperPrefRecord) {
        disableMessage = "This student has batch records and higher preferences. Delete them to delete the M file record."
      } else {
        if (row.hasBatchRecord) {
          disableMessage = "This student is in a batch. Click on View to find out the batch records"
        }
        if (row.hasUpperPrefRecord) {
          disableMessage = "This student has higher preferences. Delete its higher preferences to delete the M file record"
        }
      }
      return (
        <span className='actionset'>
          <Link className={viewClasses} to={'/home/mfile-view/record-view?indexNumber=' + row.indexNumber}>
            <FontAwesomeIcon icon="eye" data-tip="View Batches"/>
          </Link>
          <Link className={editClasses} to={'/home/edit-m-file-record/' + row.id}>
            <FontAwesomeIcon icon="pen" data-tip="Edit M File"/>
          </Link>
          <Link className={deleteClasses}>
            <FontAwesomeIcon icon="trash-alt" onClick={(e) => handleModalShow('deleteModal', true, row.id)} data-tip="Delete M File"/>
          </Link>
          <div className={disableMessageClass}>
            <p className='delete-msg'>{disableMessage}</p>
          </div>
          <ReactTooltip place='bottom'/>
        </span>
      );
    },
  },
];

//canDelete: true
//canEdit: true
//canView: false
export const data = [
  { id: 1, name: 'Kamal', price: '$10' },
  { id: 2, name: 'Kamal', price: '$10' },
  { id: 3, name: 'Kamal', price: '$10' },
  { id: 4, name: 'Kamal', price: '$10' },
  { id: 5, name: 'Kamal', price: '$10' },
  { id: 6, name: 'Kamal', price: '$10' },
  { id: 7, name: 'Kamal', price: '$10' },
  { id: 8, name: 'Kamal', price: '$10' },
  { id: 9, name: 'Kamal', price: '$10' },
  { id: 10, name: 'Kamal', price: '$10' },
  { id: 11, name: 'Kamal', price: '$10' },
  { id: 12, name: 'Kamal', price: '$10' },
  { id: 13, name: 'Kamal', price: '$10' },
  { id: 14, name: 'Kamal', price: '$10' },
  { id: 15, name: 'Kamal', price: '$10' },
  { id: 16, name: 'Kamal', price: '$10' },
  { id: 17, name: 'Kamal', price: '$10' },
  { id: 18, name: 'Kamal', price: '$10' },
  { id: 19, name: 'Kamal', price: '$10' },
  { id: 20, name: 'Kamal', price: '$10' },
  { id: 21, name: 'Kamal', price: '$10' },
  { id: 22, name: 'Kamal', price: '$10' },
  { id: 23, name: 'Kamal', price: '$10' },
  { id: 24, name: 'Kamal', price: '$10' },
  { id: 25, name: 'Kamal', price: '$10' },
  { id: 26, name: 'Kamal', price: '$10' },
  { id: 27, name: 'Kamal', price: '$10' },
  { id: 28, name: 'Kamal', price: '$10' },
  { id: 29, name: 'Kamal', price: '$10' },
  { id: 30, name: 'Kamal', price: '$10' },
  { id: 31, name: 'Kamal', price: '$10' },
  { id: 32, name: 'Kamal', price: '$10' },
  { id: 33, name: 'Kamal', price: '$10' },
  { id: 34, name: 'Kamal', price: '$10' },
  { id: 35, name: 'Kamal', price: '$10' },
  { id: 36, name: 'Kamal', price: '$10' },
  { id: 37, name: 'Kamal', price: '$10' },
  { id: 38, name: 'Kamal', price: '$10' },
  { id: 39, name: 'Kamal', price: '$10' },
  { id: 40, name: 'Kamal', price: '$10' },
  { id: 41, name: 'Kamal', price: '$10' },
  { id: 42, name: 'Kamal', price: '$10' },
  { id: 43, name: 'Kamal', price: '$10' },
  { id: 44, name: 'Kamal', price: '$10' },
  { id: 45, name: 'Kamal', price: '$10' },
  { id: 46, name: 'Kamal', price: '$10' },
  { id: 47, name: 'Kamal', price: '$10' },
  { id: 48, name: 'Kamal', price: '$10' },
  { id: 49, name: 'Kamal', price: '$10' },
  { id: 50, name: 'Kamal', price: '$10' },
  { id: 51, name: 'Kamal', price: '$10' },
  { id: 52, name: 'Kamal', price: '$10' },
  { id: 53, name: 'Kamal', price: '$10' },
  { id: 54, name: 'Kamal', price: '$10' },
  { id: 55, name: 'Kamal', price: '$10' },
  { id: 56, name: 'Kamal', price: '$10' },
  { id: 57, name: 'Kamal', price: '$10' },
  { id: 58, name: 'Kamal', price: '$10' },
  { id: 59, name: 'Kamal', price: '$10' },
  { id: 60, name: 'Kamal', price: '$10' },
  { id: 61, name: 'Kamal', price: '$10' },
  { id: 62, name: 'Kamal', price: '$10' },
  { id: 63, name: 'Kamal', price: '$10' },
  { id: 64, name: 'Kamal', price: '$10' },
  { id: 65, name: 'Kamal', price: '$10' },
  { id: 66, name: 'Kamal', price: '$10' },
  { id: 67, name: 'Kamal', price: '$10' },
  { id: 68, name: 'Kamal', price: '$10' },
  { id: 69, name: 'Kamal', price: '$10' },
  { id: 70, name: 'Kamal', price: '$10' },
  { id: 71, name: 'Kamal', price: '$10' },
  { id: 72, name: 'Kamal', price: '$10' },
  { id: 73, name: 'Kamal', price: '$10' },
  { id: 74, name: 'Kamal', price: '$10' },
  { id: 75, name: 'Kamal', price: '$10' },
  { id: 76, name: 'Kamal', price: '$10' },
  { id: 77, name: 'Kamal', price: '$10' },
  { id: 78, name: 'Kamal', price: '$10' },
  { id: 79, name: 'Kamal', price: '$10' },
  { id: 80, name: 'Kamal', price: '$10' },
  { id: 81, name: 'Kamal', price: '$10' },
  { id: 82, name: 'Kamal', price: '$10' },
  { id: 83, name: 'Kamal', price: '$10' },
  { id: 84, name: 'Kamal', price: '$10' },
  { id: 85, name: 'Kamal', price: '$10' },
  { id: 86, name: 'Kamal', price: '$10' },
  { id: 87, name: 'Kamal', price: '$10' },
  { id: 88, name: 'Kamal', price: '$10' },
  { id: 89, name: 'Kamal', price: '$10' },
  { id: 90, name: 'Kamal', price: '$10' },
];
