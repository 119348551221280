import React from 'react';
import './index.scss';
import Modal from 'react-bootstrap/Modal';
import { withRouter } from 'react-router-dom';
import FileInput from '../../../components/FileInput';
import SubmitButton from '../../../components/SubmitButton';
import InputField from '../../../components/InputField';
import DropdownInput from '../../../components/DropdownInput';
import DatetimeInput from '../../../components/DatetimeInput';
import InlineFeedback from '../../../components/InlineFeedback';
import SearchableDropdown from '../../../components/SearchableDropdown';
import { checkValidity } from '../../../utility/validation';
import { checkFormValidity } from '../../../utility/formValidation';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import axios from '../../../axios/axios';
import urls from '../../../config/url.json';
import labels from '../../../config/label.json';

class PaidtoBank extends React.Component {
    state = {
        controls: {
            slipNo: {
                valid: false,
                value: '',
                touched: false,
                rules: { required: true, slipNumber: true },
            },
            bank: {
                valid: false,
                value: '',
                touched: false,
                options: [],
                rules: { Dropdownrequired: true },
            },
            branch: {
                valid: false,
                value: [],
                touched: false,
                options: [],
                rules: { SearchableDropdownrequired: true },
            },
            paymentDate: {
                valid: false,
                value: new Date(),
                touched: false,
                rules: { dateRequired: true },
            },
        },
        fileContent: '',
        canSubmit: false,
        backendMessage: '',
        canAttach: true,
        isSuccess: '',
        formValid: false,
        branchDisable: true,
        receiptSubmitSuccessfully:false
    };
    componentDidMount() {
        // console.log(this.props.refNo);
        axios(this.props.history)
            .get(urls.getBankList)
            .then((response) => {
                const options = [{ id: '0', value: 'please select' }];
                const bankList = response.data.map((element) => {
                    return { id: element.id, value: element.bankName };
                });
                Array.prototype.push.apply(options, bankList);
                const updatedState = { ...this.state };
                updatedState.controls.bank.options = options;
                this.setState(updatedState);
            });
        const a = { ...this.state };
        a.controls.branch.options = [{ id: '0', value: 'please select' }];
    }
    handleShow = () => {
        this.setState({ show: true });
    };
    handleClose = () => {
        this.props.closed();
        this.setState({ show: false });
    };
    handleFileContentLoaded = (content) => {
        // console.log('loading file');
        // console.log(content);
        this.setState({ ...this.state, fileContent: content, canSubmit: true });
    };
    getBranches = (bankname) => {
        const bankName = bankname;
        if (bankName != 'please select') {
            axios(this.props.history)
                .get(urls.getBranchList + bankName)
                .then((response) => {
                    const branchList = response.data.map((element) => {
                        return { id: element.branchCode, label: element.branchName };
                    });
                    const updatedState = { ...this.state };
                    updatedState.controls.branch.options = branchList;
                    updatedState.branchDisable = false;
                    this.setState(updatedState);
                });
        } else {
            const updatedState = { ...this.state };
            updatedState.controls.branch.options = [];
            updatedState.controls.branch.value = null;
            updatedState.branchDisable = true;
            this.setState(updatedState);
        }
    };

    inputChangeHandler = (property, val) => {
        if (property === 'paymentDate' || property === 'branch') {
            val = val;
        } else {
            val = val.trim();
        }
        const propertyConfig = { ...this.state.controls[property] };
        const isValid = checkValidity(val, propertyConfig.rules);
        propertyConfig['valid'] = isValid;
        propertyConfig['value'] = val;
        propertyConfig['touched'] = true;

        const updatedControl = {
            ...this.state.controls,
            [property]: propertyConfig,
        };
        const formValid = checkFormValidity(updatedControl);

        const updatedState = {
            ...this.state,
            formValid: formValid,
            controls: updatedControl,
        };
        this.setState(updatedState);
        const bankname = updatedState.controls.bank.value;
        if (property === 'bank') {
            this.getBranches(bankname);
        }
    };

    submitForm = () =>{
        const emailAddress = this.props.email;
        const fixedLine = this.props.fixedLine;
        const mobileNumber = this.props.mobileNo;
        const referenceNumber = this.props.refNo;
        const upperPrefDataList = this.props.upperPrefDataList;
        const contactData = {
          emailAddress: emailAddress,
          fixedLine: fixedLine,
          mobileNumber: mobileNumber,
        };
        const studentData = {
          contactData: contactData,
          paymentMethod: 'BANK',
          refNo: referenceNumber,
          upperPrefDataList:upperPrefDataList
        };

        axios(this.props.history)
            .post(urls.registerStudent, studentData)
            .then((reseponse) => {
              console.log(reseponse.data.success);
              if(reseponse.data.success){
                this.props.history.push({pathname:'/registration-success', state:{detail: referenceNumber}}, );
              }
              this.setState({
                ...this.state,
                formValid: false,
              });
            })
            .catch((err) => console.log(err));

    }

    uploadSlip = (e) => {
        const slipNumber = this.state.controls.slipNo.value;
        const formatedpaymentDate = moment(this.state.controls.paymentDate.value).format('YYYY-MM-DDTHH:mm');
        const paymentDate = formatedpaymentDate;
        const bankName = this.state.controls.bank.value;
        const bankarray = this.state.controls.bank.options;
        const findbankId = bankarray.find((o) => o.value === bankName);
        const bankCode = findbankId.id;
        const branchName = this.state.controls.branch.value;
        const branchCode = branchName[0].id;
        const referenceNumber = this.props.refNo;

        const formData = new FormData();
        formData.append('file', this.state.fileContent);
        formData.append('bankCode', bankCode);
        formData.append('branchCode', branchCode);
        formData.append('paymentDate', paymentDate);
        formData.append('referenceNumber', referenceNumber);
        formData.append('slipNumber', slipNumber);


        axios(this.props.history)
            .post(urls.submitBankReceipt, formData)
            .then((resepose) => {
                console.log(resepose);
                if(resepose.data.status){
                    this.submitForm();
                }
                this.setState({
                    ...this.state,
                    canSubmit: false,
                    isSuccess: false,
                });
            })
            .catch((err) => console.log(err));
    };

    render() {
        return (
            <Modal
                show={true}
                onHide={() => this.handleClose()}
                size="lg"
                className="Paid-to-Bank-Modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Paid to Bank</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <FileInput
                            canAttach={this.state.canAttach}
                            name="Copy of the Bank Slip/බැංකු කුවිතාන්සියේ පිටපත/வங்கிப் பற்றுச் சீட்டின் பிரதி:"
                            hint="Please select following formats. doc, docx, pdf, jpg, JPEG, png. Maximum File size should be 1 MB."
                            hiddenFieldId="bankSlipFile"
                            visibleFieldId="bankSlipFileName"
                            onContentChange={this.handleFileContentLoaded}
                        />
                        {this.state.backendMessage !== '' ? (
                            <InlineFeedback message={this.state.backendMessage} isSuccess={this.state.isSuccess} isDisplay={true} />
                        ) : null}
                        <SubmitButton
                            variant="primary"
                            text="UPLOAD"
                            className="bslip-upload"
                            // clicked={this.uploadSlip}
                            disabled={!this.state.canSubmit}
                            id="bankSlipUploadButton"
                        />
                    </Row>
                    <Row className="normal-inputs">
                        <InputField
                            name="Slip Number/ බැංකු කුවිතාන්සි අංකය/பற்றுசீட்டு எண்:"
                            type="text"
                            feedback={labels.StudentRegistration.paidtoBank.slipNo.feedback}
                            invalid={!this.state.controls.slipNo.valid && this.state.controls.slipNo.touched}
                            onChange={(val) => this.inputChangeHandler('slipNo', val)}
                            id="slipNoInputField"
                        />
                    </Row>
                    <Row className="normal-inputs">
                        <DropdownInput
                            name="Bank/ බැංකුව/ வங்கி"
                            feedback={labels.StudentRegistration.paidtoBank.selectBank.feedback}
                            onChange={(val) => this.inputChangeHandler('bank', val)}
                            options={this.state.controls.bank.options}
                            id="selectBankDropdown"
                        />
                    </Row>
                    <Row className="normal-inputs">
                        <SearchableDropdown
                            Label="Branch/ බැංකු  ශාඛාව/ கிளை"
                            id="selectBranchDropdown"
                            placeholder="Choose Branch"
                            onChange={(val) => this.inputChangeHandler('branch', val)}
                            options={this.state.controls.branch.options} 
                            disabled = {this.state.branchDisable}/>
                        {/* <DropdownInput
                            name="Branch/ බැංකු  ශාඛාව/ கிளை"
                            feedback={labels.StudentRegistration.paidtoBank.selectBranch.feedback}
                            onChange={(val) => this.inputChangeHandler('branch', val)}
                            options={this.state.controls.branch.options}
                            id="selectBranchDropdown"
                        /> */}
                    </Row>
                    <Row>
                        <DatetimeInput
                            value={this.state.controls.paymentDate.value}
                            feedback={labels.StudentRegistration.paidtoBank.paymentDate.feedback}
                            onChange={(val) => this.inputChangeHandler('paymentDate', val)}
                            invalid={!this.state.controls.paymentDate.valid && this.state.controls.paymentDate.touched}
                            // maxDate={this.state.controls.paymentDate.value}
                            id="paymentDate"
                        >Payment Date and Time as Displayed on the Slip/ කුවිතාන්සියේ සඳහන් පරිදි මුදල් ගෙවූ දිනය සහ වේලාව
            பற்றுச் சீட்டில் குறிப்பிட்டுள்ளவாறு பணம் செலுத்திய திகதியும் நேரமும்</DatetimeInput>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SubmitButton
                        variant='primary'
                        text='SUBMIT'
                        clicked={this.uploadSlip}
                        disabled={!this.state.formValid || !this.state.canSubmit || this.state.branchDisable}
                        id='paidtoBankSubmitButton'
                    />
                </Modal.Footer>
            </Modal>
        );
    }
};

export default withRouter(PaidtoBank);
