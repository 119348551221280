import React from "react";
import Button from "react-bootstrap/Button";
import "./index.scss";

class SubmitButton extends React.Component {
  clickHnadler = (e) => {
    if (this.props.clicked) {
      this.props.clicked(e);
    }
  };
  render() {
    return (
      <Button
        className={this.props.className}
        variant={this.props.variant}
        disabled={this.props.disabled}
        onClick={(e) => this.clickHnadler(e)}
        type="submit"
        block
        id={this.props.id} 
      >
        {this.props.text}
      </Button>
    );
  }
}

export default SubmitButton;
