import React from 'react';
import Form from 'react-bootstrap/Form';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class InputField extends React.Component {
  render() {
    return (
      <div>
        <Form.Group controlId={this.props.controlId}>
          <Form.Label>{this.props.name}</Form.Label>
          <Form.Control
            {...this.props}
            isInvalid={this.props.invalid}
            onChange={(e) => this.props.onChange(e.target.value)}
          />
          <div className={'hint'} style={{ display: this.props.hint ? 'block' : 'none' }}>
            <FontAwesomeIcon icon="info-circle" />
            <p>{this.props.hint}</p>
          </div>
          <Form.Control.Feedback type="invalid">{this.props.feedback}</Form.Control.Feedback>
        </Form.Group>
      </div>
    );
  }
}

export default InputField;
