import React from 'react';
import './index.scss';

import Modal from 'react-bootstrap/Modal';
import SubmitButton from '../../../../../components/SubmitButton';
import DatetimeInput from '../../../../../components/DatetimeInput';
import InlineFeedback from '../../../../../components/InlineFeedback';
import Form from 'react-bootstrap/Form';

import { checkValidity } from '../../../../../utility/validation';
import { checkFormValidity } from '../../../../../utility/formValidation';
import { formatMessageArgs } from '../../../../../utility/utillity';
import axios from '../../../../../axios/axios';
import messages from '../../../../../config/messages.json';
import urls from '../../../../../config/url.json';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import InputField from '../../../../../components/InputField';
import { updateControlValues } from '../../../../../utility/formValidation';
import { withRouter } from 'react-router-dom';

class EditBatch extends React.Component {
  state = {
    controls: {
      batchNumber: {
        value: '',
        valid: true,
      },
      startDate: {
        valid: true,
        value: '',
        touched: false,
        rules: { dateRequired: true },
        minDate: '',
        disabled: false,
      },
      deadline: {
        valid: true,
        value: '',
        touched: false,
        rules: { dateRequired: true },
        minDate: '',
        disabled: false,
      },
    },
    backendMessage: '',
    show: false,
    formValid: true,
    isSuccess: '',
    id: '',
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.props.closed();
    this.setState({ show: false });
    if(this.state.isSuccess){
      window.location.reload(true);
    }
  };

  componentDidMount() {
    let batchId = this.props.batchNo;
    console.log(batchId);
    axios(this.props.history)
    .get(urls.getUpdateData + batchId).then((response) => {
      const registerStartDate = moment(response.data.regStartDate).toDate();
      const registerDeadline = moment(response.data.regDeadlineDate).toDate();
      const responseData = { startDate: registerStartDate, deadline: registerDeadline };
      const updatedControls = updateControlValues(this.state.controls, responseData);
      const updatedState = { ...this.state, controls: updatedControls, id: batchId };
      updatedState.controls.startDate.minDate = registerStartDate;
      this.setState(updatedState);
    });
  }

  inputChangeHandler = (property, val) => {
    const propertyConfig = { ...this.state.controls[property] };
    const isValid = checkValidity(val, propertyConfig.rules);
    propertyConfig['valid'] = isValid;
    propertyConfig['value'] = val;
    propertyConfig['touched'] = true;

    const updatedControl = {
      ...this.state.controls,
      [property]: propertyConfig,
    };
    const formValid = checkFormValidity(updatedControl);

    const updatedState = {
      ...this.state,
      formValid: formValid,
      controls: updatedControl,
    };
    this.setState(updatedState);
    console.log('hello world find');
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let batchId = this.props.batchNo;
    const formatedstartDate = moment(this.state.controls.startDate.value).format('YYYY-MM-DD HH:mm:ss');
    const formateddeadline = moment(this.state.controls.deadline.value).format('YYYY-MM-DD HH:mm:ss');
    const batchData = {
      regStartDate: formatedstartDate,
      regDeadlineDate: formateddeadline,
    };
    axios(this.props.history)
      .put(urls.updateBatch + batchId, batchData)
      .then((resepose) => {
        this.setState({
          ...this.state,
          formValid: false,
          isSuccess: true,
          disabled: true,
          backendMessage: this.buildBackendMessage(resepose.data.batchNumber),
        });
      })
      .catch((err) => console.log(err));
  };

  buildBackendMessage = (batchNumber) => {
    if (batchNumber !== 0) {
      const replacements = {
        '%BATCHNO%': batchNumber,
      };
      const message = formatMessageArgs(messages.EDIT_BATCH_SUCCESS, replacements);
      return message;
    } else {
      return messages.EDIT_BATCH_FAILD;
    }
  };

  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.handleClose()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit batch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputField name={'Batch No:'} type={'text'} value={this.props.batchNo} readonly="readonly" id={'batchNoInputField'} />
          <DatetimeInput
            controlId="rStartDate"
            value={this.state.controls.startDate.value}
            minDate={this.state.controls.startDate.minDate}
            disabled={this.state.disabled}
            onChange={(val) => this.inputChangeHandler('startDate', val)}
            invalid={!this.state.controls.startDate.valid && this.state.controls.startDate.touched}
          >
            Registration Start Date & Time
          </DatetimeInput>

          <DatetimeInput
            controlId="rDeadline"
            value={this.state.controls.deadline.value}
            minDate={this.state.controls.startDate.value}
            disabled={this.state.disabled}
            onChange={(val) => this.inputChangeHandler('deadline', val)}
            invalid={!this.state.controls.deadline.valid && this.state.controls.deadline.touched}
          >
            Registration Deadline
          </DatetimeInput>

          {this.state.backendMessage !== '' ? (
            <InlineFeedback message={this.state.backendMessage} isSuccess={this.state.isSuccess} isDisplay={true} />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            variant={'primary'}
            text={'SAVE'}
            clicked={this.handleSubmit}
            disabled={!this.state.formValid}
            id={'saveButton'}
          />
          <Button style={{ width: '100%' }} variant="outline-primary" onClick={() => this.handleClose()} id={'cancelButton'} >
            CANCEL
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(EditBatch);
