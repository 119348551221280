import React from 'react';
import logo from './logo.svg';
import './App.css';
import LoginLayout from './pages/login/loginLayout';
import LandingLayout from './pages/landing/landingLayout';
import Footer from './components/Footer';
import { Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './store/actions/authActions';
import StudentLayout from './pages/student/studentLayout';
import PasswordExpirypage from './pages/landing/passwordExpirypage';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCloudUploadAlt,
  faEye,
  faCloudDownloadAlt,
  faIndent,
  faUsers,
  faPen,
  faUnlockAlt,
  faSearch,
  faSignOutAlt,
  faInfoCircle,
  faPaperclip,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
library.add(
  faCloudUploadAlt,
  faEye,
  faCloudDownloadAlt,
  faIndent,
  faUsers,
  faPen,
  faUnlockAlt,
  faSearch,
  faSignOutAlt,
  faInfoCircle,
  faPaperclip,
  faTrashAlt
);

function App() {
  // const dispatch = useDispatch();
  // const tokenFromStore = localStorage.getItem('token');
  // if (tokenFromStore) {
  //   dispatch(actions.authSuccess(localStorage.getItem('token')));
  // }
  const token = useSelector((state) => state.auth.token);
  const isAuthenticated = token !== null;
  const passwordExpired = useSelector((state) => state.auth.passwordExpired);
  return (
    <div className="App">
      <Switch>
        {isAuthenticated && !passwordExpired ? <Route path="/home" component={LandingLayout} /> : null}
        <Route path="/login" component={LoginLayout} />
        <Route path="/password-expire" component={PasswordExpirypage} />
        <Route path="/" component={StudentLayout} />
        <Route path="/tmp" render={() => <div>tmp</div>} />

        <Route render={() => <h1>404</h1>} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
