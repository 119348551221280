import React from 'react';
import './index.scss';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardButton from '../../../components/CardButton';
import UploadMfile from './MFile/uploadMfile';
import UploadBatch from '../home/Batches/uploadBatch';
import axios from '../../../axios/axios';
import ChangePassInit from '../../../components/ChangePassInit';
import CreateBatch from './Batches/createBatch';
import InsertRecord from './MFile/InsertRecord';

import urls from '../../../config/url.json';
import UploadHigherPreference from './Batches/uploadHigherPreference';
import UgcBreadcrumb from '../../../components/UgcBreadcrumb';
import bPaths from '../../../config/breadcrumbPaths.json';

class Home extends React.Component {
  state = {
    showModal: {
      mFile: false,
      higherPreference: false,
      createBatch: false,
      masterData: false,
      editHigerPref: false,
    },
  };

  downloadMfile() {
    axios(this.props.history)
      .get(urls.downloadMfile)
      .then((response) => {
        const downloadMfile = response.data.downloadFile.url;
        let a = document.createElement('a');
        a.href = downloadMfile;
        a.click();
      });
  }

  handleCardShow = (modal, show) => {
    console.log(modal, show);
    const updatedShowModal = { ...this.state.showModal, [modal]: show };
    const updatedState = { ...this.state, showModal: updatedShowModal };
    this.setState(updatedState);
  };

  render() {
    let modal = null;
    if (this.state.showModal.mFile) {
      modal = (
        <UploadMfile
          closed={() => {
            this.handleCardShow('mFile', false);
          }}
        ></UploadMfile>
      );
    } else if (this.state.showModal.higherPreference) {
      modal = (
        <UploadHigherPreference
          closed={() => {
            this.handleCardShow('higherPreference', false);
          }}
        ></UploadHigherPreference>
      );
    } else if (this.state.showModal.createBatch) {
      modal = (
        <CreateBatch
          closed={() => {
            this.handleCardShow('createBatch', false);
          }}
        />
      );
    } else if (this.state.showModal.editHigerPref) {
      modal = (
        <CreateBatch
          closed={() => {
            this.handleCardShow('editHigerPref', false);
          }}
        />
      );
    } else if (this.state.showModal.masterData) {
      modal = null;
    }

    return (
      <div className="home">
        <Container>
          <Row>
            <Col xs={12} sm={8} md={8} lg={8}>
            <UgcBreadcrumb bPaths={bPaths.home} />
            </Col>
            <Col xs={12} sm={4} md={4} lg={4}>
              <ChangePassInit />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="section-title">Manage M File</p>
              <CardButton
                btnName="UPLOAD M-FILE"
                btnCat="card-btn"
                iconName="cloud-upload-alt"
                onClick={() => this.handleCardShow('mFile', true)}
                id="uploadMFileButton"
              ></CardButton>
              <CardButton
                btnName="MASTER DATA"
                btnCat="card-btn"
                iconName="eye"
                onClick={() => this.props.history.push('home/mfile-view')}
                id="masterDataButton"
              ></CardButton>
              <CardButton
                btnName="DOWNLOAD M-FILE"
                btnCat="card-btn"
                iconName="cloud-download-alt"
                onClick={() => this.downloadMfile()}
                id="downloadMFileButton"
              ></CardButton>
              <CardButton
                btnName="INSERT A RECORD TO M-FILE"
                btnCat="card-btn"
                iconName="indent"
                onClick={() => this.props.history.push('/home/insert-record-to-m-file')}
                id="insertRecordMFileButton"
              ></CardButton>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="section-title">Manage Batches and Preferences</p>
              <CardButton
                btnName="CREATE NEW BATCH"
                btnCat="card-btn"
                iconName="users"
                onClick={() => this.handleCardShow('createBatch', true)}
                id="createNewBatchButton"
              ></CardButton>
              <CardButton
                btnName="VIEW/ADD/EDIT BATCH DATA"
                btnCat="card-btn"
                iconName="eye"
                onClick={() => this.props.history.push('/home/view-batches')}
                id="viewBatchButton"
              ></CardButton>
              <CardButton
                btnName="UPLOAD HIGHER PREFERENCE FILE"
                btnCat="card-btn"
                iconName="cloud-upload-alt"
                onClick={() => this.handleCardShow('higherPreference', true)}
              ></CardButton>
              <CardButton
                btnName="VIEW HIGHER PREFERENCE FILE"
                btnCat="card-btn"
                iconName="eye"
                onClick={() => this.props.history.push('home/higherpreference-view')}
                id="viewHigherPreferenceButton"
              ></CardButton>
              <CardButton
                btnName="EDIT HIGHER PREFERENCE FILE"
                btnCat="card-btn"
                iconName="pen"
                onClick={() => this.props.history.push('home/higherpreference-edit')}
                id="editHigherPreferenceButton"
              ></CardButton>
              {/* <CardButton
                btnName="DOWNLOAD HIGHER PREFERENCE FILE"
                btnCat="card-btn"
                iconName="cloud-download-alt"
                id="downloadHigherPreferenceButton"
              ></CardButton> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="section-title">Manage Reports</p>
              <CardButton
                btnName="FIND REPORTS"
                btnCat="card-btn"
                iconName="search"
                onClick={() => this.props.history.push('home/find-reports')}
                id="findReportsButton"
              ></CardButton>
            </Col>
          </Row>
          {modal}
        </Container>
      </div>
    );
  }
}

export default Home;
