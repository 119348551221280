import React from "react";
import "./index.scss";

import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class CardButton extends React.Component {
  render() {
    const {
      btnCat,iconName,btnName,...props
    }= this.props;
    return (
      <Button className={this.props.btnCat} {...props} id={this.props.id} >
        <FontAwesomeIcon icon={this.props.iconName} className="fa-lg"/>
        <p>{this.props.btnName}</p>
      </Button>
    );
  }
}

CardButton.propTypes = {};

export default CardButton;
