import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

export const columns = (handleModalShow) => {
  return [
    {
      dataField: 'id',
      hidden: true,
    },
    {
      dataField: 'indexNumber',
      text: 'Index Number',
    },
    {
      dataField: 'academicYear',
      text: "Academic Year"
    },
    {
      dataField: 'referenceNumber',
      text: 'Reference Number',
    },
    {
      dataField: 'nameWithInitials',
      text: 'Name with Initials',
    },
    {
      dataField: 'intakeCategory',
      text: 'Intake Category',
    },
    {
      dataField: 'uniCode',
      text: 'UNI Code',
    },
    {
      dataField: 'previousRegistered',
      text: 'Previous Registered',
    },
    {
      dataField: 'studentRegisterd',
      text: 'Student Registered',
    },
    {
      dataField: 'applicationYear',
      text: 'Application Year',
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: (cellContent,row) => {
        let editClass = row.canEdit ? 'action-button' : 'action-button link-disabled';
        let deleteClass = row.canDelete ? 'action-button' : 'action-button link-disabled';
        return (
            <span>
                <Link className="action-button">
                  <FontAwesomeIcon 
                  icon="eye"
                  data-tip="View Record Details"
                  onClick={(e) => {
                      e.preventDefault();
                      handleModalShow('viewBatchRecordDetails', true, row.batchNumber, row.id, row.referenceNumber);
                    }
                  }
                  />
                </Link>
                <Link className={editClass}>
                  <FontAwesomeIcon 
                  icon="pen"
                  data-tip="Edit Record"
                  onClick={(e) => {
                      e.preventDefault();
                      handleModalShow('editRecordBatch', true, row.batchNumber, row.id);
                    }
                  } 
                  />
                </Link>
                <Link className={deleteClass}>
                  <FontAwesomeIcon 
                  icon="trash-alt"
                  data-tip="Delete Record"
                  onClick={(e) => {
                      e.preventDefault();
                      handleModalShow('deleteModal', true, row.batchNumber, row.id);
                    }
                  }
                  />
                </Link>
                <ReactTooltip place='bottom'/>
            </span>
          );
      }
    }
  ];
};
  
  export const data = [];
  