import React from 'react';
import './index.scss';

class InlineFeedback extends React.Component {
  state = {
    isSuccess: '',
    isDisplay: true,
  };
  render() {
    const classes = ['inline-fb-msg'];
    if (this.state.isSuccess) {
      classes.push('success');
    } else {
      classes.push('error');
    }
    classes.push('success');
    if (!this.state.isDisplay) {
      classes.push('close-fb-msg');
    }
    return (
      <div>
        <div className={classes.join(' ')}>
          <p>{this.props.message}</p>
        </div>
        {!this.props.link ? null : (
          <a className="error-link" href={this.props.link}>
            Please Click here to download error file
          </a>
        )}
      </div>
    );
  }
}

InlineFeedback.propTypes = {};

export default InlineFeedback;
