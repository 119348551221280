import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/updateObject';
const instialState = {
  messages: '',
  isSuccess: true,
};

const reducer = (state = instialState, action) => {
  switch (action.type) {
    case actionTypes.SLIDER_MESSAGE_INIT:
      return updateObject(state, {
        messages: action.messages,
        isSuccess: action.isSuccess,
      });
    case actionTypes.SLIDER_MESSAGE_REMOVE:
      return updateObject(state, {
        messages: null,
      });
  }
  return state;
};

export default reducer;
