import React from 'react';

import Modal from 'react-bootstrap/Modal';
import SubmitButton from '../../../../../components/SubmitButton';
import InlineFeedback from '../../../../../components/InlineFeedback';
import axios from '../../../../../axios/axios';
import { withRouter } from 'react-router-dom';

class RemoveHigherPreferece extends React.Component {
  state = {
    show: false,
    backendMessage: '',
    disabledYesButton: false
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.props.closed();
    this.setState({ show: false });
  };

  deleteButtonAction = (e) => {
    
    this.props.editHigherPreferences('YES');
    this.handleClose();
  };

  cancelButtonAction = (e) => {
    
    this.props.editHigherPreferences('NO');
    this.handleClose();
  };

  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.handleClose()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Remove Selected Higher Preferences</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-detail">Uni-codes (uni-code {this.props.uniCodeList}) are included in vacancy type reference numbers. Do you want to remove them as well? </p>

          {this.state.backendMessage !== '' ? (
            <InlineFeedback message={this.state.backendMessage} isSuccess={true} isDisplay={true} />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            variant={'primary'}
            text={'YES'}
            clicked={() => this.deleteButtonAction()}
            disabled={this.state.disabledYesButton}
            id={'yesButton'}
          />
          <SubmitButton
            variant={'outline-primary'}
            text={'NO'}
            clicked={() => this.cancelButtonAction()}
            disabled={false}
            id={'noButton'}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(RemoveHigherPreferece);
