import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { style } from './index.scss';

import LoginForm from '../loginForm';
import ResetpassForm from '../resetpassForm';
import FogotpassForm from '../fogotpassForm';
import SlidingMessage from '../../../components/SlidingMessage';

import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

class LoginLayout extends React.Component {
  state = {
    showSlider: true,
  };

  closeSlider = () => {
    this.setState({ ...this.state, showSlider: false });
  };

  doLogout() { }

  render() {
    console.log(this.props.match.url);
    return (
      <div className={style}>
        <Container fluid>
          {this.props.sliderMessages ? (
            <SlidingMessage messages={this.props.sliderMessages} isSuccess={this.props.success} isDisplay={true} />
          ) : null}

          <Row className="img-area">
            <Container>
              <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <div className="ugc-logo">
                    <img src={`${process.env.PUBLIC_URL}/images/ugcLogo.png`}></img>
                  </div>

                  <div>
                    <Switch>
                      <Route path={this.props.match.url + '/reset-password'} component={ResetpassForm} />
                      <Route path={this.props.match.url + '/forgot-password'} component={FogotpassForm} />
                      <Route path={this.props.match.url} component={LoginForm} />
                      <Route render={() => <h1>404</h1>} />
                    </Switch>
                  </div>
                </Col>
                <Col>
                  <h1 className="app-title">
                    University Registration <br />
                    Admin Portal
                  </h1>
                  <div className="branding">
                    <p>Powered by</p>
                    <img src={`${process.env.PUBLIC_URL}/images/mobitelLogo.png`}></img>
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </div>
    );
  }
}

LoginLayout.propTypes = {};

const mapsStateToPros = (state) => {
  return {
    sliderMessages: state.slider.messages,
    success: state.slider.isSuccess,
  };
};

export default connect(mapsStateToPros)(LoginLayout);
