import React from 'react';
import './index.scss';

import Modal from 'react-bootstrap/Modal';
import FileInput from '../../../../../components/FileInput';
import SubmitButton from '../../../../../components/SubmitButton';
import InlineFeedback from '../../../../../components/InlineFeedback';
import axios from '../../../../../axios/axios';
import urls from '../../../../../config/url.json';
import messages from '../../../../../config/messages.json';
import { updateObject } from '../../../../../utility/updateObject';
import { formatMessageArgs } from '../../../../../utility/utillity';
import { withRouter } from 'react-router-dom';

const BATCH = 'batch';

class UploadBatch extends React.Component {
  state = {
    batch: {
      canAttach: true,
      content: '',
      canSubmit: false,
      message: '',
      link: '',
    },
    upperPref: {
      canAttach: true,
      content: '',
      canSubmit: false,
      message: '',
      link: '',
    },
  };

  handleContentLoaded = (type, fileContent) => {
    const updatedFileData = {
      ...this.state[type],
      content: fileContent,
      canSubmit: true,
    };
    this.setState({ ...this.state, [type]: updatedFileData });
  };

  handleUpload = (type) => {
    const updatedFileData = {
      ...this.state[type],
      canSubmit: false,
      canAttach: false,
    };
    this.setState({ ...this.state, [type]: updatedFileData });
    console.log(urls);
    const batchNo = this.props.batchNo;
    console.log(batchNo)
    const uploadUrl = type === BATCH ? urls.batchfileUpload +batchNo: urls.upperPrefFileUpload;
    const formData = new FormData();
    formData.append('file', this.state[type].content);
    axios(this.props.history)
      .post(uploadUrl, formData)
      .then((resepose) => {
        console.log("resepose");
        console.log(resepose);
        const link = resepose.data.errorFileInfo ? resepose.data.errorFileInfo.url : '';
        const message = this.buildBackendMessage(resepose.data);
        const updatedFileData = updateObject(this.state[type], {
          link: link,
          message: message,
          canAttach: true
        });
        const updatedStates = updateObject(this.state, {
          [type]: updatedFileData,
        });
        this.setState(updatedStates);
      })
      .catch((err) => console.log(err));
  };

  // buildBackendMessage = (successCount) => {
  //   if (successCount === 0){
  //     return messages.BATCH_UPLOAD_FAILD;
  //   }
  //   if (successCount !== 0) {
  //     return messages.FILE_UPLOAD_ZERO_ERRORS;
  //   } else {
  //     const replacements = {
  //       '%COUNT%': successCount,
  //     };
  //     const message = formatMessageArgs(messages.FILE_UPLOAD_WITH_ERRORS, replacements);
  //     return message;
  //   }
  // };

  buildBackendMessage = (data) => {

    if (data.errorFileInfo) {
      if (data.successCount === 0) {
        return messages.BATCH_UPLOAD_FAILD;
      } else {
        const successcount = data.successCount
        const replace = {
          '%SUCCESSCOUNT%': successcount
        }
        const message = formatMessageArgs(messages.FILE_UPLOAD_WITH_ERRORS, replace);
        return message;
      }
      
    } else {
      const successcount = data.successCount
      const replace = {
        '%SUCCESSCOUNT%': successcount
      }
      const message = formatMessageArgs(messages.FILE_UPLOAD_ZERO_ERRORS, replace);
      return message;
    }
  };

  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.props.closed()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FileInput
              name='Upload Batch File here:'
              hint='Please select CSV File Format'
              hiddenFieldId='batchFile'
              visibleFieldId = 'batchFileName'
              canAttach={this.state.batch.canAttach}
              onContentChange={(content) => this.handleContentLoaded('batch', content)}
            />
            {this.state.batch.message !== '' ? (
              <InlineFeedback
                message={this.state.batch.message}
                isSuccess={true}
                isDisplay={true}
                link={this.state.batch.link}
              />
            ) : null}
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            variant='primary'
            text='UPLOAD'
            clicked={() => this.handleUpload(BATCH)}
            disabled={!this.state.batch.canSubmit}
            id='batchUploadButton'
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

UploadBatch.propTypes = {};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(UploadBatch);