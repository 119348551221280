import React from 'react';
import './index.scss';
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {Link} from "react-router-dom";


class UgcBreadcrumb extends React.Component {
    render(){
        return (
            <Breadcrumb>
            {this.props.bPaths && 
            this.props.bPaths.map((bp)=><Breadcrumb.Item key={bp.id}><Link to={bp.path}>{bp.name}</Link></Breadcrumb.Item>)}  
            </Breadcrumb>
        );
    }
};

UgcBreadcrumb.propTypes = {

};

export default UgcBreadcrumb;
