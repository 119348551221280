import axios from 'axios';
import env from '../config/enviroment.json';
import { convertResponseToErrorMessage } from '../utility/errorConvertor';
import messages from '../config/messages.json';
import store from '../store/store';
import * as actions from '../store/actions/index';

const getAxios = (history, fileUpload) => {
  //create the axios instane

  const instance = axios.create({
    baseURL: fileUpload ? env.fileUploadBaseUrl : env.baseUrl,
    headers: fileUpload ? {} : {
      'x-ibm-client-id': '1c9f9fb3-87a9-4fed-a445-498818ee8f88'
    }
  });

  instance.defaults.headers.common['Authorization'] = store.getState().auth.token;
  //define interceptors for the request

  instance.interceptors.request.use((req) => {
    console.log("request loader")
    store.dispatch(actions.loaderShow());
    return req;
  });
  //define interceptors fro the response
  instance.interceptors.response.use(
    (res) =>{
      console.log("response loader")
      store.dispatch(actions.loaderRemove());
      return res;
    },
    // } res,
    (error) => {
      if (!error.response || !error.response.status) {
        handleUnauthError(messages.CANNOT_CONNECT_TO_BACKEND);
        store.dispatch(actions.loaderRemove());
      } else {
        switch (error.response.status) {
          case 401:
            handleUnauthError(error, history);
            store.dispatch(actions.loaderRemove());
            break;

          case 409:
            handlerBackEndError(error, history);
            store.dispatch(actions.loaderRemove());
            break;
        }
      }
      return Promise.reject(error);
    }
  );
  return instance;
};

//method to handle back end error
const handlerBackEndError = (error, history) => {
  const message = convertResponseToErrorMessage(error);
  store.dispatch(actions.sliderMessageInit(message, false));
};

//method to handle unauth error
const handleUnauthError = (errorResponse, history) => {
  store.dispatch(actions.authClear());
  localStorage.clear();
  if (history.location.pathname === '/') {
    history.push('/tmp');
    history.goBack();
  } else {
    history.push('/');
  }
  let message = messages.NOT_AUTHORIZED;
  if (errorResponse.response.data) {
    message = convertResponseToErrorMessage(errorResponse);
  }
  store.dispatch(actions.sliderMessageInit(message, false));
};

export default getAxios;
