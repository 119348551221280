import React from 'react';
import './index.scss';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputField from '../../../components/InputField';
import SearchableDropdown from '../../../components/SearchableDropdown';
import SubmitButton from '../../../components/SubmitButton';
import Form from 'react-bootstrap/Form';
import StudentFormFeedback from '../../../components/studentFormFeedback';
import { checkValidity } from '../../../utility/validation';
import { checkFormValidity } from '../../../utility/formValidation';
import labels from './label.json';
import urls from '../../../config/url.json';
import axios from '../../../axios/axios';
import { withRouter } from 'react-router-dom';
import { convertResponseToErrorMessage } from '../../../utility/errorConvertor';
import PaidtoBank from '../paidtoBank';
import Button from 'react-bootstrap/Button';
import { formatMessageArgs } from '../../../utility/utillity';
import PromotionDeclaration from '../promotionDeclaration';

class StudentRegistration extends React.Component {
  state = {
    controls: {
      indexNo: {
        valid: false,
        value: '',
        touched: false,
        rules: { required: true, indexNo: true },
      },
      aLevelYear: {
        valid: false,
        value: '',
        touched: false,
        options: [],
        rules: { requiredForDropdown: true },
      },
      referenceNo: {
        valid: false,
        value: '',
        touched: false,
        rules: { required: true, referenceNo: true, minLength: 16, maxLength: 18 },
      },
      fullName: {
        valid: true,
        touched: true,
        value: '',
        disabled: 'disabled',
      },
      nameWithInitials: {
        valid: true,
        touched: true,
        value: '',
        disabled: 'disabled',
      },
      nic: {
        valid: true,
        touched: true,
        value: '',
        disabled: 'disabled',
      },
      applicationYear: {
        valid: true,
        touched: true,
        value: '',
        disabled: 'disabled',
      },
      uniCode: {
        valid: true,
        touched: true,
        value: '',
        disabled: 'disabled',
      },
      zscore: {
        valid: true,
        touched: true,
        value: '',
        disabled: 'disabled',
      },
      courseOfStudy: {
        valid: true,
        touched: true,
        value: '',
        disabled: 'disabled',
      },
      indexNumber: {
        valid: true,
        touched: true,
        value: '',
        disabled: 'disabled',
      },
      university: {
        valid: true,
        touched: true,
        value: '',
        disabled: 'disabled',
      },
      academicYear: {
        valid: true,
        touched: true,
        value: '',
        disabled: 'disabled',
      },
      referenceNumber: {
        valid: true,
        touched: true,
        value: '',
        disabled: 'disabled',
      },
      mobileNo: {
        valid: false,
        value: '',
        touched: false,
        rules: { required: true, isMobile: true },
      },
      fixedLineNo: {
        valid: true,
        value: null,
        touched: true,
        rules: { isMobile: true },
      },
      email: {
        valid: false,
        value: '',
        touched: false,
        rules: { required: true, isEmail: true },
      },
      registrationFee: {
        valid: true,
        touched: true,
        value: '',
        disabled: 'disabled',
      },
      registrationFeeMessage: {
        valid: true,
        touched: true,
        value: '',
      },
      receiptInfo: {
        valid: true,
        touched: true,
        value: {
          name: '',
          url: '',
        },
      },
    },
    showModal: {
      paidtoBank: false,
      promotionDeclaration: false,
    },
    formValid: false,
    searchStatus: false,
    searchErrorMessage: '',
    checkboxChecked: false,
    studentRegistered: false,
    disabledPromotionDeclaration: false,
    selected: [],
    upperPreferences: [],
    downloadlinkVisibility: 'NO',
    downloadurl: '',
  };

  showAcademicYear() {
    const thisYear = new Date().getFullYear();
    const pastYear = thisYear - 1;
    const academicYr = pastYear + '/' + thisYear;
    return academicYr;
  }

  handleChange = (event) => {
    this.setState({
      ...this.state,
      checkboxChecked: event.target.checked,
    });
  };

  inputChangeHandler = (property, val) => {
    if (property === 'aLevelYear') {
      val = val[0] ? val[0].id : '';
    } else {
      val = val.trim();
    }
    const propertyConfig = { ...this.state.controls[property] };
    const isValid = checkValidity(val, propertyConfig.rules);
    propertyConfig['valid'] = isValid;
    propertyConfig['value'] = val;
    propertyConfig['touched'] = true;

    const updatedControl = {
      ...this.state.controls,
      [property]: propertyConfig,
    };
    const formValid = checkFormValidity(updatedControl);

    const updatedState = {
      ...this.state,
      formValid: formValid,
      controls: updatedControl,
    };
    this.setState(updatedState);
  };

  searchIndex = (event) => {
    event.preventDefault();
    let indexNo = this.state.controls.indexNo.value;
    let aLevelYear = this.state.controls.aLevelYear.value;
    this.setState({
      ...this.state,
      searchErrorMessage: '',
      searchStatus: false,
    });
    axios(this.props.history)
      .get(urls.searchStudentIndex + indexNo + '/' + aLevelYear)
      .then((response) => {
        console.log(response.data.status);
        if (response.data.status) {
          this.setState({
            ...this.state,
            searchStatus: true,
          });
          let timer = setTimeout(() => {
            this.setState({
              ...this.state,
              searchStatus: false,
            });
          }, 900000);
        }
      })
      .catch((err) => {
        const message = convertResponseToErrorMessage(err);
        const updatedState = { ...this.state, searchErrorMessage: message };
        this.setState(updatedState);
      });
  };

  checkRegistrationEligibility = async (refData) => {
    let stRegistered = null;
    await axios(this.props.history)
      .post(urls.registerStatus, refData)
      .then((resepose) => {
        console.log(resepose.data);
        const updatedControls = { ...this.state.controls };
        for (let field in resepose.data) {
          if (updatedControls[field]) {
            updatedControls[field].value = resepose.data[field];
          }
        }
        stRegistered = resepose.data.studentRegistered;

        let disabledPromotion = false;

        if (
          'SP' === resepose.data.intakeCategory ||
          'AD' === resepose.data.intakeCategory ||
          'DQ' === resepose.data.intakeCategory ||
          'DI' === resepose.data.intakeCategory ||
          'SQ' === resepose.data.intakeCategory ||
          'BQ' === resepose.data.intakeCategory ||
          'AP' === resepose.data.intakeCategory ||
          'DS' === resepose.data.intakeCategory
        ) {
          disabledPromotion = true;
        }

        this.setState({
          ...this.state,
          controls: updatedControls,
          disabledPromotionDeclaration: disabledPromotion,
        });
      })
      .catch((err) => {
        const message = convertResponseToErrorMessage(err);
        const updatedState = { ...this.state, searchErrorMessage: message };
        this.setState(updatedState);
        console.log(message);
      });

    return await stRegistered;
  };

  searchReference = async (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      ...this.state,
      searchErrorMessage: '',
    });
    const indexNo = this.state.controls.indexNo.value;
    const referenceNo = this.state.controls.referenceNo.value;
    const refData = {
      indexNo: indexNo,
      referenceNumber: referenceNo,
    };

    let stRegistered = await this.checkRegistrationEligibility(refData);

    if (stRegistered === 'YES') {
      await axios(this.props.history)
        .get(urls.getOnlineReceipt + referenceNo, {
          responseType: 'blob',
        })
        .then((response) => {
          // const file = new Blob([response.data], {type: 'application/pdf' });
          const fileURL = URL.createObjectURL(response.data);
          console.log(fileURL);
          this.setState({
            ...this.state,
            downloadurl: fileURL,
            downloadlinkVisibility: stRegistered,
          });
        })
        .catch((err) => {});
    }
  };

  registerStudent = (event) => {
    event.preventDefault();
    const emailAddress = this.state.controls.email.value;
    const fixedLine = this.state.controls.fixedLineNo.value;
    const mobileNumber = this.state.controls.mobileNo.value;
    const refNo = this.state.controls.referenceNo.value;
    const upperPrefDataList = this.state.upperPreferences;
    const contactData = {
      emailAddress: emailAddress,
      fixedLine: fixedLine,
      mobileNumber: mobileNumber,
    };
    const studentData = {
      contactData: contactData,
      paymentMethod: 'PREV_REGISTERED',
      refNo: refNo,
      upperPrefDataList: upperPrefDataList,
    };
    axios(this.props.history)
      .post(urls.registerStudent, studentData)
      .then((reseponse) => {
        console.log(reseponse.data.success);
        if (reseponse.data.success) {
          this.props.history.push({
            pathname: '/registration-success',
            state: { detail: refNo },
          });
        }
        this.setState({
          ...this.state,
          formValid: false,
        });
      })
        .catch((err) => {
          const message = convertResponseToErrorMessage(err);
          const updatedState = { ...this.state, searchErrorMessage: message };
          this.setState(updatedState);
        });
  };

  handleCardShow = (modal, show) => {
    const updatedShowModal = { ...this.state.showModal, [modal]: show };
    const updatedState = { ...this.state, showModal: updatedShowModal };
    this.setState(updatedState);
  };

  updateEditHigherPreferences = (selected, upperPreferences) => {
    const updatedState = { ...this.state, selected: selected, upperPreferences: upperPreferences };
    this.setState(updatedState);
  };

  reload = () => {
    this.props.history.push('/registration-form');
    window.location.reload(true);
  };

  componentDidMount = async () => {
    axios(this.props.history)
      .get(urls.getYearList)
      .then((response) => {
        const options = [];
        const yearList = response.data.map((element) => {
          return { id: element.currentYear, label: element.currentYear };
        });
        Array.prototype.push.apply(options, yearList);
        const updatedState = { ...this.state };
        updatedState.controls.aLevelYear.options = options;
        this.setState(updatedState);
      });

    if (!('' === this.props.location.search)) {
      let search = this.props.location.search.split('=');
      let orderId = search[1];

      await axios(this.props.history)
        .get(urls.pgCancellation + orderId)
        .then((response) => {
          console.log(response.data);

          const updatedState = { ...this.state };
          updatedState.controls.indexNo.valid = true;
          updatedState.controls.indexNo.value = response.data.indexNo;
          updatedState.controls.indexNo.touched = true;

          updatedState.controls.aLevelYear.valid = true;
          updatedState.controls.aLevelYear.value = response.data.applicationYear;
          updatedState.controls.aLevelYear.touched = true;

          updatedState.controls.referenceNo.valid = true;
          updatedState.controls.referenceNo.value = response.data.refNo;
          updatedState.controls.referenceNo.touched = true;

          updatedState.controls.mobileNo.valid = true;
          updatedState.controls.mobileNo.value = response.data.contactData.mobileNumber;
          updatedState.controls.mobileNo.touched = true;

          updatedState.controls.fixedLineNo.value = response.data.contactData.fixedLine;

          updatedState.controls.email.valid = true;
          updatedState.controls.email.value = response.data.contactData.emailAddress;
          updatedState.controls.email.touched = true;

          let selected = response.data.upperPrefDataList.map((upperPref) => {
            return upperPref.id;
          });

          this.setState(updatedState);

          this.searchReference();

          this.setState({
            ...this.state,
            selected: selected,
            upperPreferences: response.data.upperPrefDataList,
            searchStatus: true,
            checkboxChecked: true,
            formValid: true,
          });
        })
        .catch((err) => {});
    }
  };

  getSessionData = async () => {
    let orderId, status, sessionId, merchant;
    const applicationYear = this.state.controls.aLevelYear.value;
    const indexNo = this.state.controls.indexNo.value;
    const emailAddress = this.state.controls.email.value;
    const fixedLine = this.state.controls.fixedLineNo.value;
    const mobileNumber = this.state.controls.mobileNo.value;
    const refNo = this.state.controls.referenceNo.value;
    const upperPrefDataList = this.state.upperPreferences;
    const contactData = {
      emailAddress: emailAddress,
      fixedLine: fixedLine,
      mobileNumber: mobileNumber,
    };
    const studentData = {
      applicationYear: applicationYear,
      contactData: contactData,
      indexNo: indexNo,
      paymentMethod: 'CREDIT_CARD',
      refNo: refNo,
      upperPrefDataList: upperPrefDataList,
    };
    await axios(this.props.history)
      .post(urls.payByCreditCard, studentData)
      .then((response) => {
        status = response.data.status;
        orderId = response.data.orderId;
        sessionId = response.data.sessionId;
        merchant = response.data.merchant;
      })
        .catch((err) => {
          const message = convertResponseToErrorMessage(err);
          const updatedState = { ...this.state, searchErrorMessage: message };
          this.setState(updatedState);
        });

    return await [status, orderId, sessionId, merchant];
  };

  payByCreditCard = async (event) => {
    event.preventDefault();

    const [status, orderId, sessionId, merchant] = await this.getSessionData();

    if (status === true) {
      window.Checkout.configure({
        merchant: merchant,
        order: {
          amount: '150',
          currency: 'LKR',
          description: 'Hosted Checkout Test Order - Return to Merchant - PHP/JavaScript/NVP',
          id: orderId,
          reference: orderId,
          item: {
            brand: 'Mastercard',
            description: 'Hosted Checkout Test Item - Return to Merchant - PHP/JavaScript/NVP',
            name: 'HostedCheckoutItem',
            quantity: '1',
            unitPrice: '1.00',
            unitTaxAmount: '1.00',
          },
        },
        customer: {
          email: this.state.controls.email.value,
        },
        interaction: {
          merchant: {
            name: 'UGC',
            address: {
              line1: '300 Adelaide Street',
              line2: 'Brisbane Queensland 4000',
            },
            logo: '',
          },
          displayControl: {
            billingAddress: 'HIDE',
            orderSummary: 'HIDE',
          },
        },
        session: {
          id: sessionId,
        },
      });
      window.Checkout.showPaymentPage();
    }
  };

  render() {
    let modal = null;
    if (this.state.showModal.paidtoBank) {
      modal = (
        <PaidtoBank
          closed={() => {
            this.handleCardShow('paidtoBank', false);
          }}
          refNo={this.state.controls.referenceNo.value}
          mobileNo={this.state.controls.mobileNo.value}
          fixedLine={this.state.controls.fixedLineNo.value}
          email={this.state.controls.email.value}
          upperPrefDataList={this.state.upperPreferences}
        ></PaidtoBank>
      );
    } else if (this.state.showModal.promotionDeclaration) {
      modal = (
        <PromotionDeclaration
          closed={() => {
            this.handleCardShow('promotionDeclaration', false);
          }}
          updateEditHigherPreferences={(selected, upperPreferences) => {
            this.updateEditHigherPreferences(selected, upperPreferences);
          }}
          selected={this.state.selected}
          indexNumber={this.state.controls.indexNumber.value}
          academicYear={this.state.controls.academicYear.value}
          uniCode={this.state.controls.uniCode.value}
        ></PromotionDeclaration>
      );
    }
    return (
      <div className="student-registration">
        <Navbar sticky="top">
          <Container>
            <Navbar.Brand href="#home">
              <img src={`${process.env.PUBLIC_URL}/images/ugcLogo.png`}></img>
            </Navbar.Brand>
          </Container>
        </Navbar>
        <Container>
          <Form>
            <p className="registration-title">
              REGISTRATION OF STUDENTS FOR COURSES OF STUDY OF UNIVERSITIES
            </p>
            <section className="registration-section">
              <p className="section-title">
                Please enter your Index No/ ඔබගේ විභාග අංකය ඇතුළත් කරන්න/ தயவு செய்து சுட்டிலக்கத்தைக் குறிப்பிடுக
              </p>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} className="col-search">
                  <div className="search-box">
                    <Row>
                      <Col xs={12} sm={12} md={5} lg={5} className="col-index-no">
                        <InputField
                          {...labels.form.indexNo}
                          onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7);
                          }}
                          onChange={(val) => this.inputChangeHandler('indexNo', val)}
                          invalid={!this.state.controls.indexNo.valid && this.state.controls.indexNo.touched}
                          value={this.state.controls.indexNo.value}
                        />
                      </Col>
                      <Col xs={12} sm={12} md={5} lg={5}>
                        <SearchableDropdown
                          Label="A/L Year/ උසස් පෙළ වර්ෂය/ க.பொ.த. (உ/த) ஆண்டு"
                          id="aLevelYear"
                          placeholder="Choose A/L Year"
                          onChange={(val) => this.inputChangeHandler('aLevelYear', val)}
                          options={this.state.controls.aLevelYear.options}
                          selected={[this.state.controls.aLevelYear.value]}
                        />
                      </Col>
                      <Col xs={12} sm={12} md={2} lg={2}>
                        <SubmitButton
                          variant="primary"
                          text="SEARCH"
                          disabled={
                            this.state.controls.indexNo.valid && this.state.controls.aLevelYear.valid
                              ? null
                              : 'disabled'
                          }
                          clicked={this.searchIndex}
                          id="indexNoSearchButton"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={7} lg={7}>
                  <div className="ref-search-box">
                    <Row className={this.state.searchStatus ? null : 'disabled-search'}>
                      <Col xs={9} sm={9} md={9} lg={9}>
                        <InputField
                          {...labels.form.referenceNo}
                          onChange={(val) => this.inputChangeHandler('referenceNo', val)}
                          invalid={!this.state.controls.referenceNo.valid && this.state.controls.referenceNo.touched}
                          disabled={this.state.searchStatus ? null : 'disabled'}
                          autocomplete="off"
                          value={this.state.controls.referenceNo.value}
                        />
                      </Col>
                      <Col xs={2} sm={2} md={2} lg={2}>
                        <SubmitButton
                          variant="primary"
                          text="SEARCH"
                          disabled={this.state.controls.referenceNo.valid ? null : 'disabled'}
                          clicked={this.searchReference}
                          id="ReferenceNoSearchButton"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              {this.state.searchErrorMessage ? <StudentFormFeedback message={this.state.searchErrorMessage} /> : null}
              {this.state.downloadlinkVisibility === 'YES' ? (
                <a className="onlinepayment-download" href={this.state.downloadurl} download="PaymentReceipt.pdf">
                  You are already registered. Click here to download Online Payment Receipt
                </a>
              ) : null}
            </section>

            <section className="registration-section">
              <p className="section-title">Personal details/ පෞද්ගලික තොරතුරු/ தனிப்பட்ட விபரங்கள்</p>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <InputField {...labels.form.fullName} {...this.state.controls.fullName} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <InputField {...labels.form.nameWithInitials} {...this.state.controls.nameWithInitials} />
                </Col>
                <Col xs={12} sm={12} md={7} lg={7}>
                  <InputField {...labels.form.nic} {...this.state.controls.nic} />
                </Col>
              </Row>
            </section>

            <section className="registration-section">
              <p className="section-title">G.C.E. (A/L) Examination/ අ.පො.ස (උසස් පෙළ) විභාගය/ க.பொ.த. (உ/த) பரீட்சை</p>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <InputField {...labels.form.applicationYear} {...this.state.controls.applicationYear} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <InputField {...labels.form.uniCode} {...this.state.controls.uniCode} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <InputField {...labels.form.zscore} {...this.state.controls.zscore} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <InputField {...labels.form.courseOfStudy} {...this.state.controls.courseOfStudy} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <InputField {...labels.form.indexNumber} {...this.state.controls.indexNumber} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <InputField {...labels.form.university} {...this.state.controls.university} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <InputField {...labels.form.academicYear} {...this.state.controls.academicYear} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <InputField {...labels.form.referenceNumber} {...this.state.controls.referenceNumber} />
                </Col>
              </Row>
            </section>

            <section className="registration-section">
              <p className="section-title">{labels.contactData.title}</p>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <InputField
                    {...labels.form.mobileNo}
                    {...this.state.controls.mobileNo}
                    invalid={!this.state.controls.mobileNo.valid && this.state.controls.mobileNo.touched}
                    onChange={(val) => this.inputChangeHandler('mobileNo', val)}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <InputField
                    {...labels.form.fixedLineNo}
                    {...this.state.controls.fixedLineNo}
                    invalid={!this.state.controls.fixedLineNo.valid && this.state.controls.fixedLineNo.touched}
                    onChange={(val) => this.inputChangeHandler('fixedLineNo', val)}
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <InputField
                    {...labels.form.email}
                    {...this.state.controls.email}
                    invalid={!this.state.controls.email.valid && this.state.controls.email.touched}
                    onChange={(val) => this.inputChangeHandler('email', val)}
                  />
                </Col>
              </Row>
              <p className="email-description">{labels.contactData.emailDescription}</p>
            </section>

            <section className="registration-section">
              <p className="section-title">{labels.registration.title}</p>
              <p>
                {formatMessageArgs(labels.registration.english, {
                  '%ACADEMICYEAR%': this.state.controls.academicYear.value,
                })}
                <br />
                <br />
                {formatMessageArgs(labels.registration.sinhala, {
                  '%ACADEMICYEAR%': this.state.controls.academicYear.value,
                })}
                <br />
                <br />
                {formatMessageArgs(labels.registration.tamil, {
                  '%ACADEMICYEAR%': this.state.controls.academicYear.value,
                })}
              </p>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={{ span: 5, offset: 7 }}
                  lg={{ span: 5, offset: 7 }}
                  className="promotion-declaration-button"
                >
                  <Button
                    variant="primary"
                    id="promotionDeclarationButton"
                    disabled={this.state.disabledPromotionDeclaration}
                    onClick={(e) => this.handleCardShow('promotionDeclaration', true)}
                  >
                    PROMOTION DECLARATION
                  </Button>
                </Col>
              </Row>
            </section>

            <section className="registration-section">
              <Form>
                <p className="section-title">{labels.studentDeclaration.title}</p>
                <p>
                  {formatMessageArgs(labels.studentDeclaration.english, {
                    '%ACADEMICYEAR%': this.state.controls.academicYear.value,
                    '%APPLICATIONYEAR%': this.state.controls.applicationYear.value,
                  })}
                  <br />
                  <br />
                  {formatMessageArgs(labels.studentDeclaration.sinhala, {
                    '%ACADEMICYEAR%': this.state.controls.academicYear.value,
                    '%APPLICATIONYEAR%': this.state.controls.applicationYear.value,
                  })}
                  <br />
                  <br />
                  {formatMessageArgs(labels.studentDeclaration.tamil, {
                    '%ACADEMICYEAR%': this.state.controls.academicYear.value,
                    '%APPLICATIONYEAR%': this.state.controls.applicationYear.value,
                  })}
                </p>
                <br/>
                <p className="section-title">{labels.studentAntiraggingDeclaration.title}</p>
                <p>
                  {labels.studentAntiraggingDeclaration.english}
                  <br/>
                  <br/>
                  {labels.studentAntiraggingDeclaration.sinhala}
                  <br/>
                  <br/>
                  {labels.studentAntiraggingDeclaration.tamil}
                </p>
                <Form.Group className="studentdeclaration-check">
                  <Form.Check
                    type="checkbox"
                    label={'I Agree/ එකඟ වෙමි/ உடன்படுகிறேன்'}
                    checked={this.state.checkboxChecked}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Form>
            </section>

            <section className="registration-section">
              <p className="section-title">Registration Fee/ ලියාපදිංචි ගාස්තුව/ தொகை</p>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <InputField {...this.state.controls.registrationFee} {...labels.registrationFee} />
                </Col>
              </Row>
              {this.state.controls.registrationFeeMessage.value ? (
                <StudentFormFeedback message={this.state.controls.registrationFeeMessage.value} />
              ) : null}
            </section>

            <section className="registration-section with-background">
              <p className="section-title">{labels.note.title}</p>
              <p>
                {labels.note.english}
                <br />
                <br />
                {labels.note.sinhala}
                <br />
                <br />
                {labels.note.tamil}
              </p>
              <p>
                {labels.antiraggingNote.english}
                <br />
                <br />
                {labels.antiraggingNote.sinhala}
                <br />
                <br />
                {labels.antiraggingNote.tamil}
              </p>
            </section>

            <section className="button-section">
              <Row>
                <Col xs={12} sm={12} md={{ span: 10, offset: 2 }} lg={{ span: 10, offset: 2 }} className="button-set">
                  <Button variant="outline-primary" id="CancelButton" onClick={(e) => this.reload()}>
                    CANCEL
                  </Button>
                  <Button
                    variant="primary"
                    id="PaybyCardButton"
                    disabled={
                      !this.state.formValid ||
                      !this.state.checkboxChecked ||
                      this.state.controls.registrationFee.value == 'Rs. 0' ||
                      this.state.studentRegistered == 'Yes' ||
                      this.state.searchErrorMessage != ''
                    }
                    onClick={this.payByCreditCard}
                  >
                    PAY BY CREDIT CARD
                  </Button>
                  <Button
                    variant="primary"
                    id="PaidtoBankButton"
                    disabled={
                      !this.state.formValid ||
                      !this.state.checkboxChecked ||
                      this.state.controls.registrationFee.value == 'Rs. 0' ||
                      this.state.studentRegistered == 'Yes' ||
                      this.state.searchErrorMessage != ''
                    }
                    onClick={(e) => this.handleCardShow('paidtoBank', true)}
                  >
                    PAID TO BANK
                  </Button>
                  <SubmitButton
                    variant="primary"
                    text="SUBMIT"
                    disabled={
                      !this.state.formValid ||
                      !this.state.checkboxChecked ||
                      this.state.controls.registrationFee.value == 'Rs. 50.00' ||
                      this.state.studentRegistered == 'Yes' ||
                      this.state.searchErrorMessage != ''
                    }
                    clicked={this.registerStudent}
                    id="SubmitButton"
                  />
                </Col>
              </Row>
            </section>
          </Form>
          {modal}
        </Container>
      </div>
    );
  }
}

export default withRouter(StudentRegistration);
