import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { capitalizeFirstLetter } from '../../../../../utility/utillity';
import ReactTooltip from "react-tooltip";


export const columns = (handleModalShow, downloadBatchRecords) => {
  return [
    {
      dataField: 'id',
      hidden: true,
    },
    {
      dataField: 'batchNumber',
      text: 'Batch Number',
    },
    {
      dataField: 'regStartDate',
      text: 'Registration Start Date & Time',
      formatter: (cellContent) => {
        return moment(cellContent).format('DD-MM-YYYY HH:mm:ss a');
      },
    },
    {
      dataField: 'regDeadlineDate',
      text: 'Registration Deadline',
      formatter: (cellContent) => {
        return moment(cellContent).format('DD-MM-YYYY HH:mm:ss a');
      },
    },
    {
      dataField: 'hasRecords',
      text: 'Batch has Records',
      formatter: (cellContent) => {
        return cellContent ? capitalizeFirstLetter(cellContent) : null;
      },
    },
    {
      dataField: 'batchActivated',
      text: 'Batch Activated',
      formatter: (cellContent) => {
        return cellContent ? capitalizeFirstLetter(cellContent) : null;
      },
    },
    {
      dataField: 'f',
      text: 'Actions',
      formatter: (cellContent, row) => {
        let viewClass = row.canView ? 'action-button' : 'action-button link-disabled';
        let downloadClass = row.canDownload ? 'action-button' : 'action-button link-disabled';
        let deleteClass = row.canDelete ? 'action-button' : 'action-button link-disabled';

        return (
          <span>
            <Link className={downloadClass}>
              <FontAwesomeIcon 
              icon="cloud-download-alt"
              data-tip="Download Records"
              onClick={() => downloadBatchRecords(row.batchNumber)}
              />
            </Link>
            <Link className={viewClass} to={'/home/view-batches-records?batchNumber=' + row.batchNumber}>
              <FontAwesomeIcon 
              icon="eye"
              data-tip="View Records"
              />
            </Link>
            <Link className="action-button">
              <FontAwesomeIcon 
              icon="cloud-upload-alt"
              data-tip="Upload Batch Records" 
              onClick={() => handleModalShow('batchFile', true, row.batchNumber)} 
              />
            </Link>
            <Link className="action-button">
              <FontAwesomeIcon
                icon="indent"
                data-tip="Insert a Record"
                onClick={() => handleModalShow('insertRecordBatch', true, row.batchNumber, row.id)}
              />
            </Link>
            <Link className="action-button">
              <FontAwesomeIcon 
              icon="pen" 
              data-tip="Edit Batch Properties" 
              onClick={() => handleModalShow('editBatch', true, row.batchNumber)} 
            />
            </Link>
            <Link className={deleteClass}>
              <FontAwesomeIcon 
              icon="trash-alt" 
              data-tip="Delete Batch"
              onClick={(e) => handleModalShow('deleteModal', true, row.batchNumber, row.id)}
            />
            </Link>
            <ReactTooltip place='bottom'/>
          </span>
        );
      },
    },
  ];
};

export const data = [];
