import React from 'react';
import './index.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import UgcBreadcrumb from '../../../../../components/UgcBreadcrumb';
import InputField from '../../../../../components/InputField';
import bPaths from '../../../../../config/breadcrumbPaths.json';
import labels from '../../../../../config/label.json';

import BootstrapTable from 'react-bootstrap-table-next';
import { paginationConfig } from '../../../../../utility/tableConfig';
import { columns, data } from './config';
import { checkFormValidity } from '../../../../../utility/formValidation';
import { checkValidity } from '../../../../../utility/validation';
import axios from '../../../../../axios/axios';
import urls from '../../../../../config/url.json';
import EditBatch from '../editBatch';
import InsertRecordBatch from '../../Batches/insertRecordBatch';
import UploadBatch from '../uploadBatch';
import Button from 'react-bootstrap/Button';
import DeleteModel from '../../../../commonDeleteModel';

import CardButton from '../../../../../components/CardButton';

class ViewBatches extends React.Component {
  state = {
    columns: columns(this.handleModalShow, this.downloadBatchRecords),
    data: [],
    totalData: data.length,
    controls: {
      indexNumber: {
        valid: false,
        value: '',
        touched: false,
        rules: { indexNo: true },
      },
      batchNumber: {
        valid: false,
        value: '',
        touched: false,
        rules: {},
      },
      academicYear: {
        valid: false,
        value: '',
        touched: false,
        rules: { required: true, academicYear: true },
      },
    },
    canSearch: false,
    batchNumber: '',
    id: '',
    showModal: {
      editBatch: false,
      insertRecordBatch: false,
      batchFile: false,
      deleteModal: false,
    },
  };

  componentDidMount() {
    this.setState({ ...this.state, columns: columns(this.handleModalShow, this.downloadBatchRecords) });
    this.loadData(0);
  }

  inputChangeHandler = (property, val) => {
    val = val.trim();
    const propertyConfig = { ...this.state.controls[property] };
    const isValid = checkValidity(val, propertyConfig.rules);
    propertyConfig['valid'] = isValid;
    propertyConfig['value'] = val;
    propertyConfig['touched'] = true;

    const updatedControl = {
      ...this.state.controls,
      [property]: propertyConfig,
    };
    const formValid = checkFormValidity(updatedControl);

    const canSearch = updatedControl['academicYear'].valid && updatedControl['indexNumber'].valid;
    const updatedState = {
      ...this.state,
      formValid: formValid,
      controls: updatedControl,
      canSearch: canSearch,
    };
    console.log('can search ', updatedControl);
    this.setState(updatedState);
  };

  handleIndexNumberKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.loadData(0);
    }
  };

  loadData = (page) => {
    const params = {
      page: page,
      batchNumber: this.state.controls.batchNumber.value,
      indexNumber: this.state.controls.indexNumber.value,
      academicYear: this.state.controls.academicYear.value,
    };
    axios(this.props.history)
      .get(urls.getBatchData, { params: params })
      .then((response) => {
        console.log('hello');
        console.log(response);
        const updatedState = { ...this.state, data: response.data.data, totalData: response.data.totalCount };
        this.setState(updatedState);
      });
  };

  handleTableChange = (type, { page, sizePerPage }) => {
    if (type === 'pagination') {
      this.loadData(page - 1);
    }
  };

  handleModalShow = (modal, show, batchNo, id) => {
    console.log(modal, show, batchNo);
    const updatedShowModal = { ...this.state.showModal, [modal]: show };
    const updatedState = { ...this.state, showModal: updatedShowModal, batchNumber: batchNo, id: id };
    this.setState(updatedState);
  };

  downloadBatchRecords = (batchNo) => {
    const batchNumber = batchNo;
    axios(this.props.history)
      .get(urls.downloadBatchfileRecords + batchNumber)
      .then((response) => {
        const downloadMfile = response.data.downloadFile.url;
        let a = document.createElement('a');
        a.href = downloadMfile;
        a.click();
      });
  };

  searchReports = (event, page) => {
    event.preventDefault();

    this.loadData(0);

    console.log(this.state.controls.indexNumber.value);
  };

  render() {
    let modal = null;
    if (this.state.showModal.editBatch) {
      modal = (
        <EditBatch
          batchNo={this.state.batchNumber}
          closed={() => {
            this.handleModalShow('editBatch', false, null);
          }}
        />
      );
    } else if (this.state.showModal.insertRecordBatch) {
      modal = (
        <InsertRecordBatch
          closed={() => {
            this.handleModalShow('insertRecordBatch', false, null);
          }}
          id={this.state.batchNumber}
        />
      );
    } else if (this.state.showModal.batchFile) {
      modal = (
        <UploadBatch
          batchNo={this.state.batchNumber}
          closed={() => {
            this.handleModalShow('batchFile', false, null);
          }}
        />
      );
    } else if (this.state.showModal.deleteModal) {
      modal = (
        <DeleteModel
          closed={() => {
            this.handleModalShow('deleteModal', false, null);
          }}
          url={urls.deleteRecordFromBatch + this.state.id}
        />
      );
    } else {
      modal = null;
    }
    return (
      <div className="view-batches">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <UgcBreadcrumb bPaths={bPaths.viewBatches} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="findreport-table-search">
              <Row>
              <Col xs={12} sm={12} md={3} lg={3}>
                  <InputField
                    name="Index Number:"
                    type="number"
                    autocomplete="off"
                    onChange={(val) => this.inputChangeHandler('indexNumber', val)}
                    feedback={labels.viewAddEditBatch.indexNo.feedback}
                    invalid={!this.state.controls.indexNumber.valid && this.state.controls.indexNumber.touched}
                    id="indexNumberInputField"
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3}>
                  <InputField
                    name="Academic Year:"
                    type="text"
                    autocomplete="off"
                    onChange={(val) => this.inputChangeHandler('academicYear', val)}
                    feedback={labels.viewAddEditBatch.academicYear.feedback}
                    invalid={!this.state.controls.academicYear.valid && this.state.controls.academicYear.touched}
                    id="academicYearInputField"
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} className="search-section-button" style={{marginBottom:"1rem"}}>
                  <CardButton
                    btnName="SEARCH"
                    btnCat="icon-btn no-margin"
                    iconName="search"
                    onClick={this.searchReports}
                    disabled={!this.state.controls.indexNumber.valid || !this.state.controls.academicYear.valid}
                    id="reportSearchButton"
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3}>
                  <InputField
                    name="Search by Batch Number"
                    type="number"
                    onChange={(val) => this.inputChangeHandler('batchNumber', val)}
                    invalid={!this.state.controls.batchNumber.valid && this.state.controls.batchNumber.touched}
                    onKeyDown={this.handleIndexNumberKeyDown}
                    id={'batchNumberInputField'}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="view-batches-table">
            <BootstrapTable
              onTableChange={this.handleTableChange}
              remote
              keyField="batchNumber"
              data={this.state.data}
              columns={this.state.columns}
              pagination={paginationConfig(this.state.totalData)}
            ></BootstrapTable>
          </Row>
          {modal}
        </Container>
      </div>
    );
  }
}

export default ViewBatches;
