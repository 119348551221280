import React from 'react';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import axios from '../../axios/axios';
import CardButton from '../CardButton';
import messages from '../../config/messages.json';
import { withRouter } from 'react-router-dom';

class ChangePassInit extends React.Component {
  handlePasswordChange = () => {
    axios(this.props.history)
      .post('/users/password/change/init')
      .then((response) => {
        if (response.status) {
          // this.props.history.push('/');
          this.props.onPassowrdChangeInitSucces();
        }
      });
  };
  render() {
    return (
      <CardButton {...this.props}
        btnName='CHANGE PASSWORD'
        btnCat='icon-btn float-right'
        iconName='unlock-alt'
        onClick={this.handlePasswordChange}
        id='changePasswordButton'
      ></CardButton>
    );
  }
}

ChangePassInit.propTypes = {};

const mapsDispatchToProps = (dispatch) => {
  return {
    onPassowrdChangeInitSucces: () => dispatch(actions.sliderMessageInit(messages.PASSWORD_RESET_LINK_SENT, true)),
    onPassowrdChangeInitFail: () => dispatch(actions.sliderMessageInit('', false)),
  };
};

export default connect(null, mapsDispatchToProps)(withRouter(ChangePassInit));
