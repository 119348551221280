import React from 'react';
import './index.scss';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';

import InputField from '../../../../../components/InputField';
import DropdownInput from '../../../../../components/DropdownInput';
import SubmitButton from '../../../../../components/SubmitButton';
import { checkValidity } from '../../../../../utility/validation';
import { checkFormValidity } from '../../../../../utility/formValidation';
import labels from '../../../../../config/label.json';
import urls from '../../../../../config/url.json';
import dropdownValues from './dropdownValues.json';
import axios from '../../../../../axios/axios';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';

import UgcBreadcrumb from '../../../../../components/UgcBreadcrumb';
import bPaths from '../../../../../config/breadcrumbPaths.json';

class InsertRecord extends React.Component {
  state = {
    controls: {
      indexNo: {
        valid: false,
        value: '',
        touched: false,
        rules: { required: true, indexNo: true },
      },
      nameInitials: {
        valid: false,
        value: '',
        touched: false,
        rules: { required: true, nameWithInitials: true },
      },
      fullName: {
        valid: false,
        value: '',
        touched: false,
        rules: { required: true, fullName: true },
      },
      NIC: {
        valid: true,
        value: null,
        touched: true,
        rules: { nic: true, required: false },
      },
      zScore: {
        valid: false,
        value: '',
        touched: false,
        rules: { required: true, zScore: true },
      },
      applicationYear: {
        valid: true,
        value: '2015',
        touched: true,
        rules: { required: true },
        options: [],
      },
      academicYear: {
        valid: true,
        value: '2015/2016',
        touched: true,
        // rules: { required: true, academicYear: true },
      },
      status: {
        valid: true,
        value: 'Pending',
        touched: true,
        rules: { required: true },
        options: [],
      },
    },
    formValid: false,
  };

  applicationyearChangeHandler = (property, val) => {

    const appYear = parseInt(val);
    const acdYear = appYear + "/" + (appYear + 1);

    const academicYear = acdYear.toString()

    val = val.trim();
    const propertyConfig = { ...this.state.controls[property] };
    const isValid = checkValidity(val, propertyConfig.rules);
    propertyConfig['valid'] = isValid;
    propertyConfig['value'] = val;
    propertyConfig['touched'] = true;


    const propertyConfig1 = { ...this.state.controls["academicYear"] };
    propertyConfig1["valid"] = true;
    propertyConfig1["value"] = academicYear;
    propertyConfig1["touched"] = true;

    const updatedControl = {
      ...this.state.controls,
      [property]: propertyConfig,
      ["academicYear"]: propertyConfig1,
    };
    const formValid = checkFormValidity(updatedControl);

    const updatedState = {
      ...this.state,
      formValid: formValid,
      controls: updatedControl,
    };
    this.setState(updatedState);

  }

  inputChangeHandler = (property, val) => {

    // let propertyConfig1 = "";
    // if (property === "academicYear") {
    //   //test
    //   propertyConfig1 = { ...this.state.controls["academicYear"] };
    //   propertyConfig1["valid"] = false;
    //   propertyConfig1["value"] = "";
    //   propertyConfig1["touched"] = true;
    //   propertyConfig1["rules"] = { required: true, academicYear: true };

    //   const appYear = parseInt(this.state.controls.applicationYear.value)
    //   const acdYear = appYear + "/" + (appYear + 1);

    //   if (val == acdYear) {
    //     propertyConfig1["valid"] = true;
    //     propertyConfig1["value"] = val;
    //   }
    //   //end test
    // }

    // let acdYear = "";
    // if(property === "applicationYear"){
    //   const appYear = parseInt(val);
    //   const acdYear = appYear + "/" + (appYear + 1);

    //   console.log(acdYear)

    //   let a = this.state.controls.academicYear.value;
    //   this.setState({
    //     a :acdYear
    //   })
    // }
    
    val = val.trim();
    
    if (property === 'NIC') {
       if(val.length == 10){
          val = val.substring(0, 9) + val.substr(9).toUpperCase();
       }
    }

    const propertyConfig = { ...this.state.controls[property] };
    const isValid = checkValidity(val, propertyConfig.rules);
    propertyConfig['valid'] = isValid;
    propertyConfig['value'] = val;
    propertyConfig['touched'] = true;

    const updatedControl = {
      ...this.state.controls,
      [property]: propertyConfig,
    };
    const formValid = checkFormValidity(updatedControl);

    const updatedState = {
      ...this.state,
      formValid: formValid,
      controls: updatedControl,
    };
    this.setState(updatedState);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const indexNo = this.state.controls.indexNo.value;
    const nameInitials = this.state.controls.nameInitials.value;
    const fullName = this.state.controls.fullName.value;
    const NIC = this.state.controls.NIC.value;
    const zScore = this.state.controls.zScore.value;
    const applicationYear = this.state.controls.applicationYear.value;
    const academicYear = this.state.controls.academicYear.value;
    const status = this.state.controls.status.value;
    const mfileRecordData = {
      academicYear: academicYear,
      applicationYear: applicationYear,
      fullName: fullName,
      indexNumber: indexNo,
      nameWithInitials: nameInitials,
      status: status,
      nic: NIC,
      zscore: zScore,
    };
    axios(this.props.history)
      .post(urls.insertRecordtoMfile, mfileRecordData)
      .then((respose) => {
        const success = respose.data.success;
        const msg = respose.data.message;
        if (!success) {
          this.props.onRecordInsert(msg, false);
        } else {
          this.props.onRecordInsert(msg, true);
          this.props.history.goBack();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div className="Mfilerecord-input">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <UgcBreadcrumb bPaths={bPaths.insertRecordMFile} />
            </Col>
          </Row>
          <Form className="Mfilerecord-inputform" onSubmit={this.handleSubmit}>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4}>
                <InputField
                  name="Index Number"
                  type="number"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7);
                  }}
                  onChange={(val) => this.inputChangeHandler('indexNo', val)}
                  feedback={labels.InsertMfileRecord.indexNo.feedback}
                  invalid={!this.state.controls.indexNo.valid && this.state.controls.indexNo.touched}
                  id={'indexNoInputField'}
                />
              </Col>
              <Col xs={12} sm={8} md={8} lg={8}>
                <InputField
                  name="Name with Initials"
                  type="text"
                  onChange={(val) => this.inputChangeHandler('nameInitials', val)}
                  feedback={labels.InsertMfileRecord.nameInitials.feedback}
                  invalid={!this.state.controls.nameInitials.valid && this.state.controls.nameInitials.touched}
                  id={'nameInitialsInputField'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={8} md={8} lg={8}>
                <InputField
                  name="Full Name"
                  type="text"
                  onChange={(val) => this.inputChangeHandler('fullName', val)}
                  feedback={labels.InsertMfileRecord.fullName.feedback}
                  invalid={!this.state.controls.fullName.valid && this.state.controls.fullName.touched}
                  id={'fullNameInputField'}
                />
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <InputField
                  name="NIC Number"
                  type="text"
                  onChange={(val) => this.inputChangeHandler('NIC', val)}
                  feedback={labels.InsertMfileRecord.NIC.feedback}
                  invalid={!this.state.controls.NIC.valid && this.state.controls.NIC.touched}
                  id={'nicInputField'}
                  value={this.state.controls.NIC.value}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4}>
                <InputField
                  name="Z-Score"
                  type="float"
                  onChange={(val) => this.inputChangeHandler('zScore', val)}
                  feedback={labels.InsertMfileRecord.zScore.feedback}
                  invalid={!this.state.controls.zScore.valid && this.state.controls.zScore.touched}
                  id={'zScoreInputField'}
                />
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <DropdownInput
                  controlId="applicationYear"
                  name="Application Year"
                  onChange={(val) => this.applicationyearChangeHandler('applicationYear', val)}
                  feedback={labels.InsertMfileRecord.applicationYear.feedback}
                  options={dropdownValues.InsertMfileRecord.applicationYear.options}
                />
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <InputField
                  name="Academic Year"
                  // onChange={(val) => this.inputChangeHandler('academicYear', val)}
                  value={this.state.controls.academicYear.value}
                  feedback={labels.InsertMfileRecord.academicYear.feedback}
                  invalid={!this.state.controls.academicYear.valid && this.state.controls.academicYear.touched}
                  disabled = {true}
                  id={'academicYearInputField'}
                />
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <DropdownInput
                  controlId="status"
                  name="Status"
                  onChange={(val) => this.inputChangeHandler('status', val)}
                  feedback={labels.InsertMfileRecord.status.feedback}
                  options={dropdownValues.InsertMfileRecord.status.options}
                />
              </Col>
            </Row>
            <Row className="button-grp justify-content-end">
              <Col xs={12} sm={4} md={4} lg={4}>
                <Button variant="outline-primary" onClick={() => this.props.history.push('/home')} id={'cancelButton'}>
                  CANCEL
                </Button>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <SubmitButton
                  variant="primary"
                  text="INSERT"
                  disabled={!this.state.formValid}
                  id='insertButton'
                ></SubmitButton>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}

const mapDispathToProps = (dispatch) => {
  return {
    onRecordInsert: (msg, success) => dispatch(actions.sliderMessageInit(msg, success)),
  };
};

export default connect(null, mapDispathToProps)(InsertRecord);
