import React from 'react';
import './index.scss';
// import Navbar from 'react-bootstrap/Navbar';
import {Container, Row, Col, Navbar} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import urls from '../../../config/url.json';
import axios from '../../../axios/axios';

class RegistrationSuccess extends React.Component {
    state ={
        urlForOnlineReceipt:'',
        downloadButtonDisabilityForOnlineReceipt: true,
        urlForAntiRaggingDeclaration:'',
        downloadButtonDisabilityForAntiRaggingDeclaration: true
    }
    componentDidMount = async () =>  {

        let referenceNumber;
        if (this.props.location.state) {
            referenceNumber = this.props.location.state.detail;
        } else {
            let url = this.props.location.search.split("=");
            referenceNumber = url[1];
        }
    
        await axios(this.props.history)
          .get(urls.getOnlineReceipt + referenceNumber ,{
            responseType: 'blob'
          })
          .then((response) => {
            console.log(response.data);
            
            // const file = new Blob([response.data], {type: 'application/pdf' });
            const fileURL = URL.createObjectURL(response.data);
            console.log(fileURL)
            this.setState({
                    ...this.state,
                    urlForOnlineReceipt:fileURL,
                    downloadButtonDisabilityForOnlineReceipt: false
                })
          })
          .catch((err) => {
          });

        await axios(this.props.history)
            .get(urls.getAntiRaggingDeclaration + referenceNumber ,{
                responseType: 'blob'
            })
            .then((response) => {

                const fileURL = URL.createObjectURL(response.data);
                console.log(fileURL)
                this.setState({
                    ...this.state,
                    urlForAntiRaggingDeclaration:fileURL,
                    downloadButtonDisabilityForAntiRaggingDeclaration: false
                })
            })
            .catch((err) => {
            });
    }
    downloadPaymentReceipt() {
        const downloadMfile = this.state.urlForOnlineReceipt;
        let a = document.createElement('a');
        a.href = downloadMfile;
        a.download = 'PaymentReceipt.pdf'
        a.click();
    }
    downloadAntiRaggingDeclaration() {
        const downloadMfile = this.state.urlForAntiRaggingDeclaration;
        let a = document.createElement('a');
        a.href = downloadMfile;
        a.download = 'Anti Ragging Declaration.pdf'
        a.click();
    }
    render(){
        return (
            <div className='registration-success-wrapper'>
                <Navbar sticky="top">
                    <Container>
                        <Navbar.Brand href="#home">
                            <img src={`${process.env.PUBLIC_URL}/images/ugcLogo.png`}></img>
                        </Navbar.Brand>
                    </Container>
                </Navbar>
                <Container>
                    <img className='success-img' src={`${process.env.PUBLIC_URL}/images/success.png`}></img>
                    <h2 className='success-title'>Registration Successful.</h2>
                </Container> 
                <Container className='download-area'>
                    <Row>
                        <Col>
                            <p className='success-para'>Please click here to download the Online Payment Receipt</p>
                            <Button
                                variant="primary"
                                id="paymentReceiptButton"
                                className='pdf-download-btn'
                                onClick={() => this.downloadPaymentReceipt()}
                                disabled={this.state.downloadButtonDisabilityForOnlineReceipt}
                            > DOWNLOAD
                            </Button>
                        </Col>
                        <Col >
                            <p className='success-para'>Please click here to download the Anti Ragging Declaration</p>
                            <Button
                                variant="primary"
                                id="antiRaggingButton"
                                className='pdf-download-btn'
                                onClick={() => this.downloadAntiRaggingDeclaration()}
                                disabled={this.state.downloadButtonDisabilityForAntiRaggingDeclaration}
                            > DOWNLOAD
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
};

export default RegistrationSuccess;
