import React from 'react';
import './index.scss';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';

import InputField from '../../../../../components/InputField';
import DropdownInput from '../../../../../components/DropdownInput';
import SubmitButton from '../../../../../components/SubmitButton';
import { checkValidity } from '../../../../../utility/validation';
import { checkFormValidity } from '../../../../../utility/formValidation';
import labels from '../../../../../config/label.json';
import urls from '../../../../../config/url.json';
import dropdownValues from '../InsertRecord/dropdownValues.json';
import axios from '../../../../../axios/axios';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import { updateControlValues } from '../../../../../utility/formValidation';

import UgcBreadcrumb from '../../../../../components/UgcBreadcrumb';
import bPaths from '../../../../../config/breadcrumbPaths.json';

class MFileRecordEditView extends React.Component {
  state = {
    controls: {
      indexNumber: {
        valid: true,
        value: '',
        touched: false,
        rules: { required: true, indexNo: true },
        disable: true,
      },
      nameWithInitials: {
        valid: true,
        value: '',
        touched: false,
        rules: { required: true, nameWithInitials: true },
      },
      fullName: {
        valid: true,
        value: '',
        touched: false,
        rules: { required: true, fullName: true },
      },
      nic: {
        valid: true,
        value: null,
        touched: false,
        rules: { nic: true },
      },
      zscore: {
        valid: true,
        value: '',
        touched: false,
        rules: { required: true, zScore: true },
      },
      applicationYear: {
        valid: true,
        value: '',
        touched: false,
        rules: { required: true },
        options: [],
        disable:false
      },
      academicYear: {
        valid: true,
        value: '',
        touched: false,
        rules: { required: true, academicYear: true },
        disable: true,
      },
      status: {
        valid: true,
        value: '',
        touched: false,
        rules: { required: true },
        options: [],
      },
    },
    formValid: true,
    id: '',
  };


  applicationyearChangeHandler = (property, val) => {
    
    const appYear = parseInt(val);
    const acdYear = appYear + "/" + (appYear + 1);

    const academicYear = acdYear.toString()

    val = val.trim();
    const propertyConfig = { ...this.state.controls[property] };
    const isValid = checkValidity(val, propertyConfig.rules);
    propertyConfig['valid'] = isValid;
    propertyConfig['value'] = val;
    propertyConfig['touched'] = true;


    const propertyConfig1 = { ...this.state.controls["academicYear"] };
    propertyConfig1["valid"] = true;
    propertyConfig1["value"] = academicYear;
    propertyConfig1["touched"] = true;

    const updatedControl = {
      ...this.state.controls,
      [property]: propertyConfig,
      ["academicYear"]: propertyConfig1,
    };
    const formValid = checkFormValidity(updatedControl);

    const updatedState = {
      ...this.state,
      formValid: formValid,
      controls: updatedControl,
    };
    this.setState(updatedState);
  }



  inputChangeHandler = (property, val) => {
    val = val.trim();
    if (property === 'nic') {
      if(val.length == 10){
         val = val.substring(0, 9) + val.substr(9).toUpperCase();
      }
    }
    const propertyConfig = { ...this.state.controls[property] };
    const isValid = checkValidity(val, propertyConfig.rules);
    propertyConfig['valid'] = isValid;
    propertyConfig['value'] = val;
    propertyConfig['touched'] = true;

    //  //test
    //  const propertyConfig1 = { ...this.state.controls["academicYear"] };
    //  propertyConfig1["valid"] = false;
    //  propertyConfig1["value"] = val;
    //  propertyConfig1["touched"] = true;
    //  propertyConfig1["rules"] = { required: true, academicYear: true };
 
    //  const appYear = parseInt(this.state.controls.applicationYear.value)
    //  const acdYear = appYear +"/"+ (appYear+1);
 
    //  if( val == acdYear){
    //    propertyConfig1["valid"] = true;
    //    propertyConfig1["value"] = val;
    //  }
 
    //  //end test

    const updatedControl = {
      ...this.state.controls,
      [property]: propertyConfig,
      // ["academicYear"]: propertyConfig1,
    };
    const formValid = checkFormValidity(updatedControl);

    const updatedState = {
      ...this.state,
      formValid: formValid,
      controls: updatedControl,
    };
    this.setState(updatedState);
  };

  componentDidMount() {
    const recordId = this.props.match.params.id;
    axios(this.props.history)
      .get(urls.getMfileRecord + recordId)
      .then((response) => {
        const updatedControls = updateControlValues(this.state.controls, response.data);
        if (response.data.canDelete) {
          updatedControls.indexNumber.disable = false;
          updatedControls.academicYear.disable = false;
        }
        if (response.data.hasBatchRecord){
          updatedControls.applicationYear.disable = true;
        }
        if (response.data.hasUpperPrefRecord){
          updatedControls.indexNumber.disable = true;
          updatedControls.applicationYear.disable = true;
        }
        const updatedState = { ...this.state, controls: updatedControls, id: recordId };
        this.setState(updatedState);
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      indexNumber,
      nameWithInitials,
      fullName,
      nic,
      zscore,
      applicationYear,
      academicYear,
      status,
    } = this.state.controls;
    const mfileRecordData = {
      academicYear: academicYear.value,
      applicationYear: applicationYear.value,
      fullName: fullName.value,
      indexNumber: indexNumber.value,
      nameWithInitials: nameWithInitials.value,
      status: status.value,
      nic: nic.value,
      zscore: zscore.value,
    };

    axios(this.props.history)
      .put(urls.updateMfileRecord + this.state.id, mfileRecordData)
      .then((respose) => {
        const success = respose.data.success;
        const msg = respose.data.message;
        if (!success) {
          this.props.onRecordInsert(msg, false);
        } else {
          this.props.onRecordInsert(msg, true);
          this.props.history.goBack();
        }
      })
      .catch((err) => {});
  };

  render() {
    return (
      <div className="Mfilerecord-input">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
            <UgcBreadcrumb bPaths={bPaths.editMFileRecord} />
            </Col>
          </Row>
          <Form className="Mfilerecord-inputform" onSubmit={this.handleSubmit}>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4}>
                <InputField
                  name="Index Number"
                  type="number"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7);
                  }}
                  onChange={(val) => this.inputChangeHandler('indexNumber', val)}
                  feedback={labels.InsertMfileRecord.indexNumber.feedback}
                  invalid={!this.state.controls.indexNumber.valid && this.state.controls.indexNumber.touched}
                  value={this.state.controls.indexNumber.value}
                  disabled={this.state.controls.indexNumber.disable}
                  id={'indexNumberInputField'}
                />
              </Col>
              <Col xs={12} sm={8} md={8} lg={8}>
                <InputField
                  name="Name with Initials"
                  type="text"
                  onChange={(val) => this.inputChangeHandler('nameWithInitials', val)}
                  feedback={labels.InsertMfileRecord.nameWithInitials.feedback}
                  invalid={!this.state.controls.nameWithInitials.valid && this.state.controls.nameWithInitials.touched}
                  value={this.state.controls.nameWithInitials.value}
                  id={'nameWithInitialsInputField'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={8} md={8} lg={8}>
                <InputField
                  name="Full Name"
                  type="text"
                  onChange={(val) => this.inputChangeHandler('fullName', val)}
                  feedback={labels.InsertMfileRecord.fullName.feedback}
                  invalid={!this.state.controls.fullName.valid && this.state.controls.fullName.touched}
                  value={this.state.controls.fullName.value}
                  id={'fullNameInputField'}
                />
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <InputField
                  name="NIC Number"
                  type="text"
                  onChange={(val) => this.inputChangeHandler('nic', val)}
                  feedback={labels.InsertMfileRecord.nic.feedback}
                  invalid={!this.state.controls.nic.valid && this.state.controls.nic.touched}
                  value={this.state.controls.nic.value}
                  id={'nicInputField'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4}>
                <InputField
                  name="Z-Score"
                  type="float"
                  onChange={(val) => this.inputChangeHandler('zscore', val)}
                  feedback={labels.InsertMfileRecord.zscore.feedback}
                  invalid={!this.state.controls.zscore.valid && this.state.controls.zscore.touched}
                  value={this.state.controls.zscore.value}
                  id={'zScoreInputField'}
                />
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <DropdownInput
                  controlId="applicationYear"
                  name="Application Year"
                  onChange={(val) => this.applicationyearChangeHandler('applicationYear', val)}
                  feedback={labels.InsertMfileRecord.applicationYear.feedback}
                  options={dropdownValues.InsertMfileRecord.applicationYear.options}
                  value={this.state.controls.applicationYear.value}
                  disabled ={this.state.controls.applicationYear.disable}
                />
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <InputField
                  name="Academic Year"
                  onChange={(val) => this.inputChangeHandler('academicYear', val)}
                  feedback={labels.InsertMfileRecord.academicYear.feedback}
                  invalid={!this.state.controls.academicYear.valid && this.state.controls.academicYear.touched}
                  value={this.state.controls.academicYear.value}
                  id={'academicYearInputField'}
                  disabled ={true}
                  // disabled={this.state.controls.academicYear.disable}
                />
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <DropdownInput
                  controlId="status"
                  name="Status"
                  onChange={(val) => this.inputChangeHandler('status', val)}
                  feedback={labels.InsertMfileRecord.status.feedback}
                  options={dropdownValues.InsertMfileRecord.status.options}
                  value={this.state.controls.status.value}
                />
              </Col>
            </Row>
            <Row className="button-grp justify-content-end">
              <Col xs={12} sm={4} md={4} lg={4}>
                <Button variant="outline-primary" onClick={() => this.props.history.goBack()} id={'cancelButton'}>
                  CANCEL
                </Button>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <SubmitButton
                  variant="primary"
                  text="SAVE"
                  disabled={!this.state.formValid}
                  id={'saveButton'}
                ></SubmitButton>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}

const mapDispathToProps = (dispatch) => {
  return {
    onRecordInsert: (msg, success) => dispatch(actions.sliderMessageInit(msg, success)),
  };
};

export default connect(null, mapDispathToProps)(MFileRecordEditView);
