import errors from "../config/messages.json";

export const convertResponseToErrorMessage = (errorResponse) => {
  const erroCodes = errorResponse.response.data.map((e) => e.errorCode);
  const message = erroCodes.map((code) => errors[code]).join(" ");
  if (!message) {
    return errors.BACKEND_COMMON_ERROR;
  }
  return message;
};

export const convertCodeToMessage = (erroCode) => {
  const message = errors[erroCode];
  return message;
};
