import React from 'react';
import './index.scss';
import Form from 'react-bootstrap/Form';
import SubmitButton from '../../../components/SubmitButton';
import InputField from '../../../components/InputField';
import { checkValidity } from '../../../utility/validation';
import { checkFormValidity } from '../../../utility/formValidation';
import labels from '../../../config/label.json';
import axios from '../../../axios/axios';
import * as actions from '../../../store/actions/index';
import messages from '../../../config/messages.json';
import { connect } from 'react-redux';
import urls from '../../../config/url.json';

class FogotpassForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controls: {
        emailaddress: {
          valid: false,
          value: '',
          touched: false,
          rules: { required: true, isEmail: true },
        },
      },
      formValid: false,

      emailaddress: '',
      buttonDisabled: false,
    };
  }

  inputChangeHandler = (property, val) => {
    val = val.trim();
    const propertyConfig = { ...this.state.controls[property] };
    const isValid = checkValidity(val, propertyConfig.rules);
    propertyConfig['valid'] = isValid;
    propertyConfig['value'] = val;
    propertyConfig['touched'] = true;

    const updatedControl = {
      ...this.state.controls,
      [property]: propertyConfig,
    };
    const formValid = checkFormValidity(updatedControl);

    const updatedState = {
      ...this.state,
      formValid: formValid,
      controls: updatedControl,
    };
    this.setState(updatedState);
  };

  setInputValue(property, val) {
    val = val.trim();
    this.setState({
      [property]: val,
    });
  }
  resetForm() {
    this.setState({
      emailaddress: '',
      buttonDisabled: false,
    });
  }
  sendMail() {
    const emailaddress = this.state.controls.emailaddress.value;
    const authData = {
      emailAddress: emailaddress,
    };

    axios(this.props.history)
      .post(urls.forgotPassword, authData)
      .then((response) => {
        if (response.data.status) {
          this.props.history.push('/');
          this.props.onPassowrdChangeInitSucces();
        }
      });
  }

  render() {
    return (
      <Form className="forgotpass-form">
        <h3>Forgot Password</h3>
        <p className="forgotpass-des">
          Enter the E-mail Address Associated with Your Account. We'll E-mail You a Link to Reset Your Password.
        </p>
        <InputField
          name={'E-mail Address'}
          type={'email'}
          hint={labels.forgotPassword.emailaddress.hint}
          onChange={(val) => this.inputChangeHandler('emailaddress', val)}
          feedback={labels.forgotPassword.emailaddress.feedback}
          invalid={!this.state.controls.emailaddress.valid && this.state.controls.emailaddress.touched}
          id={'emailInputField'}
        />
        <div className="fogotpass-btn-grp">
          <SubmitButton
            variant={'primary'}
            text={'SEND'}
            disabled={!this.state.formValid}
            clicked={() => this.sendMail()}
            id={'sendButton'}
          />
          <SubmitButton
            variant={'outline-primary'}
            text={'BACK'}
            disabled={this.state.buttonDisabled}
            clicked={() => this.props.history.push('/')}
            id={'backButton'}
          />
        </div>
      </Form>
    );
  }
}

const mapsDispatchToProps = (dispatch) => {
  return {
    onPassowrdChangeInitSucces: () => dispatch(actions.sliderMessageInit(messages.PASSWORD_RESET_LINK_SENT, true)),
    onPassowrdChangeInitFail: () => dispatch(actions.sliderMessageInit('', false)),
  };
};

export default connect(null, mapsDispatchToProps)(FogotpassForm);
