import React from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactTimeout from 'react-timeout';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import ReactCSSTransitionGroup from 'react-transition-group';

import Overlay from '../Overlay';

class SlidingMessage extends React.Component {
  state = {
    isSuccess: '',
    isDisplay: true,
  };

  componentDidMount() {
    let timer = null;
    timer = setTimeout(() => {
      this.props.onSliderClose('');
      this.setState({ isDisplay: false, isSuccess: false });
    }, 10000);
  }

  componentWillUnmount() {
    let timer = null;
    clearTimeout(timer);
  }

  closeMessage = () => {
    this.setState({ ...this.state, isDisplay: false });
    this.props.onSliderClose('');
  };

  render() {
    console.log(this.props.isSuccess);
    const classes = ['sliding-message'];
    if (this.state.isSuccess) {
      classes.push('success');
    } else {
      classes.push('error');
    }
    if (!this.state.isDisplay) {
      classes.push('close-slider');
    }

    return (
      <div>
        {this.state.isDisplay ? <Overlay /> : null}
        <div className={classes.join(' ')}>
          <FontAwesomeIcon icon={faTimes} onClick={() => this.closeMessage()} />
          <p>{this.props.messages}</p>
        </div>
      </div>
    );
  }
}

SlidingMessage.propTypes = {};

const mapDispatchToProps = (dispatch) => {
  return {
    onSliderClose: (messages) => dispatch(actions.sliderMessageRemove(messages)),
  };
};

export default connect(null, mapDispatchToProps)(SlidingMessage);
