import React from 'react';
import './index.scss';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import UgcBreadcrumb from '../../../../../components/UgcBreadcrumb';
import bPaths from '../../../../../config/breadcrumbPaths.json';
import BootstrapTable from 'react-bootstrap-table-next';
import { paginationConfig } from '../../../../../utility/tableConfig';
import { columns } from './config';
import axios from '../../../../../axios/axios';
import urls from '../../../../../config/url.json';
import InputField from '../../../../../components/InputField';
import CardButton from '../../../../../components/CardButton';
import SearchableDropdown from '../../../../../components/SearchableDropdown';
import dropdownValues from './DropdownValues.json';
import { checkValidity } from '../../../../../utility/validation';
// import { checkFormValidity } from '../../../../../utility/formValidation';
// import Button from 'react-bootstrap/Button';
import DropdownInput from '../../../../../components/DropdownInput';
import labels from '../../../../../config/label.json';
import DateInput from '../../../../../components/DateInput';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FindReports extends React.Component {
    state = {
        columns: columns(),
        data: [],
        totalData: 0,
        controls: {
            searchType:{
                valid: false,
                value: [],
                touched: false,
            },
            indexNumber: {
                valid: false,
                value: '',
                touched: false,
                rules: { required: true, indexNo: true },
            },
            referenceNumber: {
                valid: false,
                value: '',
                touched: false,
                rules: { required: true, referenceNo: true, minLength: 16, maxLength: 18 },
            },
            nic: {
                valid: false,
                value: '',
                touched: false,
                rules: { nic: true, required: false },
            },
            applicationYear: {
                valid: false,
                value: '2015',
                touched: false,
                options: [],
            },
            name: {
                valid: false,
                value: '',
                touched: false,
                rules: { required: true, fullName: true },
            },
            courseOfStudyCode:{
                valid: false,
                value: '',
                touched: false,
                options:[]
            },
            universityCode:{
                valid: false,
                value: '',
                touched: false,
                options:[]
            },
            batchNumber: {
                valid: false,
                value: '',
                touched: false,
                rules: { required: true},
            },
            regDeadLine: {
                valid: false,
                value: new Date(),
                touched: false,
                rules: { dateRequired: true },
            },
            regDate: {
                valid: false,
                value: new Date(),
                touched: false,
                rules: { dateRequired: true },
            },
            status: {
                valid: false,
                value: 'Selected',
                touched: false,
                options: [],
            }
        },
        downloadFile: {
            name: '',
            url: '',
        }
    };

    componentDidMount() {
        axios(this.props.history)
            .get(urls.getCourses)
            .then((response) => {
                const courcesList = response.data.map((element) => {
                    return { id: element.code, label: element.name };
                });
                const updatedCourse = { 
                    ...this.state.controls.courseOfStudyCode, 
                    options: courcesList
                };
                const updateControls = {
                    ...this.state.controls,
                    courseOfStudyCode: updatedCourse
                }
                const updatedState ={
                    ...this.state,
                    controls: updateControls
                }
                this.setState(updatedState);
            });
            axios(this.props.history)
            .get(urls.getUniversities)
            .then((response) => {
                const universityList = response.data.map((element) => {
                    return { id: element.code, label: element.name };
                });
                const updatedUniversity = { 
                    ...this.state.controls.universityCode, 
                    options: universityList
                };
                const updateControls = {
                    ...this.state.controls,
                    universityCode: updatedUniversity
                }
                const updatedState ={
                    ...this.state,
                    controls: updateControls
                }
                this.setState(updatedState);
            });

        this.loadData(0);
    }

    loadData = (page) => {
        const selctT =this.state.controls.searchType.value;
        let params = {
            page: page
        }
        if (selctT && selctT.length > 0){
            const type = selctT[0].id;
            let value = this.state.controls[type].value;
            if (type === 'regDeadLine') {
                const cc = this.state.controls.regDeadLine.value;
                value = moment(cc).format("YYYY-MM-DD");
            }
            params = {
                [type] :value,
                page: page
            }

        }



        axios(this.props.history)
            .get(urls.getReportData, {
                params:params,
            })
            .then((response) => {
                const updatedState = { ...this.state, data: response.data.data, totalData: response.data.totalCount };
                this.setState(updatedState);
            });
    };

    handleTableChange = (type, { page }) => {
        if (type === 'pagination') {
            this.loadData(page - 1);
        }
    };

    inputChangeHandler = (property, val) => {
        if (property === 'searchType' || property === 'applicationYear' || property === 'status' || property === 'regDeadLine' || property === 'regDate') {
            if (property === 'status' && val === 'Not Registered') {
                val = 'Not_Registered';
            } else {
                val = val;
            }
        } else if (property === 'universityCode' || property === 'courseOfStudyCode') {
            const codeList = val;
            const code = codeList[0] ? codeList[0].id : null;
            val = code;
        } else {
            val = val.trim();
        }
        const propertyConfig = { ...this.state.controls[property] };
        const isValid = checkValidity(val, propertyConfig.rules);
        propertyConfig['valid'] = isValid;
        propertyConfig['value'] = val;
        propertyConfig['touched'] = true;

        const updatedControl = {
            ...this.state.controls,
            [property]: propertyConfig,
        };

        const updatedState = {
            ...this.state,
            controls: updatedControl,
        };

        this.setState(updatedState);
    };

      selectedSearchField = (id) =>{
          if(id ==="indexNumber"){
              return (
                  <InputField
                      name='Index Number:'
                      type='number'
                      autocomplete='off'
                      onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7);
                      }}
                      onChange={(val) => this.inputChangeHandler('indexNumber', val)}
                      feedback={labels.FindReports.indexNo.feedback}
                      invalid={!this.state.controls.indexNumber.valid && this.state.controls.indexNumber.touched}
                      id='indexNumberInputField'
                  />
              );

          }if(id ==="referenceNumber"){
              return (
                  <InputField
                      name='Reference Number:'
                      autocomplete='off'
                      onChange={(val) => this.inputChangeHandler('referenceNumber', val)}
                      feedback={labels.FindReports.referenceNo.feedback}
                      invalid={!this.state.controls.referenceNumber.valid && this.state.controls.referenceNumber.touched}
                      id='referenceNumberInputField'
                  />
              );

          }if(id === "nic"){
              return (
                  <InputField
                      name='NIC:'
                      autocomplete='off'
                      onChange={(val) => this.inputChangeHandler('nic', val)}
                      feedback={labels.FindReports.NIC.feedback}
                      invalid={!this.state.controls.nic.valid && this.state.controls.nic.touched}
                      id='nicInputField'
                  />
              );

          }if(id === "applicationYear"){
              return (
                  <DropdownInput
                      controlId="applicationYear"
                      name="Application Year"
                      onChange={(val) => this.inputChangeHandler('applicationYear', val)}
                      options={dropdownValues.ApplicationYear.options}
                  />
              );

          }if(id === "name"){
              return (
                  <InputField
                      name='Name'
                      autocomplete='off'
                      onChange={(val) => this.inputChangeHandler('name', val)}
                      invalid={!this.state.controls.name.valid && this.state.controls.name.touched}
                      id='nameInputField'
                  />
              );

          }if(id === "courseOfStudyCode"){
              return (
                  <SearchableDropdown
                      Label="Course of study"
                      id='courseofstudyInputField'
                      placeholder="Choose course of study"
                      onChange={(val) => this.inputChangeHandler('courseOfStudyCode', val)}
                      options={this.state.controls.courseOfStudyCode.options}
                  />
              );
              
          }if(id === "universityCode"){
              return (
                  <SearchableDropdown
                      Label="University"
                      id='universityInputField'
                      placeholder="Choose university"
                      onChange={(val) => this.inputChangeHandler('universityCode', val)}
                      options={this.state.controls.universityCode.options}
                  />
              );

          }if(id === "batchNumber"){
              return (
                  <InputField
                      name='Batch Number'
                      type='number'
                      autocomplete='off'
                      onChange={(val) => this.inputChangeHandler('batchNumber', val)}
                      id='batchNumberInputField'
                  />
              );

          }if(id === "regDeadLine"){
              return (
                  <DateInput
                      controlId="registrationDeadline"
                      value={this.state.controls.regDeadLine.value}
                      onChange={(val) => this.inputChangeHandler("regDeadLine", val)}
                      invalid={!this.state.controls.regDeadLine.valid && this.state.controls.regDeadLine.touched}
                      feedback={labels.FindReports.NIC.feedback}
                  >Registration Deadline</DateInput>
              );

          }if(id === "regDate"){
              return (
                  <DateInput
                      controlId="registrationDate"
                      value={this.state.controls.regDate.value}
                      onChange={(val) => this.inputChangeHandler("regDate", val)}
                      // invalid={!this.state.controls.regDate.valid && this.state.controls.regDate.touched}
                  >Registration Date</DateInput>
              );

          }
          if(id === "status"){
              return (
                  <DropdownInput
                      controlId="status"
                      name="Status"
                      onChange={(val) => this.inputChangeHandler('status', val)}
                      options={dropdownValues.Status.options}
                  />
              );
          } 
      }

    showDataTabe = (dataCounnt) =>{
        if (dataCounnt !== 0){
            return (
                <BootstrapTable
                    onTableChange={this.handleTableChange}
                    remote
                    keyField="id"
                    data={this.state.data}
                    columns={this.state.columns}
                    pagination={paginationConfig(this.state.totalData)}
                />
            )
        }else{
            return(
                <p className='empty-records-message'>No records under the filtered criteria</p>
            )
        }
        
    }

    searchReports = (event,page= 0) => {
        event.preventDefault(); 
        const selctT =this.state.controls.searchType.value;

        let params = {
            page: page
        }
        if (selctT && selctT.length > 0){
            const type = selctT[0].id;
            let value = this.state.controls[type].value;
            if (type === 'regDeadLine') {
                const cc = this.state.controls.regDeadLine.value;
                value = moment(cc).format("YYYY-MM-DD");
            }
            else if (type === 'regDate') {
                const cc = this.state.controls.regDate.value;
                value = moment(cc).format("YYYY-MM-DD");
            }
            params = {
                [type] :value,
                page: page
            }

        }
        console.log(params);

        axios(this.props.history)
            .get(urls.getReportData, { 
                params: params   
            })
            .then((resepose) => {
                const updatedState = { 
                    ...this.state, 
                    data: resepose.data.data, 
                    totalData: resepose.data.totalCount 
                };
                this.setState(updatedState);
                this.getDownloadableReportFile();
            })

            .catch((err) => {
            });
    };

    getDownloadableReportFile = () => {
        const selctT =this.state.controls.searchType.value;
        const type = selctT[0].id;
        let value = this.state.controls[type].value;
        if (type === 'regDeadLine') {
            const cc = this.state.controls.regDeadLine.value;
            value = moment(cc).format("YYYY-MM-DD");  
        }
        else if (type === 'regDate') {
            const cc = this.state.controls.regDate.value;
            value = moment(cc).format("YYYY-MM-DD");
        }
        const params = {
            [type] :value,
            page: '0'
        }
        console.log(params);

        axios(this.props.history)
            .get(urls.getDownloadReportFile, { 
                params: params   
            })
            .then((response) => {

                console.log(response);

                if (response.data.success) {
                    const updatedState = { 
                        ...this.state, 
                        downloadFile: response.data.downloadFile
                    };
                    this.setState(updatedState);
                }
            })

            .catch((err) => {
        });
    }

    render() {
        const arrayofSelectedType = this.state.controls.searchType.value;
        const id = arrayofSelectedType[0] ? arrayofSelectedType[0].id : null;
        const inputFieldType = id ? this.selectedSearchField(id):null

        const dataCount = this.state.totalData;
        const tableData = this.showDataTabe(dataCount);

        return (
            <div className='find-reports'>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <UgcBreadcrumb bPaths={bPaths.findReport} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className="findreport-table-search">
                            <Row>
                                <Col xs={12} sm={12} md={4} lg={4}>
                                    <SearchableDropdown
                                        Label="Search Type:"
                                        id='searchTypeInputField'
                                        placeholder="Choose search type"
                                        onChange={(val) => this.inputChangeHandler('searchType', val)}
                                        options={dropdownValues.SearchTypes.options}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={5} lg={5}>
                                    {inputFieldType}
                                </Col>
                                <Col xs={12} sm={12} md={3} lg={3} className='search-section-button'>
                                    <CardButton
                                        btnName='SEARCH'
                                        btnCat='icon-btn no-margin float-right'
                                        iconName='search'
                                        onClick={this.searchReports}
                                        id='reportSearchButton'
                                    />
                                </Col>
                            </Row>
                            {this.state.downloadFile.url ? (
                                <a className="download-report-file" href={this.state.downloadFile.url} >
                                    <FontAwesomeIcon icon="cloud-download-alt" className="fa-lg"/>
                                    Click here to download the Report
                                </a>
                            ) : null}
                        </Col>
                    </Row>
                    <Row className="view-reports-table">
                        {tableData}
                    </Row>
                </Container>
            </div>
        );
    }
};

export default FindReports;
