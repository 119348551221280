export const checkValidity = (value, rules, config) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  //rules for insert m file record

  if (rules.indexNo) {
    const pattern = /^\d{7}[0-9]{0,7}$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.nameWithInitials) {
    const pattern = /^[a-zA-Z. ]{0,100}$/;
    isValid = pattern.test(value) && isValid;
  }
  if (rules.fullName) {
    const pattern = /^[a-zA-Z ]{0,200}$/;
    isValid = pattern.test(value) && isValid;
  }
  if (rules.academicYear) {
    const pattern = /^\d{4}[/]\d{4}$/;
    let v = value.split("/");
    let sub = v[1] - v[0];
    isValid = pattern.test(value) && isValid && (sub === 1);
  }
  if (rules.zScore) {
    const pattern = /^[-+]?(\d{1}[.]\d{4})$/;
    isValid = pattern.test(value) && isValid;
  }
  if (rules.nic) {
    const oldNic = /^\d{9}[VX]$/;
    const newNic = /^\d{12}$/;
    const validNic = oldNic.test(value) || newNic.test(value) || value=='';
    isValid = validNic && isValid;
  }

  //common rules
  if(rules.slipNumber){
    const pattern = /^[a-zA-Z0-9-_]{0,20}$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.dateRequired) {
    isValid = value !== null && isValid;
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
  }
  if (rules.SearchableDropdownrequired) {
    isValid = value.length !== 0 && isValid;
  }
  if (rules.Dropdownrequired) {
    isValid = value.trim() !== "please select" && isValid;
  }
  if (rules.requiredForDropdown) {
    isValid = value.trim() !== "SELECT VALUE" && isValid;
  }

  if (rules.password) {
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d.*)(?=.*(\W|_).*)[a-zA-Z0-9\S]{8,15}$/;
    isValid = pattern.test(value) && isValid;
  }
  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    isValid = pattern.test(value) && isValid;
  }

  if (rules.isMobile) {
    const pattern = /[0]\d{9,9}$/;
    console.log(isValid);
    isValid = pattern.test(value) && isValid;
    console.log(pattern.test(value), isValid);
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }
  if (rules.sameas) {
    for (let property of rules.sameas) {
      const propertyConfig = config[property];
      isValid = isValid && propertyConfig.value === value;
    }
  }
  if (rules.requredIf) {
    for (let property of rules.requredIf) {
      const propertyConfig = config[property];
      isValid = isValid && propertyConfig.value;
    }
  }

  if (rules.referenceNo) {
    const pattern = /[0-9]{2}(NI|SP|AD|DQ|DI|SQ|BQ|AP|DS|V[0-9]{2}|V[1-9]{1})[0-9]{3}[A-Z]{1}[0-9]{8}$/;
    isValid = pattern.test(value) && isValid;
  }
  
  return isValid;
};
