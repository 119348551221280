export const checkFormValidity = (config) => {
  let formValid = true;
  for (let property in config) {
    const valid = config[property].valid;
    formValid = formValid && valid;
  }
  return formValid;
};

export const checkInputInvalid = (config, field) => {
  const filedConfig = config[field];
  const invalid = !filedConfig.valid && filedConfig.touched;
  //console.log(invalid);
  return invalid;
};

export const updateControlValues = (controls, data) => {
  const updatedControls = { ...controls };
  for (let field in controls) {
    if (data[field]) {
      updatedControls[field].value = data[field];
    }
  }
  return updatedControls;
};
