import React from 'react';
import './index.scss';

import CardButton from '../../../components/CardButton';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import expImg from './pwExpiry.png';
import ChangePassInit from '../../../components/ChangePassInit';
import Header from '../../../components/Header';
import SlidingMessage from '../../../components/SlidingMessage';
import Loader from '../../../components/Loader';
import { connect } from 'react-redux';

class PasswordExpirypage extends React.Component {
  render() {
    return (
      <div className={'Password-Expirypage'}>
        {this.props.sliderMessages ? (
          <SlidingMessage messages={this.props.sliderMessages} isSuccess={this.props.success} isDisplay={true} />
        ) : null}
        {this.props.showLoader ? <Loader/> : null}
        <Header />
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="exp-msg">
              <img src={expImg} />
              <h3>Password Expired</h3>
              <p>Your password has expired. Please change your password</p>
            </Col>
          </Row>
          <Row className="pw-chng-area">
            <Col xs={12} sm={12} md={12} lg={12} className="exp-change-btn">
              <ChangePassInit />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapsStateToPros = (state) => {
  return {
    sliderMessages: state.slider.messages,
    showLoader: state.loader.showLoader
  };
};

export default connect(mapsStateToPros)(PasswordExpirypage);
