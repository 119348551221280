import React, { Component } from 'react';
import './index.scss';

import { Link, useLocation } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import BootstrapTable from 'react-bootstrap-table-next';

import { paginationConfig } from '../../../../../utility/tableConfig';
import axios from '../../../../../axios/axios';
import urls from '../../../../../config/url.json';
import { columns, data } from './config';
import UgcBreadcrumb from '../../../../../components/UgcBreadcrumb';
import bPaths from '../../../../../config/breadcrumbPaths.json';

class MFileRecordView extends Component {
  state = {
    columns: columns,
    data: [],
    totalData: data.length,
    indexNumber: '',
  };

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const index = params.get('indexNumber');
    this.setState({ ...this.state, indexNumber: index });
    this.loadData(0, index);
  }

  loadData = (page, index) => {
    console.log(this.state.indexNumber);
    const indexNo = index ? index : this.state.indexNumber;
    axios(this.props.history)
      .get(urls.getBatchFileData, { params: { year: '', page: page, indexNumber: indexNo } })
      .then((response) => {
        const updatedState = { ...this.state, data: response.data.data, totalData: response.data.totalCount };
        this.setState(updatedState);
      });
  };

  handleTableChange = (type, { page, sizePerPage }) => {
    if (type === 'pagination') {
      this.loadData(page - 1);
    }
  };

  render() {
    return (
      <div className="mfile-record-view">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <UgcBreadcrumb bPaths={bPaths.batchMFileRecord} />
            </Col>
          </Row>
          <Row>
            <BootstrapTable
              onTableChange={this.handleTableChange}
              remote
              keyField="id"
              data={this.state.data}
              columns={this.state.columns}
              pagination={paginationConfig(this.state.totalData)}
            />
          </Row>
        </Container>
      </div>
    );
  }
}

export default MFileRecordView;
