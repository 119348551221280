import React from 'react';

import Modal from 'react-bootstrap/Modal';
import SubmitButton from '../../../components/SubmitButton';
import axios from '../../../axios/axios';
import { withRouter } from 'react-router-dom';
import './index.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BootstrapTable from 'react-bootstrap-table-next';
import { paginationConfig } from '../../../utility/tableConfig';
import { columns } from './config';
import urls from '../../../config/url.json';


class PromotionDeclaration extends React.Component {

    state = {
        columns: columns(),
        data: [],
        totalData: 0,
        show: false,
        id: null,
        removed: [],
        selected: this.props.selected,
    };

    componentDidMount() {
        this.setState({
          ...this.state,
          columns: columns(this.removeAllHandler, this.removeHander),
        });
        this.loadData();
    }

    handleShow = () => {
        this.setState({ show: true });
    };
    
    handleClose = () => {
        this.props.closed();
        this.setState({ show: false });
    };

    loadData = () => {
        const indexNumber = this.props.indexNumber;
        const academicYear = this.props.academicYear;
        const uniCode = this.props.uniCode;
        axios(this.props.history)
          .get(urls.getStudentUpperPreferenceData, {
            params: { academicYear: academicYear, indexNumber: indexNumber, uniCode: uniCode },
          })
          .then((response) => {
            const updatedState = { ...this.state, data: response.data};
            this.setState(updatedState);
          });
    };

    handleOnSelect = (row, isSelect) => {
      if (isSelect) {
        this.setState(() => ({
          selected: [...this.state.selected, row.id]
        }));
      } else {
        this.setState(() => ({
          selected: this.state.selected.filter(x => x !== row.id)
        }));
      }
    }
  
    handleOnSelectAll = (isSelect, rows) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        this.setState(() => ({
          selected: ids
        }));
      } else {
        this.setState(() => ({
          selected: []
        }));
      }
    }

    editHigherPreferences = () => {
      const upperPreferences = [];
      this.state.selected.forEach((value) => {
        const upperPref = { id: value, removed: true };
        upperPreferences.push(upperPref);
      });
     this.props.updateEditHigherPreferences(this.state.selected, upperPreferences);
    };

    render() {

        const selectRow = {
          mode: 'checkbox',
          clickToSelect: true,
          selectColumnPosition: 'right',
          style: { color: '#A4523F', textDecoration: 'line-through' },
          selected: this.state.selected,
          onSelect: this.handleOnSelect,
          onSelectAll: this.handleOnSelectAll
        };

        return (
            <Modal
                show={true}
                onHide={() => this.handleClose()}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="promotion-declaration-main-model"
            >

                <Modal.Header closeButton>
                    <Modal.Title>Promotion Declaration</Modal.Title>
                </Modal.Header>

                <Modal.Body className="promotion-declaration-model" >
                    <p className="section-title">Student’s Higher Preferences/ ශිෂ්‍යයාගේ ඉහළ මනාපයන්/மாணவர்களின் உயர் விருப்பத்தேர்வுகள்</p>

                    <div className="edit-higherpreferences-table">
                      <BootstrapTable
                        onTableChange={this.handleTableChange}
                        remote
                        keyField="id"
                        data={this.state.data}
                        columns={this.state.columns}
                        selectRow={selectRow}
                      />
                    </div>

                    <Col xs={12} sm={12} md={12} lg={12} className="bottom-buttons">
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4}>
                            </Col>
                            <Col xs={12} sm={12} md={2} lg={2}>
                                <SubmitButton
                                    variant={'outline-primary'}
                                    text="BACK"
                                    clicked={this.handleClose}
                                    disabled={false}
                                    id="backButton"
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <SubmitButton
                                    variant={'primary'}
                                    text="REMOVE SELECTED HIGHER PREFERENCES"
                                    clicked={this.editHigherPreferences}
                                    disabled={false}
                                    id="romoveButton"
                                />
                            </Col>
                        </Row>
                    </Col>

                </Modal.Body>

            </Modal>
        );
    }

}

export default withRouter(PromotionDeclaration);