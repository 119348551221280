import authReducer from "./reducers/authReducer";
import sliderReducer from "./reducers/sliderReducer";
import loaderReducer from "./reducers/loaderReducer";
import thunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  slider: sliderReducer,
  loader: loaderReducer
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
