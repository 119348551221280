import React, { Component } from 'react';
import './index.scss';

import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import BootstrapTable from 'react-bootstrap-table-next';
import { paginationConfig } from '../../../../../utility/tableConfig';
import InputField from '../../../../../components/InputField';
import labels from '../../../../../config/label.json';
import { checkFormValidity } from '../../../../../utility/formValidation';
import axios from '../../../../../axios/axios';
import { checkValidity } from '../../../../../utility/validation';
import urls from '../../../../../config/url.json';
import DeleteModel from '../../../../commonDeleteModel';
import UgcBreadcrumb from '../../../../../components/UgcBreadcrumb';
import bPaths from '../../../../../config/breadcrumbPaths.json';

import { columns, data } from './config';

// import * as actions from '../../../../../store/actions/index';
// import messages from '../../../../../config/messages.json';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';

class MFileView extends Component {
  state = {
    columns: columns(this.handleModalShow),
    data: [],
    totalData: 0,
    controls: {
      indexNumber: {
        valid: false,
        value: '',
        touched: false,
        rules: {indexNo: true },
      },
      batchNumber: {
        valid: false,
        value: '',
        touched: false,
        rules: {},
      },
      academicYear: {
        valid: false,
        value: '',
        touched: false,
        rules: {},
      },
    },
    showModal: {
      deleteModal: false,
    },
    id: null,
  };

  componentDidMount() {
    this.setState({ ...this.state, columns: columns(this.handleModalShow) });
    this.loadData(0);
  }

  handleModalShow = (modal, show, id) => {
    console.log(modal, show);
    const updatedShowModal = { ...this.state.showModal, [modal]: show };
    const updatedState = { ...this.state, showModal: updatedShowModal, id: id };
    this.setState(updatedState);
  };

  inputChangeHandler = (property, val) => {
    val = val.trim();
    const propertyConfig = { ...this.state.controls[property] };
    const isValid = checkValidity(val, propertyConfig.rules);
    propertyConfig['valid'] = isValid;
    propertyConfig['value'] = val;
    propertyConfig['touched'] = true;

    const updatedControl = {
      ...this.state.controls,
      [property]: propertyConfig,
    };
    const formValid = checkFormValidity(updatedControl);

    const updatedState = {
      ...this.state,
      formValid: formValid,
      controls: updatedControl,
    };
    this.setState(updatedState);
  };

  handleIndexNumberKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.loadData(0);
    }
  };

  loadData = (page) => {
    axios(this.props.history)
      .get(urls.getMfileData, { params: { year: '', page: page, indexNumber: this.state.controls.indexNumber.value } })
      .then((response) => {
        // if(response.data.totalCount==0){
        //   this.props.onPassowrdChangeInitSucces();
        // }
        const updatedState = { ...this.state, data: response.data.data, totalData: response.data.totalCount };
        this.setState(updatedState);
      });
  };

  handleTableChange = (type, { page, sizePerPage }) => {
    if (type === 'pagination') {
      this.loadData(page - 1);
    }
  };
  render() {
    let modal = null;
    if (this.state.showModal.deleteModal) {
      modal = (
        <DeleteModel
          closed={() => {
            this.handleModalShow('deleteModal', false);
          }}
          url={urls.deleteRecordFromMFile + this.state.id}
        />
      );
    } else {
      modal = null;
    }

    return (
      <div className="view-mfile">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <UgcBreadcrumb bPaths={bPaths.masterData} />
            </Col>
          </Row>

          <Row>
            <Col
              xs={12}
              sm={{ span: 6, offset: 6 }}
              md={{ span: 6, offset: 6 }}
              lg={{ span: 6, offset: 6 }}
              className="table-search"
            >
              <InputField
                name='Search by Index Number'
                type="number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7);
                }}
                onChange={(val) => this.inputChangeHandler('indexNumber', val)}
                invalid={!this.state.controls.indexNumber.valid && this.state.controls.indexNumber.touched}
                feedback={labels.viewMfile.indexNo.feedback}
                onKeyDown={this.handleIndexNumberKeyDown}
                id={'indexNumberInputField'}
              />
            </Col>
          </Row>
          <Row className="view-mfile-table">
            <BootstrapTable
              onTableChange={this.handleTableChange}
              remote
              keyField="indexNumberd"
              data={this.state.data}
              columns={this.state.columns}
              pagination={paginationConfig(this.state.totalData)}
            />
          </Row>
          {modal}
        </Container>
      </div>
    );
  }
}

// const mapsDispatchToProps = (dispatch) => {
//   return {
//     onPassowrdChangeInitSucces: () => dispatch(actions.sliderMessageInit(messages.INDEX_NOT_IN_db, true)),
//     // onPassowrdChangeInitFail: () => dispatch(actions.sliderMessageInit('', false)),
//   };
// };

// export default connect(null, mapsDispatchToProps) (withRouter(MFileView));
 export default MFileView;
