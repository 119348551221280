import React from 'react';
import './index.scss';
import Form from 'react-bootstrap/Form';
import SubmitButton from '../../../components/SubmitButton';
import InputField from '../../../components/InputField';
import { connect } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { checkValidity } from '../../../utility/validation';
import { checkFormValidity } from '../../../utility/formValidation';
import axios from '../../../axios/axios';
import * as actions from '../../../store/actions/index';
import labels from '../../../config/label.json';
import moment from 'moment';

class LoginForm extends React.Component {
  state = {
    controls: {
      username: {
        valid: false,
        value: '',
        touched: false,
        rules: { required: true },
      },
      password: {
        valid: false,
        value: '',
        touched: false,
        rules: { required: true },
      },
    },
    formValid: false,
  };

  inputChangeHandler = (property, val) => {
    val = val.trim();
    const propertyConfig = { ...this.state.controls[property] };
    const isValid = checkValidity(val, propertyConfig.rules);
    propertyConfig['valid'] = isValid;
    propertyConfig['value'] = val;
    propertyConfig['touched'] = true;

    const updatedControl = {
      ...this.state.controls,
      [property]: propertyConfig,
    };
    const formValid = checkFormValidity(updatedControl);

    const updatedState = {
      ...this.state,
      formValid: formValid,
      controls: updatedControl,
    };
    this.setState(updatedState);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const username = this.state.controls.username.value;
    const password = this.state.controls.password.value;
    const authData = {
      username: username,
      password: password,
    };

    axios(this.props.history)
      .post('/authenticate', authData)
      .then((respose) => {
        this.props.onAuthSuccess(respose.headers.authorization);
        localStorage.setItem('token', respose.headers.authorization);
        const daysToExpirePassword = respose.data.noOfDaysToExpirePassword;
        if (daysToExpirePassword <= 0) {
          localStorage.setItem('passwordExpire', true);
          this.props.onPasswordExpire();
          this.props.history.push('/password-expire');
        } else if (daysToExpirePassword <= 3) {
          const dateStr = moment()
            .add(daysToExpirePassword - 1, 'days')
            .format('Do MMMM YYYY');
          const message = 'Your password will expire on ' + dateStr + ' please change your password.';
          this.props.onPasswordChangeMessage(message);
          this.props.history.push('/home');
        } else {
          this.props.history.push('/home');
        }
      })
      .catch((err) => {
        this.props.history.push('/tmp');
        this.props.history.goBack();
      });
  };

  render() {
    return (
      <Form className={'login-form'} onSubmit={this.handleSubmit}>
        <h3>Login Form</h3>
        <InputField
          name={'Username'}
          type={'text'}
          hint={labels.login.username.hint}
          onChange={(val) => this.inputChangeHandler('username', val)}
          feedback={labels.login.username.feedback}
          invalid={!this.state.controls.username.valid && this.state.controls.username.touched}
          id={'userNameInputField'}
        />
        <InputField
          name={'Password'}
          type={'password'}
          onChange={(val) => this.inputChangeHandler('password', val)}
          hint={labels.login.password.hint}
          feedback={labels.login.password.feedback}
          invalid={!this.state.controls.password.valid && this.state.controls.password.touched}
          id={'passwordInputField'}
        />
        <div className={'fogotpass-link'}>
          <NavLink className={'fogotpass-link'} to="login/forgot-password" id={'forgotPassword'}>
            Forgot Password?
          </NavLink>
        </div>
        {
          <SubmitButton
            id={'loginButton'}
            variant={'primary'}
            text={'LOGIN'}
            disabled={!this.state.formValid}
          ></SubmitButton>
        }
      </Form>
    );
  }
}

const mapDispathToProps = (dispatch) => {
  return {
    onAuthSuccess: (token) => dispatch(actions.authSuccess(token)),
    onAuthFail: (codes) => dispatch(actions.sliderMessageInit(codes, false)),
    onPasswordChangeMessage: (message) => dispatch(actions.sliderMessageInit(message)),
    onPasswordExpire: () => dispatch(actions.passwordExpire(true)),
  };
};

export default connect(null, mapDispathToProps)(LoginForm);
