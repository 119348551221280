import React from 'react';
import './index.scss';
import Form from 'react-bootstrap/Form';
import SubmitButton from '../../../components/SubmitButton';
import InputField from '../../../components/InputField';
import { checkValidity } from '../../../utility/validation';
import axios from '../../../axios/axios';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { checkFormValidity, checkInputInvalid } from '../../../utility/formValidation';
import labels from '../../../config/label.json';
import { convertCodeToMessage } from '../../../utility/errorConvertor';
import messages from '../../../config/messages.json';

class ResetpassForm extends React.Component {
  state = {
    controls: {
      newpassword: {
        valid: false,
        value: '',
        touched: false,
        rules: { required: true, password: true },
      },
      confirmnewpassword: {
        valid: false,
        value: '',
        touched: false,
        rules: { required: true, sameas: ['newpassword'] },
      },
    },
    formValid: false,
  };

  componentDidMount() {
    console.log(this.props.location.search);
    const searchParams = new URLSearchParams(this.props.location.search);
    const toekn = searchParams.get('token');
    const errors = searchParams.get('erros');
    if (errors) {
      this.props.onChangeFail(convertCodeToMessage(errors));
      this.props.history.push('/login');
    }
    console.log(toekn);
  }

  inputChangeHandler = (property, val) => {
    val = val.trim();
    const propertyConfig = { ...this.state.controls[property] };
    const isValid = checkValidity(val, propertyConfig.rules, this.state.controls);
    propertyConfig['valid'] = isValid;
    propertyConfig['value'] = val;
    propertyConfig['touched'] = true;

    const updatedControl = {
      ...this.state.controls,
      [property]: propertyConfig,
    };
    const formValid = checkFormValidity(updatedControl);

    const updatedState = {
      ...this.state,
      formValid: formValid,
      controls: updatedControl,
    };
    this.setState(updatedState);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const password = this.state.controls.newpassword.value;
    const confirmPassword = this.state.controls.confirmnewpassword.value;
    const token = new URLSearchParams(this.props.location.search).get('token');
    const passwordChangeData = {
      password: password,
      confirmPassword: confirmPassword,
      identification: token,
    };
    axios(this.props.history)
      .post('/users/password/change', passwordChangeData)
      .then((respose) => {
        console.log(respose);
        this.props.onChangeSuccess();
        this.props.history.push('/login');
      })
      .catch((err) => {
        const messages = err.response.data.map((e) => e.errorCode);
        //this.props.onChangeFail(messages);
        this.props.history.push('/login');
      });
  };

  resetPassword() {}
  render() {
    checkInputInvalid(this.state.controls, 'newpassword');
    return (
      <Form className="resetpass-form" onSubmit={this.handleSubmit}>
        <h3>Reset Password</h3>
        <InputField
          name={'New Password'}
          type={'password'}
          value={this.state.controls.newpassword.value}
          hint={labels.passwordChange.newPassword.hint}
          feedback={labels.passwordChange.newPassword.feedback}
          onChange={(val) => this.inputChangeHandler('newpassword', val)}
          invalid={checkInputInvalid(this.state.controls, 'newpassword')}
          id={'newPasswordInputField'}
        />
        <InputField
          name={'Confirm New Password'}
          type={'password'}
          onChange={(val) => this.inputChangeHandler('confirmnewpassword', val)}
          hint={labels.passwordChange.confirmPassword.hint}
          feedback={labels.passwordChange.confirmPassword.feedback}
          invalid={checkInputInvalid(this.state.controls, 'confirmnewpassword')}
          id={'confirmNewPasswordInputField'}
        />
        <div className="resetpass-btn-grp">
          <SubmitButton id={'submitButton'} variant={'primary'} text={'SUBMIT'} disabled={!this.state.formValid} />
        </div>
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeSuccess: () => {
      dispatch(actions.sliderMessageInit(messages.PASSWORD_CHANGED_SUCCESSFUL, true));
    },
    onChangeFail: (messages) => {
      dispatch(actions.sliderMessageInit(messages, false));
    },
  };
};
ResetpassForm.propTypes = {};

export default connect(null, mapDispatchToProps)(ResetpassForm);
