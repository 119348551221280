import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from "react-tooltip";

export const columns = () => [
  {
    dataField: 'indexNo',
    text: '​Index Number',
  },
  {
    dataField: 'uniCode',
    text: '​Higher Preferred UNI Codes',
  },
  {
    dataField: 'prefNumber',
    text: '​Preference Number',
  },  
];
